import { Button } from "@/components/ui/button";
import React from "react";

interface LoadMoreInterface {
  currentPage: number;
  total: number;
  pageName: string;
  docCount: number;
  pageData: any;
  setPageData: React.Dispatch<React.SetStateAction<number | any>>;
}

const PaginationComp = ({
  currentPage,
  setPageData,
  total,
  docCount,
  pageData,
  pageName,
}: LoadMoreInterface) => {
  const changeCurrentPage = () => {
    setPageData({ ...pageData, [pageName]: currentPage + 1 });
  };

  return (
    <>
      {docCount !== total ? (
        <div className="flex justify-center items-center gap-3">
          <Button
            className=" bg-transparent rounded-full text-black border border-gray-200 text-sm leading-none p-2 px-4 hover:bg-black hover:text-white"
            onClick={changeCurrentPage}
          >
            Load More
          </Button>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default PaginationComp;
