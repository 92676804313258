import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import { format } from "date-fns";
import {
  SelectTrigger,
  SelectValue,
  Select as MainSelect,
  SelectContent,
  SelectItem,
  SelectGroup,
} from "@/components/ui/select";
import Select from "react-select";
import {
  PopoverTrigger,
  PopoverContent,
  Popover,
} from "@/components/ui/popover";
import { startOfToday } from "date-fns";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { CalendarIcon, Image, Trash2 } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { ppholder } from "constants/assets";
import { Calendar } from "@/components/ui/calendar";
import SettingsMap from "pages/dashboard/dashboardsettings/components/components/SettingsMap";
import { useEffect, useMemo, useState } from "react";
import { Label } from "@/components/ui/label";
import { Card } from "flowbite-react";
import * as yup from "yup";
import { WithContext as ReactTags } from "react-tag-input";
import { Formik } from "formik";
import { formValidations } from "utils/validations/formValidations";
import { EVENTS_TYPE, INTERESTS } from "constants/data";
import useUIMisc from "hooks/useUIMisc";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useDispatch, useSelector } from "react-redux";
import {
  generateTimeOptions,
  getRandomSuggestions,
  getValueAndLabel,
} from "services/misc";
import { setDashboardData } from "redux/features/dashboardSlice";
import { EmptyContainer } from "components/custom/EmptyState";
import { mainCountries } from "utils/countries";
const KeyCodes = {
  comma: 188,
  enter: 13,
};
type AEProps = {
  viewType: string;
  addFunc?: (data: any) => void;
};

const AddEditEvent = ({ viewType, addFunc }: AEProps) => {
  const [photos, setPhotos] = useState<any[]>([]);
  const [subDisable, setSubDisable] = useState(false);
  const [randomTags, setRandomTags]: any = useState<any>([]);
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );
  const [selectInterests, setSelectInterests]: any = useState<any[]>(
    dashData !== null ? dashData.interests : []
  );
  const [tags, setTags]: any = useState([{ id: "OutFun", text: "OutFun" }]);
  const { showToast, closeLoading, showLoading } = useUIMisc();
  const axiosPrivate = useAxiosPrivate();
  const [start_date, setStartDate]: any = useState(new Date());
  const [mainStartTime, setMainStartTime]: any = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [mainEndTime, setMainEndTime]: any = useState<{
    value: string;
    label: string;
  } | null>(null);
  const [image, setImage] = useState<any>(null);
  const [previewImage, setPreviewImage] = useState<any>();
  const dispatch = useDispatch();
  const initEventValue = {
    name: viewType === "settings" ? dashData.name : "",
    startTime: viewType === "settings" ? dashData.startTime : "",
    endTime: viewType === "settings" ? dashData.endTime : "",
    startDate: viewType === "settings" ? dashData.startDate : "",
    event_category: viewType === "settings" ? dashData.event_category : "",
    desc: viewType === "settings" ? dashData.desc : "",
    media: viewType === "settings" ? dashData.media : "",
    shortdesc: viewType === "settings" ? dashData.shortdesc : "",
    privacy: viewType === "settings" ? dashData.privacy : "",
    event_type: "single",
    tags: viewType === "settings" ? dashData.tags : [],
    interests: viewType === "settings" ? dashData.interests : [],
  };
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const eventSchema = yup.object().shape({
    name: formValidations.default,
    event_type: formValidations.default,
    endTime: formValidations.defaultArr,
    interests: formValidations.defaultArr,
    tags: formValidations.defaultArr,
    startTime: formValidations.defaultArr,
    startDate: formValidations.datetime,
    event_category: formValidations.default,
    desc: formValidations.desc,
    media: formValidations.link,
    shortdesc: formValidations.shortdesc,
    privacy: formValidations.default,
  });

  const venueSchema = yup.object().shape({
    venue_name: formValidations.address,
    address: formValidations.address,
    city: formValidations.address,
    country: formValidations.address,
    link: formValidations.link,
  });
  const socialSchema = yup.object().shape({
    fb_link: formValidations.link,
    ig_link: formValidations.link,
    twitter_link: formValidations.link,
  });

  const initSocialValues = {
    fb_link: viewType === "settings" ? dashData.fb_link : "",
    ig_link: viewType === "settings" ? dashData.ig_link : "",
    twitter_link: viewType === "settings" ? dashData.twitter_link : "",
  };

  const initVenueValues = {
    venue_name: viewType === "settings" ? dashData.venue.venue_name : "",
    address: viewType === "settings" ? dashData.venue.address : "",
    city: viewType === "settings" ? dashData.venue.city : "",
    country: viewType === "settings" ? dashData.venue.country : "",
    link: viewType === "settings" ? dashData.venue.link : "",
  };

  // const [location, setLocation] = useState<any>([
  //   5.60003365639047, -0.18919420975159973,
  // ]);
  const timeOptions: any = generateTimeOptions();
  const today = startOfToday();
  const handleDrag = (tag: any, currPos: any, newPos: any) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);

    setTags(newTags);
  };

  const getArrayInt = () => {
    return selectInterests.map((interest: any) => interest.value);
  };

  const handleAddition = (tag: any, setField?: any) => {
    const findTag = tags.find((a: any) => a.text === tag.text);
    if (tags.length < 10 && !findTag) {
      if (tag.text.length > 3) {
        setTags([...tags, tag]);
        setRandomTags(getRandomSuggestions(getArrayInt()));
      } else {
        showToast(`Must be at least four (4) characters`, "warning");
      }
    } else if (tags.length === 10) {
      showToast(`Can only add 10 tags`, "warning");
    } else {
      showToast(`Tag already exists`, "warning");
    }
  };
  const handleDelete = (i: any) => {
    setTags(tags.filter((tag: any, index: any) => index !== i));
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (selectInterests.length !== 0) {
      setRandomTags(getRandomSuggestions(getArrayInt()));
    }
  }, [selectInterests]);

  const multiSelecClasses = {
    control: () => "!border-slate-200 !py-[.4%] !rounded-md ",
    placeholder: () => "!text-sm !text-muted-foreground",
    multiValueLabel: () => "bg-slate-100",
    multiValueRemove: () => "bg-slate-200",
  };

  const addEditEvent = async (values: any) => {
    setSubDisable(true);
    showLoading();

    const mainData =
      values.venue_name !== undefined
        ? {
            country: values.country,
            venue: {
              venue_name: values.venue_name,
              address: values.address,
              city: values.city,
              country: values.country,
              link: values.link,
            },
          }
        : values;

    setTimeout(async () => {
      try {
        const eventRes =
          viewType === "add"
            ? await axiosPrivate.post(`/events/add/${accountData._id}`, values)
            : await axiosPrivate.put(
                `/events/edit/${accountData._id}/${dashData._id}`,
                mainData
              );
        if (viewType === "add") {
          addFunc?.(eventRes.data.data);
        } else {
          dispatch(setDashboardData(eventRes.data.data));
        }
        setSubDisable(false);
        setTimeout(() => {
          closeLoading();
          showToast(
            `${
              viewType === "add"
                ? "Event created successfully!"
                : "Event updated successfully!"
            }`,
            "success"
          );
        }, 1000);
      } catch (error: any) {
        setSubDisable(false);
        setTimeout(() => {
          closeLoading();
          showToast(
            `An error occured!\n ${error.message}\n Try again!`,
            "error"
          );
        }, 2000);
      }
    }, 500);
  };

  const handleSubmit = (values: any, type: string) => {
    scrollToTop();
    if ((tags.length < 3 || tags.length > 10) && type === "basic") {
      showToast("Tags must be at least three (3) or 10", "warning");
    } else if (selectInterests?.length !== 3 && type === "basic") {
      showToast("Interests must be at least three (3) ", "warning");
    } else {
      addEditEvent(values);
    }
  };

  const delImage = () => {
    setPreviewImage(null);
    setImage(null);
  };

  const chooseImg = (type: string) => {
    const inputElem = document.createElement("input");
    inputElem.type = "file";
    inputElem.name = "image";
    inputElem.setAttribute("accept", ".jpg, .png, .jpeg");
    inputElem.click();
    inputElem.onchange = (ev: any) => {
      if (ev.target.files[0].size > 3097152) {
        showToast(`Image must be 3MB maximum!`, "warning");
      } else {
        if (type === "poster") {
          setPreviewImage({ url: URL.createObjectURL(ev.target.files[0]) });
          setImage(ev.target.files[0]);
          handleImageUpload(ev.target.files[0]);
        } else if (type === "photos") {
          handlePhotoUpload(ev.target.files[0]);
        }
      }
    };
  };

  const handleImageUpload = async (file: any) => {
    showLoading();
    let formData = new FormData();
    formData.append("image", file);
    // dispatch(showLoader);
    try {
      await axiosPrivate
        .put(`/events/upload/${accountData._id}/${dashData._id}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setTimeout(() => {
            closeLoading();
            showToast(`Poster updated successfully!`, "success");
          }, 1000);
          dispatch(setDashboardData(res.data.data));
        });
    } catch (error: any) {
      setTimeout(() => {
        closeLoading();
        showToast(`${error.response.data.message}\n Try again!`, "error");
      }, 2000);
      // dispatch(hideLoader);
    }
  };

  const handlePhotoUpload = async (file: any) => {
    let formData = new FormData();
    formData.append("image", file);
    showLoading();
    try {
      const uploadImg = await axiosPrivate.put(
        `/events/photos/${accountData._id}/${dashData._id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setTimeout(() => {
        closeLoading();
        showToast(`Uploaded Image successfully!`, "success");
      }, 1000);
      setPhotos(uploadImg.data.data.images);
    } catch (error: any) {
      setTimeout(() => {
        closeLoading();
        showToast(`${error.response.data.message}\n Try again!`, "error");
      }, 2000);
    }
  };

  const getPhotos = async () => {
    try {
      const photoRes = await axiosPrivate.get(`/events/photos/${dashData._id}`);
      if (photoRes.data.data.images !== undefined) {
        setPhotos(photoRes.data.data.images);
      }
    } catch (error: any) {
      // closeLoader();
      showToast(`${error.response.data.message}`, "error");
    }
  };

  const delPhotos = async (index: number) => {
    showLoading();
    const allImgs = [...photos];
    try {
      await axiosPrivate.delete(
        `/events/photos/${accountData._id}/${index}/${dashData._id}`
      );
      allImgs.splice(index, 1);
      setPhotos(allImgs);
      closeLoading();
      showToast(`Deleted photo successfully!`, "success");
    } catch (error: any) {
      closeLoading();
      showToast(`${error.response.data.message}`, "error");
    }
  };

  useEffect(() => {
    if (dashData) {
      setPreviewImage(dashData.image);
      setTags(dashData.tags);
      getPhotos();
    }

    window.scrollTo(0, 0);
  }, []);

  const filteredEndTimeOptions: any = useMemo(() => {
    if (!mainStartTime) return timeOptions;
    const [startHour, startMinute] = mainStartTime.value.split(":").map(Number);
    return timeOptions.filter(({ value }: any) => {
      const [endHour, endMinute] = value.split(":").map(Number);
      return (
        endHour > startHour ||
        (endHour === startHour && endMinute > startMinute)
      );
    });
  }, [mainStartTime]);

  return (
    <Card
      x-chunk="dashboard-05-chunk-3"
      className={`${viewType === "add" ? "border-none shadow-none" : ""}`}
    >
      <CardHeader className="p-0">
        <CardTitle>
          {viewType === "add" ? "Add Event" : "Event Settings"}
        </CardTitle>
        <CardDescription>
          {viewType === "add"
            ? "Fill the following fields. Click submit to save event."
            : "Make changes to your event here."}
        </CardDescription>
      </CardHeader>
      <CardContent
        className={`p-0 ${
          viewType === "add" ? "max-h-[75vh] overflow-y-scroll pe-2" : ""
        }`}
      >
        <Tabs defaultValue="basic">
          <TabsList
            className={`grid w-full mb-7 ${
              viewType !== "add" ? "grid-cols-3" : "grid-cols-1"
            }`}
          >
            <TabsTrigger value="basic">Basic Information</TabsTrigger>
            {viewType !== "add" ? (
              <>
                <TabsTrigger value="photos">Photos & Socials</TabsTrigger>
                <TabsTrigger value="venue">Venue </TabsTrigger>
              </>
            ) : null}
          </TabsList>
          <TabsContent value="basic">
            <Formik
              validationSchema={eventSchema}
              onSubmit={(values) => {
                handleSubmit(values, "basic");
              }}
              initialValues={initEventValue}
            >
              {({
                values,
                errors,
                handleChange,
                touched,
                setFieldValue,
                handleSubmit,
              }: any) => (
                <form onSubmit={handleSubmit}>
                  <div className="px-1">
                    <div className="mb-4">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="name">Name of event</Label>
                        <Input
                          id="name"
                          type="text"
                          placeholder="Early Bird"
                          onChange={handleChange}
                          name="name"
                          value={values.name}
                        />
                        {errors.name && touched.name && (
                          <span className="text-sm font-light text-red-500">
                            {errors.name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="grid gap-4 mb-4 sm:grid-cols-3">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="event_category">Event Category</Label>
                        <MainSelect
                          name="event_category"
                          onValueChange={(e) => {
                            setFieldValue("event_category", e);
                          }}
                          value={values.event_category}
                        >
                          <SelectTrigger
                            className="w-full focus:ring-0 mt-1"
                            id="event_category"
                          >
                            <SelectValue
                              className="focus:ring-0 font-normal"
                              placeholder="Select"
                            />
                          </SelectTrigger>
                          <SelectContent className="h-36">
                            <SelectGroup>
                              {EVENTS_TYPE.map((eventType) => (
                                <SelectItem
                                  className="capitalize"
                                  key={eventType.label}
                                  value={eventType.value}
                                >{`${eventType.icon_name} ${eventType.label}`}</SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </MainSelect>
                        {errors.event_category && touched.event_category && (
                          <span className="text-sm font-light text-red-500">
                            {errors.event_category}
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="privacy">Privacy</Label>
                        <MainSelect
                          name="privacy"
                          onValueChange={(e) => {
                            setFieldValue("privacy", e);
                          }}
                          value={values.privacy}
                        >
                          <SelectTrigger
                            className="w-full focus:ring-0 mt-1"
                            id="privacy"
                          >
                            <SelectValue
                              className="focus:ring-0 font-normal"
                              placeholder="Select"
                            />
                          </SelectTrigger>
                          <SelectContent className="h-36">
                            <SelectGroup>
                              <SelectItem value="public">Public</SelectItem>
                              <SelectItem value="private">Private</SelectItem>
                            </SelectGroup>
                          </SelectContent>
                        </MainSelect>
                        {errors.privacy && touched.privacy && (
                          <span className="text-sm font-light text-red-500">
                            {errors.privacy}
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="event_type">Event Type</Label>
                        <MainSelect
                          name="event_type"
                          onValueChange={(e) => {
                            setFieldValue("event_type", e);
                          }}
                          value={values.event_type}
                        >
                          <SelectTrigger
                            className="w-full focus:ring-0 mt-1"
                            id="event_type"
                          >
                            <SelectValue
                              className="focus:ring-0 font-normal"
                              placeholder="Select"
                            />
                          </SelectTrigger>
                          <SelectContent className="h-36">
                            <SelectGroup>
                              <SelectItem value="single">
                                Single event
                              </SelectItem>
                              {/* <SelectItem value="recurring">
                                Recurring event
                              </SelectItem> */}
                            </SelectGroup>
                          </SelectContent>
                        </MainSelect>
                        {errors.event_type && touched.event_type && (
                          <span className="text-sm font-light text-red-500">
                            {errors.event_type}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="grid gap-4 mb-4 sm:grid-cols-3">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="startDate">Start Date</Label>
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button
                              variant={"outline"}
                              className={cn(
                                "w-full justify-start text-left font-normal",
                                !start_date && "text-muted-foreground"
                              )}
                            >
                              <CalendarIcon className="mr-2 h-4 w-4" />
                              {start_date ? (
                                format(start_date, "PPP")
                              ) : (
                                <span>Pick a date</span>
                              )}
                            </Button>
                          </PopoverTrigger>
                          <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                              disabled={(date) => date < today}
                              mode="single"
                              selected={start_date}
                              onSelect={(e) => {
                                setFieldValue("startDate", e);
                                setStartDate(e);
                              }}
                              initialFocus
                            />
                          </PopoverContent>
                        </Popover>
                        {errors.startDate && touched.startDate && (
                          <span className="text-sm font-light text-red-500">
                            {errors.startDate}
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="startTime">Start Time</Label>
                        <MainSelect
                          name="startTime"
                          onValueChange={(e) => {
                            setFieldValue("startTime", e);
                            setMainStartTime({ value: e, label: e });
                          }}
                          value={values.startTime}
                        >
                          <SelectTrigger
                            className="w-full focus:ring-0 mt-1"
                            id="startTime"
                          >
                            <SelectValue
                              className="focus:ring-0 font-normal"
                              placeholder="Select"
                            />
                          </SelectTrigger>
                          <SelectContent className="h-36">
                            <SelectGroup>
                              {timeOptions.map((options: any) => (
                                <SelectItem value={options.value}>
                                  {options.label}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </MainSelect>
                        {errors.startTime && touched.startTime && (
                          <span className="text-sm font-light text-red-500">
                            {errors.startTime}
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="endTime">End Time</Label>
                        <MainSelect
                          name="endTime"
                          onValueChange={(e) => {
                            setFieldValue("endTime", e);
                            setMainEndTime({ value: e, label: e });
                          }}
                          value={values.endTime}
                        >
                          <SelectTrigger
                            className="w-full focus:ring-0 mt-1"
                            id="endTime"
                          >
                            <SelectValue
                              className="focus:ring-0 font-normal"
                              placeholder="Select"
                            />
                          </SelectTrigger>
                          <SelectContent className="h-36">
                            <SelectGroup>
                              {filteredEndTimeOptions.map((options: any) => (
                                <SelectItem value={options.value}>
                                  {options.label}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </MainSelect>
                        {errors.endTime && touched.endTime && (
                          <span className="text-sm font-light text-red-500">
                            {errors.endTime}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mb-4">
                      <Label htmlFor="currency">Currency</Label>
                      <MainSelect
                        name="currency"
                        onValueChange={(e) => {
                          setFieldValue("currency", e);
                        }}
                        disabled
                        value={values.currency}
                      >
                        <SelectTrigger
                          className="w-full focus:ring-0 mt-1"
                          id="currency"
                        >
                          <SelectValue
                            className="focus:ring-0 font-normal"
                            placeholder="Select currency"
                          />
                        </SelectTrigger>
                        <SelectContent className="h-36">
                          <SelectGroup>
                            {mainCountries.map((country) => (
                              <SelectItem
                                // value={`${country.name} ${country.currency} ${country.symbol}`}
                                value={country.name}
                                key={country.name}
                              >
                                {country.name} {country.currency}{" "}
                                {country.symbol}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </MainSelect>
                    </div>
                    <div className="mb-4">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="interests">Event Interests</Label>
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          classNames={{ ...multiSelecClasses }}
                          options={INTERESTS}
                          isSearchable={true}
                          defaultValue={selectInterests}
                          onChange={(e: any) => {
                            setFieldValue("interests", [
                              ...getValueAndLabel(e),
                            ]);
                            setSelectInterests([...getValueAndLabel(e)]);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="media">Media</Label>
                        <Input
                          id="media"
                          type="text"
                          placeholder="Youtube link video eg. https://ytbe.com"
                          onChange={handleChange}
                          name="media"
                          value={values.media}
                        />
                        {errors.media && touched.media && (
                          <span className="text-sm font-light text-red-500">
                            {errors.media}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex flex-col space-y-1.5 tags-main">
                        <Label htmlFor="tickets">Event Tags</Label>
                        <ReactTags
                          tags={tags}
                          placeholder="Press enter to add tag & must be at least four (4) characters"
                          delimiters={delimiters}
                          handleDelete={handleDelete}
                          handleAddition={(e: any) => {
                            handleAddition(e);
                            const allTags = [...tags];
                            allTags.push(e);
                            setFieldValue("tags", allTags);
                          }}
                          handleDrag={handleDrag}
                          inputFieldPosition="bottom"
                          autocomplete
                        />
                        <span className="text-sm font-light text-gray-500">
                          Add three (3) tags related to the event.
                        </span>
                        <div className="flex flex-wrap">
                          {randomTags.length !== 0 ? (
                            <>
                              {randomTags.map((randTag: any) => (
                                <span
                                  className="p-2 bg-gray-100 text-sm mr-2 mb-2 cursor-pointer"
                                  onClick={() => {
                                    const mainTags = {
                                      text: randTag,
                                      id: randTag,
                                      className: "",
                                    };
                                    handleAddition(mainTags);
                                    const allTags = [...tags];
                                    allTags.push(mainTags);
                                    setFieldValue("tags", allTags);
                                  }}
                                >
                                  {randTag}
                                </span>
                              ))}
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="shortdesc">Short Description</Label>
                        <Input
                          id="shortdesc"
                          type="text"
                          placeholder="A vibrant and classy experience with lots of moments"
                          onChange={handleChange}
                          name="shortdesc"
                          value={values.shortdesc}
                        />
                        {errors.shortdesc && touched.shortdesc && (
                          <span className="text-sm font-light text-red-500">
                            {errors.shortdesc}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mb-4">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="desc">Description</Label>
                        <Textarea
                          placeholder="Type your description here."
                          className="resize-none"
                          onChange={handleChange}
                          name="desc"
                          value={values.desc}
                        />
                        {errors.desc && touched.desc && (
                          <span className="text-sm font-light text-red-500">
                            {errors.desc}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <Button
                      type="submit"
                      disabled={subDisable}
                      className="bg-black"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </TabsContent>
          {viewType !== "add" ? (
            <>
              <TabsContent value="photos">
                <div>
                  <div className="flex justify-between items-start">
                    <div>
                      <Label htmlFor="poster">Event Poster</Label>
                      <p className="text-muted-foreground text-sm mb-3">
                        Will appear on the event page
                      </p>
                    </div>
                    <Button
                      className={`bg-black hover:bg-black hover:text-white ${
                        previewImage !== null && "bg-red-700 text-white"
                      }`}
                      onClick={
                        previewImage === null
                          ? () => chooseImg("poster")
                          : () => delImage()
                      }
                    >
                      {previewImage === null ? (
                        <>
                          <Image size={13} className="me-1" />
                          Change image
                        </>
                      ) : (
                        <>
                          Delete image
                          <Trash2 size={13} className="me-1" />
                        </>
                      )}
                    </Button>
                  </div>
                  <div className="grid grid-cols-5 gap-4">
                    <div className="w-64 h-40 rounded-lg mb-7">
                      <img
                        src={
                          previewImage !== null && previewImage !== undefined
                            ? previewImage.url
                            : ppholder
                        }
                        alt=""
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-start">
                    <div>
                      <Label htmlFor="maxtickets">Event Photos</Label>
                      <p className="text-muted-foreground text-sm mb-3">
                        Upload four (4) pictures for the event
                      </p>
                    </div>
                    <Button
                      className="bg-black"
                      disabled={photos.length < 4 ? false : true}
                      onClick={() => chooseImg("photos")}
                    >
                      <Image /> {""} <span className="ms-2">Upload Photos</span>
                    </Button>
                  </div>
                  <div className="grid grid-cols-5 gap-4">
                    <>
                      {photos.length !== 0 ? (
                        <>
                          {photos.map((image: any, index: any) => (
                            <div
                              className="w-36 h-36 rounded-lg mb-7"
                              key={image.fileId}
                            >
                              <img
                                src={image.url || ppholder}
                                alt=""
                                className="w-full h-full object-cover rounded-lg"
                              />
                              <div
                                className="flex justify-center  bg-slate-100 text-red-600 text-sm p-2 mt-2 rounded-md"
                                onClick={() => delPhotos(index)}
                              >
                                <Trash2 size={15} />
                              </div>
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="col-span-5">
                          <EmptyContainer
                            message="No photos yet."
                            instructions="Click on the upload button to add photos."
                          />
                        </div>
                      )}
                    </>
                  </div>
                  <Formik
                    validationSchema={socialSchema}
                    onSubmit={(values) => {
                      handleSubmit(values, "social");
                    }}
                    initialValues={initSocialValues}
                  >
                    {({
                      values,
                      errors,
                      handleChange,
                      touched,
                      handleSubmit,
                    }: any) => (
                      <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-2 gap-4 mt-10">
                          <div className="space-y-1">
                            <Label htmlFor="fb_link">Facebook Link</Label>
                            <Input
                              id="fb_link"
                              className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                              placeholder="https://web.facebook.com/outfunapp"
                              onChange={handleChange}
                              name="fb_link"
                              value={values.fb_link}
                            />
                            {errors.fb_link && touched.fb_link && (
                              <span className="text-sm font-light text-red-500">
                                {errors.fb_link}
                              </span>
                            )}
                          </div>
                          <div className="space-y-1">
                            <Label htmlFor="ig_link">Instagram Link</Label>
                            <Input
                              id="ig_link"
                              className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                              placeholder="https://instagram.com/outfunapp"
                              name="ig_link"
                              onChange={handleChange}
                              value={values.ig_link}
                            />
                            {errors.ig_link && touched.ig_link && (
                              <span className="text-sm font-light text-red-500">
                                {errors.ig_link}
                              </span>
                            )}
                          </div>
                          <div className="space-y-1">
                            <Label htmlFor="twitter_link">Twitter Link</Label>
                            <Input
                              id="twitter_link"
                              className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                              placeholder="https://twitter.com/outfunapp"
                              name="twitter_link"
                              onChange={handleChange}
                              value={values.twitter_link}
                            />
                            {errors.twitter_link && touched.twitter_link && (
                              <span className="text-sm font-light text-red-500">
                                {errors.twitter_link}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="text-center mt-5">
                          <Button type="submit" className="bg-black">
                            Submit
                          </Button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </TabsContent>
              <TabsContent value="venue">
                <Formik
                  validationSchema={venueSchema}
                  onSubmit={(values) => {
                    handleSubmit(values, "venue");
                  }}
                  initialValues={initVenueValues}
                >
                  {({
                    values,
                    errors,
                    handleChange,
                    touched,
                    setFieldValue,
                    handleSubmit,
                  }: any) => (
                    <form onSubmit={handleSubmit}>
                      <div className="mb-4">
                        <div className="space-y-1">
                          <Label htmlFor="venue_name">Venue Name</Label>
                          <Input
                            id="venue_name"
                            className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                            placeholder="Jams Arena"
                            name="venue_name"
                            onChange={handleChange}
                            value={values.venue_name}
                          />
                          {errors.venue_name && touched.venue_name && (
                            <span className="text-sm font-light text-red-500">
                              {errors.venue_name}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="space-y-1 mb-4">
                        <Label htmlFor="address">Street address</Label>
                        <Input
                          id="address"
                          className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                          placeholder="Old Road Street"
                          name="address"
                          onChange={handleChange}
                          value={values.address}
                        />
                        {errors.address && touched.address && (
                          <span className="text-sm font-light text-red-500">
                            {errors.address}
                          </span>
                        )}
                      </div>
                      <div className="grid grid-cols-2 gap-4 mb-4">
                        <div className="space-y-1">
                          <Label htmlFor="city">City</Label>
                          <Input
                            id="city"
                            className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                            placeholder="Accra"
                            name="city"
                            onChange={handleChange}
                            value={values.city}
                          />
                          {errors.city && touched.city && (
                            <span className="text-sm font-light text-red-500">
                              {errors.city}
                            </span>
                          )}
                        </div>
                        <div>
                          <Label htmlFor="country">Country</Label>
                          <MainSelect
                            name="country"
                            onValueChange={(e) => {
                              setFieldValue("country", e);
                            }}
                            value={values.country}
                          >
                            <SelectTrigger
                              className="w-full focus:ring-0 mt-1"
                              id="country"
                            >
                              <SelectValue
                                className="focus:ring-0 font-normal"
                                placeholder="Select country"
                              />
                            </SelectTrigger>
                            <SelectContent className="h-36">
                              <SelectGroup>
                                {mainCountries.map((country) => (
                                  <SelectItem
                                    value={country.name}
                                    key={country.name}
                                  >
                                    {country.name} {country.flag}
                                  </SelectItem>
                                ))}
                              </SelectGroup>
                            </SelectContent>
                          </MainSelect>
                          {errors.country && touched.country && (
                            <span className="text-sm font-light text-red-500">
                              {errors.country}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="space-y-1">
                        <Label htmlFor="link">
                          Venue Link (copied from Google Maps)
                        </Label>
                        <Input
                          id="link"
                          className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                          placeholder="https://maps.app.goo.gl/8bzjYFZhe97Ln87"
                          name="link"
                          onChange={handleChange}
                          value={values.link}
                        />
                        {errors.link && touched.link && (
                          <span className="text-sm font-light text-red-500">
                            {errors.link}
                          </span>
                        )}
                      </div>
                      <div className="text-center mt-5">
                        <Button type="submit" className="bg-black">
                          Submit
                        </Button>
                      </div>
                    </form>
                  )}
                </Formik>
              </TabsContent>
            </>
          ) : null}
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default AddEditEvent;
