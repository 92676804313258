import { Badge } from "@/components/ui/badge";
import { CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Separator } from "@/components/ui/separator";
import { event5, ppholder } from "constants/assets";
import { Card } from "flowbite-react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import {
  Banknote,
  BriefcaseBusiness,
  Handshake,
  Heart,
  Megaphone,
  MessageSquare,
  MessagesSquare,
  Star,
  Ticket,
} from "lucide-react";
import moment from "moment";
import { StatsCard } from "pages/components";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TeamCard from "../teams/components/TeamCard";
import { uniqBy } from "lodash";
import { SkeletonCard } from "components/custom/SkeletonCards";
import { EmptyContainer } from "components/custom/EmptyState";
import FeedSubCard from "../feeds/component/FeedSubCard";
import TicketCard from "../tickets/components/TicketCard";
import CampaignCard from "../campaigns/components/CampaignCard";
import ReviewCard from "../reviews/components/ReviewCard";
import { Link, useLocation } from "react-router-dom";
import BookingCard from "../booking/components/BookingCard";
import { getCurrencyByCountryName } from "services/misc";

type Props = {};

const OrganizerHome = (props: Props) => {
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const [emptyFirstState, setFirstEmptyState] = useState(false);
  const [emptySecondState, setSecondEmptyState] = useState(false);
  const [emptyThirdState, setThirdEmptyState] = useState(false);
  const [allThirdData, setThirdData] = useState<any[]>([]);
  const [allSecondData, setSecondData] = useState<any[]>([]);
  const [allFirstData, setFirstData] = useState<any[]>([]);
  const [statsData, setStatsData]: any = useState({
    totalTickets: 0,
    totalRevenue: 0,
    totalFeeds: 0,
    soldTickets: 0,
    totalReviews: 0,
    totalCampaigns: 0,
    totalBookings: 0,
    totalServices: 0,
  });
  const { dashData, accountData } = useSelector(
    (state: any) => state.dashboard
  );

  const getStats = async () => {
    try {
      const statsRes =
        accountData.business_type === "venue_manager"
          ? await axiosPrivate.get(
              `/dashboard/venue-stats/${dashData._id}/${accountData._id}`
            )
          : await axiosPrivate.get(
              `/dashboard/stats/${accountData._id}/${dashData._id}`
            );
      if (accountData.business_type === "venue_manager") {
        setStatsData({
          ...statsRes.data.data,
          totalServices: dashData.services.length,
        });
      } else {
        setStatsData(statsRes.data.data);
      }
    } catch (error) {}
  };

  const getTickets = async () => {
    try {
      const tickets = await axiosPrivate.get(
        `/tickets/manager/${accountData._id}/${dashData._id}`,
        {
          params: {
            limit: 5,
          },
        }
      );
      const responseData = tickets.data.data;
      setThirdData([...allThirdData, ...responseData]);
      if (responseData.length === 0 && allThirdData.length === 0) {
        setThirdEmptyState(true);
      }
    } catch (error: any) {}
  };

  const getTeams = async () => {
    try {
      const teams = await axiosPrivate.get(
        `/teams/manager/${accountData._id}/${dashData._id}`,
        {
          params: {
            limit: 5,
          },
        }
      );
      const responseData = teams.data.data;
      setFirstData([...allFirstData, ...responseData]);
      if (responseData.length === 0 && allFirstData.length === 0) {
        setFirstEmptyState(true);
      }
    } catch (error: any) {}
  };

  const getFeeds = async () => {
    try {
      const feeds = await axiosPrivate.get(`/feeds/event/${dashData._id}`, {
        params: {
          limit: 5,
        },
      });
      const responseData = feeds.data.data;
      setSecondData([...allSecondData, ...responseData]);
      if (responseData.length === 0 && allSecondData.length === 0) {
        setSecondEmptyState(true);
      }
    } catch (error: any) {}
  };

  const getCampaigns = async () => {
    try {
      const campaigns = await axiosPrivate.get(
        `/campaigns/${dashData._id}/${
          accountData.business_type === "venue_manager"
            ? "venue_manager"
            : "event_organizer"
        }`,
        {
          params: {
            limit: 5,
          },
        }
      );
      const responseData = campaigns.data.data;
      setSecondData([...allSecondData, ...responseData]);
      if (responseData.length === 0 && allSecondData.length === 0) {
        setSecondEmptyState(true);
      }
    } catch (error: any) {}
  };

  const getReviews = async () => {
    try {
      const reviews = await axiosPrivate.get(`/reviews/all/${dashData._id}`, {
        params: {
          limit: 5,
        },
      });
      const responseData = reviews.data.data;
      setThirdData([...allThirdData, ...responseData]);
      if (responseData.length === 0 && allThirdData.length === 0) {
        setThirdEmptyState(true);
      }
    } catch (error: any) {}
  };

  const getBookings = async () => {
    try {
      const bookings = await axiosPrivate.get(
        `/business/bus-bookings/${dashData._id}/venue`,
        {
          params: {
            limit: 5,
          },
        }
      );
      const responseData = bookings.data.data;
      setFirstData([...allFirstData, ...responseData]);
      if (responseData.length === 0 && allFirstData.length === 0) {
        setFirstEmptyState(true);
      }
    } catch (error: any) {}
  };

  useEffect(() => {
    if (accountData.business_type === "event_organizer") {
      getTickets();
      getTeams();
      getFeeds();
    } else if (accountData.business_type === "venue_manager") {
      getReviews();
      getCampaigns();
      getBookings();
    }
  }, []);

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h2 className="font-bold text-3xl text-black capitalize">
            hello {accountData.name} 👋
          </h2>
          <p className="text-muted-foreground">
            This is what we got for you today.
          </p>
        </div>
        <div className="flex justify-start items-center gap-2 border p-2 rounded-lg bg-white max-w-96">
          <img
            src={
              accountData.business_type === "event_organizer" &&
              dashData.image !== null
                ? dashData.image.url
                : accountData.business_type === "venue_manager" &&
                  dashData.image !== null
                ? dashData.image.url
                : ppholder
            }
            className="w-20 h-20 object-cover rounded-lg"
            alt=""
          />
          <div>
            <h6 className="font-medium text-black capitalize tracking-tighter text-md">
              {dashData.name}
            </h6>
            <p className="text-muted-foreground text-sm">
              {moment(dashData.startDate).format("Do MMM. YYYY")}
            </p>
            <Link
              to="/outfunner/settings"
              className="text-sm text-gray-500 underline"
            >
              Edit{" "}
              {accountData.business_type === "venue_manager"
                ? "venue"
                : "event"}
            </Link>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-4 gap-2">
        <StatsCard
          name={
            accountData.business_type === "venue_manager"
              ? "Total Bookings"
              : "Total Revenue"
          }
          figure={
            accountData.business_type === "venue_manager"
              ? statsData.totalBookings
              : statsData.totalRevenue || 0
          }
          iconData={{
            icon:
              accountData.business_type === "venue_manager"
                ? BriefcaseBusiness
                : Banknote,
          }}
          info={
            accountData.business_type === "venue_manager"
              ? "Number of bookings"
              : "Revenue generated"
          }
          currency={
            accountData.business_type === "event_organizer"
              ? getCurrencyByCountryName(dashData.venue.country)
              : ""
          }
        />
        <StatsCard
          name={
            accountData.business_type === "venue_manager"
              ? "Total Campaigns"
              : "Total tickets"
          }
          figure={
            accountData.business_type === "venue_manager"
              ? statsData.totalCampaigns
              : statsData.totalTickets || 0
          }
          iconData={{
            icon:
              accountData.business_type === "venue_manager"
                ? Megaphone
                : Ticket,
          }}
          info={
            accountData.business_type === "venue_manager"
              ? "Number of campaigns"
              : "Number of tickets"
          }
        />
        <StatsCard
          name={
            accountData.business_type === "venue_manager"
              ? "Total Reviews"
              : "Total Sold tickets"
          }
          figure={
            accountData.business_type === "venue_manager"
              ? statsData.totalReviews
              : statsData.soldTickets || 0
          }
          iconData={{
            icon: accountData.business_type === "venue_manager" ? Star : Ticket,
          }}
          info={
            accountData.business_type === "venue_manager"
              ? "Number of reviews"
              : "Number of sold tickets"
          }
        />
        <StatsCard
          name={
            accountData.business_type === "venue_manager"
              ? "Services"
              : "Total Feeds"
          }
          figure={
            accountData.business_type === "venue_manager"
              ? statsData.totalServices
              : statsData.totalFeeds || 0
          }
          iconData={{
            icon:
              accountData.business_type === "venue_manager"
                ? Handshake
                : MessagesSquare,
          }}
          info={
            accountData.business_type === "venue_manager"
              ? "Number of services"
              : "Number of feeds"
          }
        />
      </div>
      <div className="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3 mt-6">
        <Card x-chunk="dashboard-01-chunk-5" className="shadow-sm">
          <CardHeader className="p-0">
            <CardTitle>
              {accountData.business_type === "venue_manager"
                ? "Recent Bookings"
                : "Recent Teams"}
            </CardTitle>
          </CardHeader>
          <CardContent className="grid gap-3 p-0">
            {allFirstData !== undefined && allFirstData.length !== 0 ? (
              <>
                {uniqBy(allFirstData, "_id").map((data) => (
                  <>
                    {accountData.business_type === "venue_manager" ? (
                      <BookingCard data={data} viewBool={false} />
                    ) : (
                      <TeamCard viewBool={false} data={data} />
                    )}
                  </>
                ))}
              </>
            ) : allFirstData.length === 0 && !emptyFirstState ? (
              <>
                {Array.from({ length: 4 }).map((card) => (
                  <SkeletonCard />
                ))}
              </>
            ) : (
              <div className="col-span-3">
                <EmptyContainer
                  message={
                    accountData.business_type === "venue_manager"
                      ? "No bookings yet!"
                      : "No team member yet!"
                  }
                />
              </div>
            )}
          </CardContent>
        </Card>
        <Card x-chunk="dashboard-01-chunk-5" className="shadow-sm">
          <CardHeader className="p-0">
            <CardTitle>
              {accountData.business_type === "venue_manager"
                ? "Recent Campaigns"
                : "Recent Feeds"}
            </CardTitle>
          </CardHeader>
          <CardContent className="grid gap-3 p-0">
            {allSecondData !== undefined && allSecondData.length !== 0 ? (
              <>
                {uniqBy(allSecondData, "_id").map((data) => (
                  <>
                    {accountData.business_type === "venue_manager" ? (
                      <CampaignCard
                        currency={getCurrencyByCountryName(
                          accountData.business_type === "event_organizer"
                            ? dashData.venue.country
                            : dashData.country
                        )}
                        data={data}
                        viewBool={false}
                      />
                    ) : (
                      <FeedSubCard data={data} />
                    )}
                  </>
                ))}
              </>
            ) : allSecondData.length === 0 && !emptySecondState ? (
              <>
                {Array.from({ length: 4 }).map((card) => (
                  <SkeletonCard />
                ))}
              </>
            ) : (
              <div className="col-span-4">
                <EmptyContainer
                  message={
                    accountData.business_type === "venue_manager"
                      ? "No campaigns yet!"
                      : "No feeds yet!"
                  }
                />
              </div>
            )}
          </CardContent>
        </Card>
        <Card x-chunk="dashboard-01-chunk-5" className="shadow-sm">
          <CardHeader className="p-0">
            <CardTitle>
              {accountData.business_type === "venue_manager"
                ? "Recent Reviews"
                : "Event Tickets"}
            </CardTitle>
          </CardHeader>
          <CardContent className="grid gap-3 p-0">
            {allThirdData !== undefined && allThirdData.length !== 0 ? (
              <>
                {uniqBy(allThirdData, "_id").map((data) => (
                  <>
                    {accountData.business_type === "venue_manager" ? (
                      <ReviewCard data={data} />
                    ) : (
                      <TicketCard
                        viewBool={false}
                        key={data._id}
                        data={data}
                        currency={
                          accountData.business_type === "event_organizer"
                            ? getCurrencyByCountryName(dashData.venue.country)
                            : ""
                        }
                        // viewFunc={viewTicket}
                      />
                    )}
                  </>
                ))}
              </>
            ) : allThirdData.length === 0 && !emptyThirdState ? (
              <>
                {Array.from({ length: 4 }).map((card) => (
                  <SkeletonCard />
                ))}
              </>
            ) : (
              <div className="col-span-3">
                <EmptyContainer
                  message={
                    accountData.business_type === "venue_manager"
                      ? "No reviews yet!"
                      : "No tickets yet!"
                  }
                />
              </div>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default OrganizerHome;
