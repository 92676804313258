import React from "react";
import { Heart, MessageSquare } from "lucide-react";
import { nowDate } from "services/misc";
import { ppholder } from "constants/assets";
type FCardProps = {
  data: any;
  viewFunc?: (data: any, viewType: string) => void;
};
const FeedSubCard = ({ viewFunc, data }: FCardProps) => {
  return (
    <div
      className="flex justify-start items-center gap-3 border-b pb-3"
      onClick={() => {
        viewFunc?.(data, "feed");
      }}
    >
      <img
        src={data.image !== null ? data.image.url : ppholder}
        alt=""
        className="w-20 h-16 rounded-xl object-cover"
      />
      <div className="w-full">
        <h4 className="font-medium text-sm mb-0 line-clamp-2 text-ellipsis">
          {data.caption}
        </h4>
        <div className="flex justify-start items-start divide-x mt-2">
          <div className="flex justify-start items-center text-gray-500 pe-2">
            <Heart size={13} />
            <span className="text-gray-500 text-sm">{data.likesCount}</span>
          </div>
          <div className="flex justify-start items-center text-gray-500 px-2">
            <MessageSquare size={13} />
            <span className="text-gray-500 text-sm">{data.commentsCount}</span>
          </div>
          <span className="text-gray-500 text-sm ps-2">
            {nowDate(data.createdAt)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default FeedSubCard;
