import {
  appleIcon,
  authImg,
  fbIcon,
  googleIcon,
  loginImg,
  logoBlack,
} from "../../constants/assets";
import { Button } from "../../@/components/ui/button";
import { Input } from "../../@/components/ui/input";
import { Label } from "../../@/components/ui/label";
import React, { useEffect, useRef, useState } from "react";
import {
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
} from "firebase/auth";

import * as yup from "yup";
import { Formik } from "formik";
import { auth } from "config/firebaseconfig";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { useDispatch } from "react-redux";
import { assignOrg, assignOfTokens } from "redux/features/authSlice";
import AppleLogin from "react-apple-login";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { getName } from "services/misc";
import { Eye, EyeOff } from "lucide-react";
import { formValidations } from "utils/validations/formValidations";
import { Helmet } from "react-helmet-async";

type Props = {};

const AuthPage = (props: Props) => {
  const { id, token }: any = useParams();
  const [activePage, setActivePage] = useState("login");
  const [visiblePass, setVisiblePass] = useState(false);
  const [visibleConfirmPass, setVisibleConfirmPass] = useState(false);
  const passRef: any = useRef();
  const confirmPassRef: any = useRef();
  const axiosPrivate = useAxiosPrivate();
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const dispatch = useDispatch();
  const location = useLocation();
  const router = useNavigate();

  const toggleVisible = () => {
    setVisiblePass(!visiblePass);
    passRef.current.type = visiblePass ? "password" : "text";
  };

  const toggleConfirmVisible = () => {
    setVisibleConfirmPass(!visibleConfirmPass);
    confirmPassRef.current.type = visibleConfirmPass ? "password" : "text";
  };
  const allValidations = [
    yup.string().email("Invalid email").required("Required"),
    yup
      .string()
      .min(8, "Must be at least 8 characters")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
        "Must contain minimum 8 characters long, at least one uppercase letter, one lowercasse letter, one number & a special character"
      )
      .required("Required"),
    yup
      .string()
      .min(3, "Must be at least 3 characters")
      .max(50, "Must be at most 50 characters")
      .matches(/^[A-Za-z]+$/, "Must contain only letters")
      .required("Required"),
  ];

  const forgotSchema = yup.object().shape({
    email: allValidations[0],
  });

  const registerSchema = yup.object().shape({
    email: allValidations[0],
    password: allValidations[1],
    firstname: allValidations[2],
    lastname: allValidations[2],
  });

  const loginSchema = yup.object().shape({
    email: allValidations[0],
    password: allValidations[1],
  });

  const resetPasswordSchema = yup.object().shape({
    password: allValidations[1],
    confirmPassword: formValidations.confirmPassword,
  });

  const signBtn = [
    { name: "google", icon: googleIcon },
    // { name: "apple", icon: appleIcon },
    { name: "facebook", icon: fbIcon },
  ];

  const getInitialValues = (values: any) => {
    switch (activePage) {
      case "login":
        return {
          email: values.email,
          password: values.password,
        };
      case "resetPassword":
        return {
          password: values.password,
          confirmPassword: values.confirmPassword,
        };
      case "forgot":
        return {
          email: values.email,
        };
      case "signup":
        return {
          email: values.email,
          password: values.password,
          lastname: values.lastname,
          firstname: values.firstname,
        };
      default:
        return {};
    }
  };

  const routeUser = (userdata: any) => {
    const { firstname } =
      activePage === "login" || activePage === "signup" ? userdata.user : "";
    if (activePage === "login" || activePage === "signup") {
      dispatch(assignOrg(userdata.user));
      dispatch(assignOfTokens(userdata.tokens));
      localStorage.setItem("authUser", "organizer");
      router("/outfunner/accounts");
    } else {
      setActivePage("login");
    }
    closeLoading();
    setTimeout(() => {
      showToast(
        activePage === "login" || activePage === "signup"
          ? `Hi ${firstname} 👋🏽\n Welcome to OutFun`
          : activePage === "resetPassword"
          ? `Password updated successfully!`
          : "A mail has been sent to your email. Kindly check it for instructions",
        "success"
      );
    }, 3000);
  };

  const handleSocialAuth = async (authType: string) => {
    showLoading();
    const provider: any =
      authType === "google"
        ? new GoogleAuthProvider()
        : authType === "facebook"
        ? new FacebookAuthProvider()
        : "";
    try {
      const googleUser: any = await signInWithPopup(auth, provider);
      const { firstname, lastname } = getName(googleUser.user.displayName);
      const data = {
        email: googleUser.user.email,
        firstname,
        lastname,
        password: `${googleUser.user.reloadUserInfo.localId}`,
        verified: true,
        // profileImg: `pro${getRandomNumber()}`,
      };
      const signData = await axiosPrivate.post(`/auth/social-auth`, data);
      routeUser(signData.data.data);
    } catch (error) {
      closeLoading();
      showToast("An error occured!", "error");
    }
  };

  const handleSubmit = async (values: any, resetForm: any) => {
    showLoading();
    let data = {};
    if (activePage === "resetPassword") {
      data = {
        id,
        token,
        password: getInitialValues(values).password,
      };
    }

    try {
      const resData: any =
        activePage === "login"
          ? await axiosPrivate.post(`/auth/signin`, getInitialValues(values))
          : activePage === "signup"
          ? await axiosPrivate.post(`/auth/register`, getInitialValues(values))
          : activePage === "resetPassword"
          ? await axiosPrivate.post(`/auth/password-reset`, data)
          : activePage === "forgot"
          ? await axiosPrivate.post(
              `/auth/forgot-password`,
              getInitialValues(values)
            )
          : null;

      routeUser(resData.data.data);
    } catch (error: any) {
      closeLoading();
      showToast(error.response.data.message, "error");
    }
    setTimeout(() => {
      resetForm();
    }, 1000);
  };

  useEffect(() => {
    if (location.pathname.includes("password-reset")) {
      setActivePage("resetPassword");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Login to OutFun</title>
        <meta
          name="description"
          content="Access your OutFun account to book and manage your events."
        />
        <meta
          name="keywords"
          content="login, OutFun, event booking, event management, events, event, party, concert near me, nightlife"
        />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Login to OutFun - Event Booking Platform",
        "url": "https://outfun.app/login",
        "description": "Access your OutFun account to book and manage your events.",
        "inLanguage": "en",
        "potentialAction": {
          "@type": "LoginAction",
          "target": "https://outfun.app/login"
        },
        "publisher": {
          "@type": "Organization",
          "name": "OutFun",
          "url": "https://outfun.app"
        }
      }
    `}
        </script>
      </Helmet>
      <div className="w-full lg:grid lg:min-h-screen lg:grid-cols-2 xl:min-h-screen">
        <div className="flex items-center justify-center py-12">
          <div className="md:mx-auto w-full px-8 md:px-0 grid md:w-[350px] gap-6">
            <Link to="/">
              <img src={logoBlack} alt="logo" className="h-10 w-10 mb-8" />
            </Link>
            <div className="grid gap-2">
              <h1 className="text-3xl font-bold">
                {activePage === "login"
                  ? "Login"
                  : activePage === "signup"
                  ? "Register"
                  : activePage === "resetPassword"
                  ? "Set New Password"
                  : activePage === "forgot"
                  ? "Reset Password"
                  : ""}
              </h1>
              <p className="text-balance text-muted-foreground">
                {activePage === "login"
                  ? "Enter your email & password."
                  : activePage === "signup"
                  ? "Create an accoount with your info."
                  : activePage === "forgot"
                  ? "Enter your email address to send instructions."
                  : activePage === "resetPassword"
                  ? "Enter your new password to access your account."
                  : ""}
              </p>
            </div>
            <Formik
              validationSchema={
                activePage === "login"
                  ? loginSchema
                  : activePage === "signup"
                  ? registerSchema
                  : activePage === "forgot"
                  ? forgotSchema
                  : activePage === "resetPassword"
                  ? resetPasswordSchema
                  : loginSchema
              }
              onSubmit={(values, { resetForm }) => {
                handleSubmit(values, resetForm);
              }}
              initialValues={{
                password: "",
                confirmPassword: "",
                email: "",
                firstname: "",
                lastname: "",
              }}
            >
              {({
                values,
                errors,
                handleChange,
                touched,
                resetForm,
                handleSubmit,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4">
                    {activePage === "signup" ? (
                      <>
                        <div className="grid gap-2" key={"firstname"}>
                          <Label htmlFor="firstname">Firstname</Label>
                          <Input
                            id="firstname"
                            type="text"
                            placeholder="Michael"
                            required
                            onChange={handleChange}
                            value={values.firstname}
                          />
                          {errors.firstname && touched.firstname ? (
                            <span className="text-sm font-light text-red-500 font-gelion">
                              {errors.firstname}
                            </span>
                          ) : null}
                        </div>
                        <div className="grid gap-2" key={"lastname"}>
                          <Label htmlFor="lastname">Lastname</Label>
                          <Input
                            id="lastname"
                            type="text"
                            placeholder="Quaynor"
                            required
                            onChange={handleChange}
                            value={values.lastname}
                          />
                          {errors.lastname && touched.lastname ? (
                            <span className="text-sm font-light text-red-500 font-gelion">
                              {errors.lastname}
                            </span>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                    {activePage !== "resetPassword" ? (
                      <div className="grid gap-2">
                        <Label htmlFor="email">Email</Label>
                        <Input
                          id="email"
                          type="email"
                          placeholder="m@example.com"
                          required
                          onChange={handleChange}
                          value={values.email}
                        />
                        {errors.email && touched.email ? (
                          <span className="text-sm font-light text-red-500 font-gelion">
                            {errors.email}
                          </span>
                        ) : null}
                      </div>
                    ) : null}
                    {activePage !== "forgot" ? (
                      <div className="grid gap-2">
                        <div className="flex items-center">
                          <Label htmlFor="password">Password</Label>
                          {activePage === "login" ? (
                            <span
                              className="ml-auto inline-block text-sm underline cursor-pointer"
                              onClick={() => {
                                setActivePage("forgot");
                              }}
                            >
                              Forgot your password?
                            </span>
                          ) : null}
                        </div>
                        <div className="flex justify-start items-center border rounded-md">
                          <Input
                            className="border-0"
                            id="password"
                            type="password"
                            placeholder="••••••••••"
                            required
                            ref={passRef}
                            onChange={handleChange}
                            value={values?.password}
                          />
                          <div
                            className="pe-3 cursor-pointer"
                            onClick={toggleVisible}
                          >
                            {visiblePass ? (
                              <EyeOff className="text-gray-400" />
                            ) : (
                              <Eye className="text-gray-400" />
                            )}
                          </div>
                        </div>
                        {errors.password && touched.password ? (
                          <span className="text-sm font-light text-red-500 font-gelion">
                            {errors.password}
                          </span>
                        ) : null}
                      </div>
                    ) : null}

                    {activePage === "resetPassword" ? (
                      <div className="grid gap-2">
                        <div className="flex items-center">
                          <Label htmlFor="confirmPassword">
                            Confirm Password
                          </Label>
                        </div>
                        <div className="flex justify-start items-center border rounded-md">
                          <Input
                            className="border-0"
                            id="confirmPassword"
                            type="password"
                            placeholder="••••••••••"
                            required
                            ref={confirmPassRef}
                            onChange={handleChange}
                            value={values?.confirmPassword}
                          />
                          <div
                            className="pe-3 cursor-pointer"
                            onClick={toggleConfirmVisible}
                          >
                            {visibleConfirmPass ? (
                              <EyeOff className="text-gray-400" />
                            ) : (
                              <Eye className="text-gray-400" />
                            )}
                          </div>
                        </div>
                        {errors.confirmPassword && touched.confirmPassword ? (
                          <span className="text-sm font-light text-red-500 font-gelion">
                            {errors.confirmPassword}
                          </span>
                        ) : null}
                      </div>
                    ) : null}
                    <Button
                      type="submit"
                      className="w-full bg-black"
                      key="btnsubmit"
                    >
                      {activePage === "login"
                        ? "Sign in account"
                        : activePage === "signup"
                        ? "Create account"
                        : activePage === "forgot"
                        ? "Submit request"
                        : activePage === "resetPassword"
                        ? "Submit"
                        : "Create account"}
                    </Button>
                    {/* <div className="flex justify-center items-center gap-2">
                      {signBtn.map((btn) => (
                        <>
                          {btn.name === "apple" ? (
                            <AppleLogin
                              key={btn.name}
                              clientId="app.outfun.event"
                              redirectURI="localhost:3001"
                              render={(props) => (
                                <Button
                                  variant="ghost"
                                  className="w-full"
                                  key={btn.name}
                                  onClick={props.onClick}
                                >
                                  <div className="flex">
                                    <img
                                      src={btn.icon}
                                      alt=""
                                      width={18}
                                      className="me-2"
                                    />
                                  </div>
                                </Button>
                              )}
                            />
                          ) : (
                            <Button
                              variant="ghost"
                              onClick={() => handleSocialAuth(btn.name)}
                              className="w-full"
                              aria-label={`Login with ${btn.name}`}
                              key={btn.name}
                            >
                              <div className="flex">
                                <img
                                  src={btn.icon}
                                  alt=""
                                  width={18}
                                  className="me-2"
                                />
                              </div>
                            </Button>
                          )}
                        </>
                      ))}
                    </div> */}
                  </div>
                </form>
              )}
            </Formik>
            <div className="mt-4 text-center text-sm">
              Don&apos;t have an account?{" "}
              <span
                onClick={() => {
                  setActivePage(activePage === "login" ? "signup" : "login");
                }}
                className="underline cursor-pointer"
              >
                {activePage === "login" ? "Sign up" : "Sign in"}
              </span>
            </div>
          </div>
        </div>
        <div className="hidden bg-muted lg:block h-screen">
          <img
            src={loginImg}
            alt="login"
            className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
          />
        </div>
      </div>
    </>
  );
};

export default AuthPage;
