import { Skeleton } from "@/components/ui/skeleton";

const SkeletonCard = () => {
  return (
    <div className="border p-3 mb-3 rounded-md">
      <div className="flex items-center space-x-4">
        <Skeleton className="h-10 w-10 rounded-full" />
        <div className="space-y-2">
          <Skeleton className="h-3 w-[250px]" />
          <Skeleton className="h-3 w-[200px]" />
        </div>
      </div>
      <div className="mt-2">
        <Skeleton className="h-3 mb-1 w-[250px]" />
        <Skeleton className="h-3 mb-1 w-[200px]" />
        <Skeleton className="h-3 mb-1 w-[250px]" />
        <Skeleton className="h-3 mb-1 w-[250px]" />
      </div>
    </div>
  );
};

const SkeletonLine = () => {
  return (
    <div className="border p-3 mb-3 rounded-md">
      <div className="mt-2">
        <Skeleton className="h-3 mb-1 w-[250px]" />
        <Skeleton className="h-3 mb-1 w-[200px]" />
        <Skeleton className="h-3 mb-1 w-[250px]" />
        <Skeleton className="h-3 mb-1 w-[250px]" />
      </div>
    </div>
  );
};

const SkeletonList = () => {
  return (
    <>
      {Array.from({ length: 3 }, (_, i) => i + 1).map((list) => (
        <SkeletonCard key={list} />
      ))}
    </>
  );
};

const SkeletonSearchList = () => {
  return (
    <>
      {Array.from({ length: 3 }, (_, i) => i + 1).map((list) => (
        <div className="border p-3 mb-3 rounded-md w-full" key={list}>
          <div className="flex items-center space-x-4">
            <Skeleton className="h-10 w-10 rounded-md" />
            <div className="space-y-2 w-full">
              <Skeleton className="h-4 w-full" />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export { SkeletonCard, SkeletonList, SkeletonSearchList, SkeletonLine };
