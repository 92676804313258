import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import {
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
  Select,
} from "@/components/ui/select";
import { TabsList, TabsTrigger, TabsContent, Tabs } from "@/components/ui/tabs";
import { Clock, Ticket, Trash2 } from "lucide-react";
import TicketCard from "./TicketCard";
import { useEffect, useState } from "react";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import { EmptyContainer } from "components/custom/EmptyState";
import { SkeletonCard } from "components/custom/SkeletonCards";
import { uniqBy } from "lodash";
import PaginationComp from "components/custom/PaginationComp";
import { nowDate } from "services/misc";

const TicketCountCard = ({ data, viewType }: any) => {
  return (
    <div className="border rounded-lg mb-2 w-full text-left p-2 px-4">
      <h6 className="font-medium text-md">
        Transaction ID:{" "}
        <span className="uppercase">#{data.transactionId || "Free"}</span>
      </h6>
      <div className="flex justify-start items-center divide-x gap-3">
        <div className="flex items-center gap-1">
          <Ticket size={20} className="text-gray-500" />
          <p className="text-md font-normal text-gray-500">
            No. of Tickets: {data.tickets.length}
          </p>
        </div>
        {""}
        <div className="flex items-center gap-1">
          <Clock size={20} className="text-gray-500 ms-2" />
          <p className="text-md font-normal text-gray-500">
            {" "}
            {nowDate(
              viewType === "sold" ? data.createdAt : data.latestCheckTime
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

const ViewTicket = ({ data, event, delFunc, updateFunc, currency }: any) => {
  const [status, setStatus] = useState(data.status);

  const initPageData = {
    page: 1,
    total: 0,
  };
  const initSoldPageData = {
    page: 1,
    total: 0,
  };
  const axiosPrivate = useAxiosPrivate();
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );
  const [allTickets, setAllTickets] = useState<any[]>([]);
  const [allSoldTickets, setAllSoldTickets] = useState<any[]>([]);
  const [emptyState, setEmptyState] = useState(false);
  const [emptySoldState, setEmptySoldState] = useState(false);
  const [pageData, setPageData] = useState(initPageData);
  const [pageSoldData, setPageSoldData] = useState(initSoldPageData);

  const getSoldTickets = async () => {
    try {
      const sold = await axiosPrivate.get(
        `/tickets/sold/${accountData._id}/${dashData._id}`,
        {
          params: {
            page: pageData.page,
            ticketType: data._id,
          },
        }
      );
      const responseData = sold.data.data;
      setPageSoldData({ ...pageSoldData, total: sold.data.total });
      setAllSoldTickets([...allSoldTickets, ...responseData]);
      if (responseData.length === 0 && allSoldTickets.length === 0) {
        setEmptySoldState(true);
      }
    } catch (error: any) {}
  };

  const getScannedTickets = async () => {
    try {
      const scanned = await axiosPrivate.get(
        `/tickets/scanned/${accountData._id}/${dashData._id}`,
        {
          params: {
            page: pageData.page,
            ticketType: data._id,
          },
        }
      );
      const responseData = scanned.data.data;
      setPageData({ ...pageData, total: scanned.data.total });
      setAllTickets([...allTickets, ...responseData]);
      if (responseData.length === 0 && allTickets.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {}
  };

  const changeStatus = () => {
    if (data.status !== status) {
      updateFunc(data, status);
    }
  };

  useEffect(() => {
    if (data !== undefined) {
      getScannedTickets();
    }
  }, [pageData.page]);

  useEffect(() => {
    if (data !== undefined) {
      getSoldTickets();
    }
  }, [pageSoldData.page]);

  return (
    <Card className="w-full p-0 border-0 shadow-none">
      <CardHeader>
        <CardTitle className="capitalize">{event.name} stats</CardTitle>
        <CardDescription>Shows detailed stats of tickets</CardDescription>
      </CardHeader>
      <CardContent>
        <TicketCard currency={currency} viewBool={true} key={1} data={data} />
        <div className="mt-3">
          <Tabs defaultValue="scanned">
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="scanned">Scanned</TabsTrigger>
              <TabsTrigger value="sold">Sold</TabsTrigger>
              <TabsTrigger value="actions">Actions</TabsTrigger>
            </TabsList>
            <TabsContent
              className="max-h-[50vh] overflow-y-scroll"
              value="scanned"
            >
              {allTickets !== undefined && allTickets.length !== 0 ? (
                <>
                  {uniqBy(allTickets, "_id").map((ticket) => (
                    <>
                      <TicketCountCard data={ticket} />
                    </>
                  ))}
                  <div className="my-3 w-full col-span-2">
                    <PaginationComp
                      currentPage={pageData.page}
                      total={pageData.total}
                      docCount={allTickets.length}
                      setPageData={setPageData}
                      pageName="page"
                      pageData={pageData}
                    />
                  </div>
                </>
              ) : allTickets.length === 0 && !emptyState ? (
                <>
                  {Array.from({ length: 4 }).map((card) => (
                    <SkeletonCard />
                  ))}
                </>
              ) : (
                <div className="col-span-3">
                  <EmptyContainer
                    message={"No scanned tickets yet!"}
                    instructions={`Tickets scanned will show here.`}
                  />
                </div>
              )}
              {/* <div className="grid grid-col-3">
                {Array.from({ length: 50 }).map((ticket) => (
                  
                ))}
              </div> */}
            </TabsContent>
            <TabsContent
              className="max-h-[50vh] overflow-y-scroll"
              value="sold"
            >
              {allSoldTickets !== undefined && allSoldTickets.length !== 0 ? (
                <>
                  {uniqBy(allSoldTickets, "_id").map((ticket) => (
                    <>
                      <TicketCountCard data={ticket} viewType="sold" />
                    </>
                  ))}
                  <div className="my-3 w-full col-span-2">
                    <PaginationComp
                      currentPage={pageSoldData.page}
                      total={pageSoldData.total}
                      docCount={allSoldTickets.length}
                      setPageData={setPageSoldData}
                      pageName="page"
                      pageData={pageSoldData}
                    />
                  </div>
                </>
              ) : allSoldTickets.length === 0 && !emptyState ? (
                <>
                  {Array.from({ length: 4 }).map((card) => (
                    <SkeletonCard />
                  ))}
                </>
              ) : (
                <div className="col-span-3">
                  <EmptyContainer
                    message={"No sold tickets yet!"}
                    instructions={`Tickets sold will show here.`}
                  />
                </div>
              )}
              {/* <div className="grid grid-col-3">
                {Array.from({ length: 50 }).map((ticket) => (
                  
                ))}
              </div> */}
            </TabsContent>
            <TabsContent className="max-h-[50vh]" value="actions">
              <div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="maxtickets">Status</Label>
                  <Select onValueChange={(e) => setStatus(e)} value={status}>
                    <SelectTrigger id="maxtickets" className="capitalize">
                      <SelectValue placeholder="Select" />
                    </SelectTrigger>
                    <SelectContent position="popper">
                      {["live", "ended", "on hold"].map((status) => (
                        <SelectItem
                          className="capitalize"
                          value={`${status}`}
                          key={status}
                        >
                          {status}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                <div className="flex justify-between items-center mt-4">
                  <Button className="bg-red-600" onClick={() => delFunc(data)}>
                    <Trash2 color="#fff" /> Delete Ticket
                  </Button>
                  <Button className="bg-black" onClick={changeStatus}>
                    Submit
                  </Button>
                </div>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </CardContent>
    </Card>
  );
};

export default ViewTicket;
