import React from "react";
import VenueSettings from "./components/VenueSettings";
import EventSettings from "./components/EventSettings";
import { useSelector } from "react-redux";

type Props = {};

const DashboardSettings = (props: Props) => {
  const { accountData } = useSelector((state: any) => state.dashboard);
  return (
    <div>
      {accountData.business_type === "event_organizer" ? (
        <EventSettings />
      ) : accountData.business_type === "venue_manager" ? (
        <VenueSettings />
      ) : null}
    </div>
  );
};

export default DashboardSettings;
