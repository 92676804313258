import { CircleOff, Frown } from "lucide-react";

type Props = {
  message: string;
  instructions?: string;
};

const EmptyState = ({ message }: Props) => {
  return (
    <div className="w-full h-32 bg-gray-50 rounded-md table">
      <div className="table-cell align-middle text-center">
        <div className="flex justify-center items-center">
          <Frown size={15} className="text-gray-500" />
          <span className="text-gray-500 ms-2">{message}</span>
        </div>
      </div>
    </div>
  );
};

const EmptyContainer = ({ message, instructions }: Props) => {
  return (
    <div className="w-full justify-center flex flex-col align-middle h-[60vh] rounded-lg bg-gray-100">
      <div className="text-center flex flex-col justify-center items-center">
        <CircleOff size={40} className="text-gray-500" />
        <h4 className="text-lg font-bold text-gray-500">{message}</h4>
        <span className="text-md text-gray-400">{instructions}</span>
      </div>
    </div>
  );
};

export { EmptyState, EmptyContainer };
