import React from "react";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { format } from "date-fns";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  PopoverTrigger,
  PopoverContent,
  Popover,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { CalendarIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import useUIMisc from "hooks/useUIMisc";
import { useSelector } from "react-redux";
import { formValidations } from "utils/validations/formValidations";
import * as yup from "yup";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Formik } from "formik";
import { getToday } from "services/misc";

type TicketProps = {
  addFunc: (data: any) => void;
  updateFunc: (type: string, data: any) => void;
};

const AddTicket = ({ addFunc, updateFunc }: TicketProps) => {
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const axiosPrivate = useAxiosPrivate();
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );
  const initTicketValues = {
    status: "",
    qty: 0,
    desc: "",
    name: "",
    ticketType: "",
    maxOrder: 1,
    price: 0,
    startDate: "",
    endDate: "",
  };

  const ticketSchema = yup.object().shape({
    status: formValidations.default,
    desc: formValidations.desc,
    endDate: formValidations.datetime,
    startDate: formValidations.datetime,
    qty: formValidations.defaultNum,
    name: formValidations.defaultString,
    ticketType: formValidations.defaultString,
    price: formValidations.price,
    maxOrder: formValidations.defaultNum,
  });

  const handleSubmit = async (values: any) => {
    showLoading();
    const ticketData = {
      ...values,
      maxOrder: parseInt(values.maxOrder),
      eventId: dashData._id,
    };
    try {
      const ticketRes = await axiosPrivate.post(
        `/tickets/manager/${accountData._id}`,
        ticketData
      );
      const ticket = ticketRes.data.data;
      closeLoading();
      showToast(`Ticket added successful`, "success");
      addFunc(ticket);
      updateFunc("add", ticket);
    } catch (error: any) {
      closeLoading();
      showToast(`An error occured!\n ${error.message}\n Try again!`, "error");
    }
  };

  const [date, setDate] = React.useState<Date>();
  const [endDate, setEndDate] = React.useState<Date>();
  return (
    <div>
      <Card className="w-full p-0 border-0 shadow-none">
        <CardHeader>
          <CardTitle>Add Ticket</CardTitle>
          <CardDescription>
            Fill the following fields. Click submit when you're done.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Formik
            validationSchema={ticketSchema}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            initialValues={initTicketValues}
          >
            {({
              values,
              errors,
              handleChange,
              touched,
              setFieldValue,
              resetForm,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="grid gap-4 mb-4 sm:grid-cols-2">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="name">Name of Ticket</Label>
                    <Input
                      id="name"
                      type="text"
                      placeholder="Early Bird"
                      onChange={handleChange}
                      name="name"
                      value={values.name}
                    />
                    {errors.name && touched.name && (
                      <span className="text-sm font-light text-red-500">
                        {errors.name}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="ticketType">Ticket Type</Label>
                    <Select
                      name="ticketType"
                      onValueChange={(e) => {
                        setFieldValue("ticketType", e);
                      }}
                      value={values.ticketType}
                    >
                      <SelectTrigger id="ticketType" className="capitalize">
                        <SelectValue placeholder="Select ticket type" />
                      </SelectTrigger>
                      <SelectContent position="popper">
                        {["free", "paid"].map((ticketType) => (
                          <SelectItem
                            className="capitalize"
                            value={`${ticketType}`}
                            key={ticketType}
                          >
                            {ticketType}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {errors.ticketType && touched.ticketType && (
                      <span className="text-sm font-light text-red-500">
                        {errors.ticketType}
                      </span>
                    )}
                  </div>
                </div>
                <div className="grid gap-4 mb-4 sm:grid-cols-2">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="price">Price</Label>
                    <Input
                      id="price"
                      type="text"
                      placeholder="100"
                      onChange={handleChange}
                      name="price"
                      value={values.price}
                    />
                    {errors.price && touched.price && (
                      <span className="text-sm font-light text-red-500">
                        {errors.price}
                      </span>
                    )}
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="qty">Qty</Label>
                    <Input
                      id="qty"
                      type="text"
                      placeholder="500"
                      onChange={handleChange}
                      name="qty"
                      value={values.qty}
                    />
                    {errors.qty && touched.qty && (
                      <span className="text-sm font-light text-red-500">
                        {errors.qty}
                      </span>
                    )}
                  </div>
                </div>

                <div className="grid gap-4 mb-4 sm:grid-cols-2">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="maxOrder">Max Tickets per Order</Label>
                    <Select
                      name="maxOrder"
                      onValueChange={(e) => {
                        setFieldValue("maxOrder", e);
                      }}
                      value={`${values.maxOrder}`}
                    >
                      <SelectTrigger id="maxOrder">
                        <SelectValue placeholder="Select no. of tickets" />
                      </SelectTrigger>
                      <SelectContent position="popper">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((count) => (
                          <SelectItem value={`${count}`} key={count}>
                            {count}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="status">Status</Label>
                    <Select
                      name="status"
                      onValueChange={(e) => {
                        setFieldValue("status", e);
                      }}
                      value={values.status}
                    >
                      <SelectTrigger id="status" className="capitalize">
                        <SelectValue placeholder="Select status" />
                      </SelectTrigger>
                      <SelectContent position="popper">
                        {["live", "ended", "hold"].map((status) => (
                          <SelectItem
                            className="capitalize"
                            value={`${status}`}
                            key={status}
                          >
                            {status}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    {errors.status && touched.status && (
                      <span className="text-sm font-light text-red-500">
                        {errors.status}
                      </span>
                    )}
                  </div>
                </div>
                <div className="grid gap-4 mb-4 sm:grid-cols-2">
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="startDate">Start Selling</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full justify-start text-left font-normal",
                            !date && "text-muted-foreground"
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {date ? (
                            format(date, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          disabled={(date) => date < getToday()}
                          selected={date}
                          onSelect={(e) => {
                            setFieldValue("startDate", e);
                            setDate(e);
                          }}
                          initialFocus
                        />
                        {errors.startDate && touched.startDate && (
                          <span className="text-sm font-light text-red-500">
                            {errors.startDate}
                          </span>
                        )}
                      </PopoverContent>
                    </Popover>
                  </div>
                  <div className="flex flex-col space-y-1.5">
                    <Label htmlFor="endDate">End Selling</Label>
                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full justify-start text-left font-normal",
                            !endDate && "text-muted-foreground"
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {endDate ? (
                            format(endDate, "PPP")
                          ) : (
                            <span>Pick a date</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={endDate}
                          disabled={(date) => date < getToday()}
                          onSelect={(e) => {
                            setFieldValue("endDate", e);
                            setEndDate(e);
                          }}
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                    {errors.endDate && touched.endDate && (
                      <span className="text-sm font-light text-red-500">
                        {errors.endDate}
                      </span>
                    )}
                  </div>
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="desc">Description</Label>
                  <Textarea
                    placeholder="Type your message here."
                    className="resize-none"
                    onChange={handleChange}
                    name="desc"
                    value={values.desc}
                  />
                  {errors.desc && touched.desc && (
                    <span className="text-sm font-light text-red-500">
                      {errors.desc}
                    </span>
                  )}
                </div>
                <div className="flex justify-end mt-6">
                  <Button className="bg-black">Submit</Button>
                </div>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </div>
  );
};

export default AddTicket;
