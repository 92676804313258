import { LandingFooter, LandingHeader } from "pages/components";
import { Link } from "react-router-dom";
import { Mail, Map, PhoneCall } from "lucide-react";
import { useEffect } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { formValidations } from "utils/validations/formValidations";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Helmet } from "react-helmet-async";
type Props = {};

const Contact = (props: Props) => {
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const axiosPrivate = useAxiosPrivate();
  const contactSchema = yup.object().shape({
    email: formValidations.email,
    desc: formValidations.desc,
    name: formValidations.defaultText,
    subject: formValidations.defaultText,
  });

  const initContactValues = {
    email: "",
    desc: "",
    name: "",
    subject: "",
  };

  const handleSubmit = async (values: any) => {
    showLoading();
    const contactData = {
      ...values,
    };
    try {
      await axiosPrivate.post(`/contacts`, contactData);
      closeLoading();
      showToast(`Message sent successfully!`, "success");
    } catch (error: any) {
      closeLoading();
      showToast(`An error occured!\n ${error.message}\n Try again!`, "error");
    }
  };

  const hasErrors = (errors: any) => {
    return !!(errors.email || errors.desc || errors.name || errors.subject);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <title>Contact OutFun - Event Booking Support</title>
        <meta
          name="description"
          content="Get in touch with the OutFun team for support or inquiries."
        />
        <meta
          name="keywords"
          content="contact OutFun, event booking support, event inquiries"
        />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "ContactPage",
        "name": "Contact OutFun - Event Booking Support",
        "url": "https://outfun.app/contact",
        "description": "Get in touch with the OutFun team for support, inquiries, or assistance with your event bookings.",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+233-546-522014",
          "contactType": "Customer Service",
        },
        "publisher": {
          "@type": "Organization",
          "name": "OutFun",
          "url": "https://outfun.app"
        }
      }
    `}
        </script>
      </Helmet>
      <div>
        <LandingHeader />
        <div className="mx-auto max-w-screen-xl">
          <section className="bg-white dark:bg-gray-900">
            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                Contact Us
              </h2>
              <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                Got a technical issue? Want to send feedback about a beta
                feature? Need details about our Business plan? Let us know.
              </p>
              <Formik
                validationSchema={contactSchema}
                onSubmit={(values) => {
                  handleSubmit(values);
                }}
                initialValues={initContactValues}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  touched,
                  resetForm,
                  handleSubmit,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Name
                      </label>
                      <Input
                        id="name"
                        type="text"
                        placeholder="Michael Quaynor"
                        onChange={handleChange}
                        name="name"
                        value={values.name}
                      />
                      {errors.name && touched.name && (
                        <span className="text-sm font-light text-red-500">
                          {errors.name}
                        </span>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Your email
                      </label>
                      <Input
                        id="email"
                        type="email"
                        placeholder="your@email.com"
                        onChange={handleChange}
                        name="email"
                        value={values.email}
                      />
                      {errors.email && touched.email && (
                        <span className="text-sm font-light text-red-500">
                          {errors.email}
                        </span>
                      )}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="subject"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Subject
                      </label>
                      <Input
                        id="subject"
                        type="text"
                        placeholder="Let us know how we can help you"
                        onChange={handleChange}
                        name="subject"
                        value={values.subject}
                      />
                      {errors.subject && touched.subject && (
                        <span className="text-sm font-light text-red-500">
                          {errors.subject}
                        </span>
                      )}
                    </div>
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="message"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-400"
                      >
                        Your message
                      </label>
                      <Textarea
                        placeholder="Type your message here."
                        className="resize-none"
                        onChange={handleChange}
                        name="desc"
                        rows={6}
                        value={values.desc}
                      />
                      {errors.desc && touched.desc && (
                        <span className="text-sm font-light text-red-500">
                          {errors.desc}
                        </span>
                      )}
                    </div>
                    <div className="text-center mt-3">
                      <Button
                        className="bg-black"
                        onClick={() => {
                          setTimeout(() => {
                            if (!hasErrors(errors)) {
                              resetForm();
                            }
                          }, 1500);
                        }}
                      >
                        Send message
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </section>
          <section className="mb-20">
            <div className="grid md:grid-cols-3 gap-4 px-4 md:px-0">
              <div className="w-full md:max-w-sm p-6 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700">
                <Map size={30} className="mx-auto" />
                <Link to="#">
                  <p className="font-normal text-gray-500 dark:text-gray-400 capitalize">
                    Address
                  </p>
                  <h6 className="mb-1 text-xl capitalize font-semibold tracking-tight text-gray-900 dark:text-white">
                    tsokome street.
                  </h6>
                </Link>
              </div>
              <div className="w-full md:max-w-sm p-6 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700">
                <Mail size={30} className="mx-auto" />
                <Link to="#">
                  <p className="font-normal text-gray-500 dark:text-gray-400 capitalize">
                    Email
                  </p>
                  <h6 className="mb-1 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
                    hello@outfun.app
                  </h6>
                </Link>
              </div>
              <div className="w-full md:max-w-sm p-6 text-center bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700">
                <PhoneCall size={30} className="mx-auto" />
                <Link to="#">
                  <p className="font-normal text-gray-500 dark:text-gray-400 capitalize">
                    Call
                  </p>
                  <h6 className="mb-1 text-xl capitalize font-semibold tracking-tight text-gray-900 dark:text-white">
                    +233 546 522 014
                  </h6>
                </Link>
              </div>
            </div>
          </section>
        </div>
        <LandingFooter />
      </div>
    </>
  );
};

export default Contact;
