import { Card, CardContent } from "@/components/ui/card";
import { ppholder } from "constants/assets";
import { customStyles } from "constants/ui";
import { Rating } from "@smastrom/react-rating";
import { Separator } from "@/components/ui/separator";
import moment from "moment";

type VRProps = {
  // setFunc?: React.Dispatch<React.SetStateAction<boolean>> | any;
  setFunc?: (data: any) => void;
  data: any;
};

const ReviewCard = ({ setFunc, data }: VRProps) => {
  return (
    <Card
      className="w-full p-0 border-1 shadow-sm"
      onClick={() => setFunc?.(data)}
    >
      <CardContent className="pt-6">
        <div>
          <div className="flex gap-3 justify-start items-start">
            <img
              src={
                data.userData.profile_img !== null
                  ? data.userData.profile_img.url
                  : ppholder
              }
              alt=""
              className="w-14 h-14 rounded-lg object-cover"
            />
            <div className="w-full">
              <h5 className="text-lg font-medium tracking-tight text-black">
                @{data.userData.username}
              </h5>
              <p className="m-0 font-normal text-gray-500">User</p>
            </div>
          </div>
        </div>
        <div className="my-3">
          <p className="text-sm">{data.review}</p>
        </div>
        <Separator className="my-3" />
        <div className="flex gap-2 justify-start items-center">
          <div className="flex justify-center items-center flex-col w-10 h-10 bg-black text-white rounded-md">
            <p className="font-medium text-sm">{data.rating.toFixed(1)}</p>
          </div>
          <div>
            <Rating
              style={{ maxWidth: 100 }}
              value={data.rating}
              readOnly
              itemStyles={customStyles}
              // onChange={(selectedValue) => setRating(selectedValue)}
              spaceBetween="small"
              spaceInside="medium"
            />
            <span className="text-sm text-gray-500 capitalize">
              {moment(data.createdAt).fromNow()}
            </span>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ReviewCard;
