import { Navigate, Route, Routes } from "react-router-dom";
import {
  AuthPage,
  BookingsPage,
  CampaignsPage,
  Contact,
  DashboardSettings,
  FeedsPage,
  Home,
  OrganizerHome,
  PageManager,
  PageOpener,
  Privacy,
  ReviewsPage,
  TeamsPage,
  Terms,
  TicketsPage,
  TransactionsPage,
} from "../pages";
import { OrganizerRoutes } from "./ProtectedRoutes";
import { useSelector } from "react-redux";
import PageNotFound from "pages/auth/PageNotFound";

const AllRoutes = () => {
  const { oforg, loggedIn } = useSelector((state: any) => state.auth);
  const { dashData, accountData } = useSelector(
    (state: any) => state.dashboard
  );

  return (
    <Routes>
      <Route
        element={
          loggedIn ? (
            <Navigate to="/outfunner/accounts" replace />
          ) : (
            <AuthPage />
          )
        }
        path="/login"
      ></Route>
      <Route
        element={
          loggedIn ? (
            <Navigate to="/outfunner/accounts" replace />
          ) : (
            <AuthPage />
          )
        }
        path="/auth/:id/password-reset/:token"
      ></Route>
      <Route element={<Home />} path="/"></Route>
      <Route element={<Home />} path="/home"></Route>
      <Route element={<Contact />} path="/contact"></Route>
      <Route element={<Privacy />} path="/privacy-policy"></Route>
      <Route element={<Terms />} path="/terms"></Route>
      <Route element={<OrganizerRoutes />} path="/outfunner">
        <Route path="" element={<Navigate to="/outfunner/dashboard" />} />
        <Route element={<PageOpener />} path="accounts"></Route>
        <Route
          path="manager/:id"
          element={
            accountData && oforg !== null && oforg.email_verified ? (
              <PageManager />
            ) : (
              <Navigate to="/outfunner/accounts" />
            )
          }
        />
        <Route
          path="dashboard"
          element={
            accountData &&
            dashData &&
            oforg !== null &&
            oforg.email_verified ? (
              <OrganizerHome />
            ) : (
              <Navigate to="/outfunner/accounts" />
            )
          }
        />
        <Route
          path="tickets"
          element={
            accountData &&
            dashData &&
            oforg !== null &&
            oforg.email_verified ? (
              <TicketsPage />
            ) : (
              <Navigate to="/outfunner/accounts" />
            )
          }
        />
        <Route
          path="transactions"
          element={
            accountData &&
            dashData &&
            oforg !== null &&
            oforg.email_verified ? (
              <TransactionsPage />
            ) : (
              <Navigate to="/outfunner/accounts" />
            )
          }
        />
        <Route
          path="teams"
          element={
            accountData &&
            dashData &&
            oforg !== null &&
            oforg.email_verified ? (
              <TeamsPage />
            ) : (
              <Navigate to="/outfunner/accounts" />
            )
          }
        />
        <Route
          path="settings"
          element={
            accountData &&
            dashData &&
            oforg !== null &&
            oforg.email_verified ? (
              <DashboardSettings />
            ) : (
              <Navigate to="/outfunner/accounts" />
            )
          }
        />
        <Route
          path="bookings"
          element={
            accountData &&
            dashData &&
            oforg !== null &&
            oforg.email_verified ? (
              <BookingsPage />
            ) : (
              <Navigate to="/outfunner/accounts" />
            )
          }
        />
        <Route
          path="campaigns"
          element={
            accountData &&
            dashData &&
            oforg !== null &&
            oforg.email_verified ? (
              <CampaignsPage />
            ) : (
              <Navigate to="/outfunner/accounts" />
            )
          }
        />
        <Route
          path="reviews"
          element={
            accountData &&
            dashData &&
            oforg !== null &&
            oforg.email_verified ? (
              <ReviewsPage />
            ) : (
              <Navigate to="/outfunner/accounts" />
            )
          }
        />
        <Route
          path="feeds"
          element={
            accountData &&
            dashData &&
            oforg !== null &&
            oforg.email_verified ? (
              <FeedsPage />
            ) : (
              <Navigate to="/outfunner/accounts" />
            )
          }
        />
      </Route>
      <Route element={<PageNotFound />} path="*"></Route>
    </Routes>
  );
};

export default AllRoutes;
