import React, { useEffect, useState } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Button } from "@/components/ui/button";
import { CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { StatsCard } from "pages/components";
import {
  CheckCheck,
  CircleOff,
  Hourglass,
  ListFilter,
  Ticket,
} from "lucide-react";
import {
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  Table,
} from "@/components/ui/table";
import { Badge } from "@/components/ui/badge";
import { Card } from "flowbite-react";
import BookingView from "./components/BookingView";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { uniqBy } from "lodash";
import { SkeletonCard, SkeletonLine } from "components/custom/SkeletonCards";
import { EmptyContainer } from "components/custom/EmptyState";
import { cleanDate } from "services/misc";
import { useSelector } from "react-redux";
import useScrollToBottom from "hooks/useScrollToBottom";
type Props = {};

const BookingCard = ({ booking, openFunc }: any) => {
  return (
    <TableRow key={booking} onClick={() => openFunc(booking)}>
      <TableCell>
        <div className="font-medium">{booking.bookingId}</div>
      </TableCell>
      <TableCell className="hidden sm:table-cell">
        {cleanDate(booking.date)}
      </TableCell>
      <TableCell className="hidden sm:table-cell">
        <p className="line-clamp-1">{booking.description}</p>
      </TableCell>
      <TableCell className="hidden sm:table-cell">
        <Badge
          className={`text-white bg-emerald-500 font-normal hover:bg-black hover:text-white capitalize ${
            booking.status === "pending"
              ? "bg-yellow-300 text-black"
              : booking.status === "completed"
              ? " bg-emerald-500"
              : booking.status === "accepted"
              ? " bg-blue-600"
              : "bg-red-600"
          }`}
        >
          {booking.status}
        </Badge>
      </TableCell>
      <TableCell className="hidden sm:table-cell">
        {cleanDate(booking.createdAt)}
      </TableCell>
    </TableRow>
  );
};

const BookingsPage = (props: Props) => {
  const initFilter = {
    status: "all",
    sort: "desc",
  };
  const initStats = {
    totalPending: 0,
    totalCompleted: 0,
    totalRejected: 0,
    total: 0,
  };
  const [filter, setFilter] = useState(initFilter);
  const [openModal, setOpenModal] = useState(false);
  const [allBookings, setAllBookings] = useState<any[]>([]);
  const [emptyState, setEmptyState] = useState(false);
  const [selectedBooking, setSelectedBooking]: any = useState();
  const axiosPrivate = useAxiosPrivate();
  const [statsData, setStatsData]: any = useState(initStats);
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );
  const { showLoading, closeLoading, showToast } = useUIMisc();
  const initPageData = {
    page: 1,
    total: 0,
  };
  const [pageData, setPageData] = useState(initPageData);

  const openBooking = (data: any) => {
    setSelectedBooking(data);
    setOpenModal(true);
  };

  const getBookings = async () => {
    try {
      const bookings = await axiosPrivate.get(
        `/business/bus-bookings/${dashData._id}/venue`,
        {
          params: {
            page: pageData.page,
            status: filter.status,
          },
        }
      );
      const responseData = bookings.data.data;
      setPageData({ ...pageData, total: bookings.data.total });
      setAllBookings([...allBookings, ...responseData]);
      if (responseData.length === 0 && allBookings.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {}
  };

  const updateAllBookings = (status: any) => {
    const mainBookings: any = [...allBookings];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    const findIndex = mainBookings.findIndex(
      (a: any) => a._id === selectedBooking._id
    );
    mainBookings[findIndex] = {
      ...selectedBooking,
      busConfirm: status,
    };
    setSelectedBooking({ ...mainBookings[findIndex] });
    setAllBookings([...mainBookings]);
  };

  const getStats = async () => {
    try {
      const statsRes = await axiosPrivate.get(
        `/business/booking-stats/${dashData._id}/venue`
      );

      setStatsData(statsRes.data.data);
    } catch (error) {}
  };

  const updateStatus = async (status: any) => {
    try {
      await axiosPrivate.put(
        `/business/bookings/${dashData._id}/${selectedBooking._id}/venue`,
        {
          status,
        }
      );
      updateAllBookings(status);
      setTimeout(() => {
        showToast("Update successfully", "success");
      }, 500);
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  const changeFilter = (filterType: string) => {
    setAllBookings([]);
    setPageData(initPageData);
    setFilter({ ...filter, status: filterType });
  };

  useEffect(() => {
    getBookings();
  }, [pageData.page, filter.status]);

  const loadMore = () => {
    if (pageData.total !== allBookings.length) {
      setPageData({ ...pageData, page: pageData.page + 1 });
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  const loadMoreData = () => {
    setTimeout(() => {
      loadMore();
      resetFetching();
    }, 1000);
  };

  const { isFetching, resetFetching } = useScrollToBottom(loadMoreData, 100);

  return (
    <div className="grow">
      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent className="max-w-3xl max-h-screen">
          <BookingView booking={selectedBooking} updateFunc={updateStatus} />
        </DialogContent>
      </Dialog>

      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
        <StatsCard
          name="Total Bookings"
          figure={statsData.total || 0}
          iconData={{ icon: Ticket }}
          info="Number of bookings"
        />
        <StatsCard
          name="Total Completed Bookings"
          figure={statsData.totalCompleted || 0}
          iconData={{ icon: CheckCheck }}
          info="Number of completed bookings"
        />
        <StatsCard
          name="Total Pending Bookings"
          figure={statsData.totalPending || 0}
          iconData={{ icon: Hourglass }}
          info="Number of pending bookings"
        />
        <StatsCard
          name="Total Rejected Bookings"
          figure={statsData.totalRejected || 0}
          iconData={{ icon: CircleOff }}
          info="Number of rejected bookings"
        />
      </div>
      <div className="flex justify-between items-center mb-5">
        <h5 className="font-bold text-3xl">Bookings</h5>
        <div className="flex justify-end mt-4 gap-3">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <ListFilter />
                {""} Filter Bookings
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              {["all", "pending", "completed", "rejected"].map((status) => (
                <DropdownMenuItem
                  className="capitalize"
                  onClick={() => changeFilter(status)}
                >
                  {status}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div>
        <Card x-chunk="dashboard-05-chunk-3">
          <CardContent className="p-0">
            <Table>
              <TableHeader className="bg-accent">
                <TableRow>
                  <TableHead>Booking ID</TableHead>
                  <TableHead className="hidden sm:table-cell">
                    Booking Date
                  </TableHead>
                  <TableHead className="hidden sm:table-cell w-96">
                    Description
                  </TableHead>
                  <TableHead className="hidden sm:table-cell">Status</TableHead>
                  <TableHead className="hidden md:table-cell">Date</TableHead>
                </TableRow>
              </TableHeader>
              {allBookings !== undefined && allBookings.length !== 0 ? (
                <TableBody className="max-h-[40vh]">
                  {uniqBy(allBookings, "_id").map((booking) => (
                    <BookingCard booking={booking} openFunc={openBooking} />
                  ))}
                </TableBody>
              ) : allBookings.length === 0 && !emptyState ? (
                <>
                  <TableRow>
                    <TableCell colSpan={5}>
                      {Array.from({ length: 1 }).map((card) => (
                        <SkeletonLine />
                      ))}
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <EmptyContainer
                      message={"No bookings yet!"}
                      instructions={`Bookings will appear here!`}
                    />
                  </TableCell>
                </TableRow>
              )}
            </Table>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default BookingsPage;
