import axios from 'axios';
export default axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`
    // baseURL: `https://9ac8-154-161-12-120.ngrok-free.app/api`,
    // headers: {
    //     "ngrok-skip-browser-warning": "69420",
    // },
});

export const axiosPrivate = axios.create({
    baseURL: `${process.env.REACT_APP_BASE_URL}`,
    // baseURL: `https://9ac8-154-161-12-120.ngrok-free.app/api`,
    // headers: {
    //     "ngrok-skip-browser-warning": "69420",
    // },
    withCredentials: true
});