import { useEffect, useState } from 'react';

const useScrollToBottom = (callback: any, threshold = 100) => {
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        let ticking = false;

        const handleScroll = () => {
            if (!ticking) {
                window.requestAnimationFrame(() => {
                    const scrollPosition = window.innerHeight + window.scrollY;
                    const totalHeight = document.documentElement.scrollHeight;

                    if (scrollPosition >= totalHeight - threshold && !isFetching) {
                        setIsFetching(true);
                        callback();
                    }

                    ticking = false;
                });
                ticking = true;
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [callback, isFetching, threshold]);

    const resetFetching = () => setIsFetching(false);

    return { isFetching, resetFetching };
};

export default useScrollToBottom;
