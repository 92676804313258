import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Input } from "@/components/ui/input";
import {
  SelectTrigger,
  SelectValue,
  Select as MainSelect,
  SelectContent,
  SelectItem,
  SelectGroup,
} from "@/components/ui/select";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Image, Trash2 } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { ppholder } from "constants/assets";
import { useEffect, useState } from "react";
import { Label } from "@/components/ui/label";
import { Card } from "flowbite-react";
import * as yup from "yup";
import { Formik } from "formik";
import { formValidations } from "utils/validations/formValidations";
import useUIMisc from "hooks/useUIMisc";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import { mainCountries } from "utils/countries";
type EditManagerProps = {
  data: any;
  updateFunc: (data: any) => void;
};

const EditManager = ({ data, updateFunc }: EditManagerProps) => {
  const [subDisable, setSubDisable] = useState(false);
  const { dashData } = useSelector((state: any) => state.dashboard);
  const { showToast, closeLoading, showLoading } = useUIMisc();
  const axiosPrivate = useAxiosPrivate();
  const [image, setImage] = useState<any>(null);
  const [previewImage, setPreviewImage] = useState<any>(data.img);
  const initManagerValue = {
    name: data.name || "",
    bio: data.bio || "",
    business_type: data.business_type || "",
    origin: data.origin || "",
    username: data.username || "",
    website: data.website || "",
    email: data.email || "",
  };
  const accountSchema = yup.object().shape({
    name: formValidations.default,
    bio: formValidations.bio,
    origin: formValidations.address,
    website: formValidations.link,
    business_type: formValidations.default,
    email: formValidations.email,
    username: formValidations.username,
  });

  const socialSchema = yup.object().shape({
    facebook: formValidations.link,
    instagram: formValidations.link,
    twitter: formValidations.link,
    snapchat: formValidations.link,
    linkedin: formValidations.link,
    tiktok: formValidations.link,
    youtube: formValidations.link,
  });

  const initSocialValues = {
    facebook: data.facebook || "",
    instagram: data.instagram || "",
    twitter: data.twitter || "",
    snapchat: data.snapchat || "",
    linkedin: data.linkedin || "",
    tiktok: data.tiktok || "",
    youtube: data.youtube || "",
  };

  const updateManager = async (values: any) => {
    setSubDisable(true);
    showLoading();
    setTimeout(async () => {
      try {
        const editRes = await axiosPrivate.put(
          `/accounts/edit/${data._id}`,
          values
        );

        setSubDisable(false);
        const responseData = editRes.data.data;
        updateFunc(responseData);
        closeLoading();
        showToast("Account updated successfully!", "success");
      } catch (error: any) {
        setSubDisable(false);
        setTimeout(() => {
          closeLoading();
          showToast(
            `An error occured!\n ${error.message}\n Try again!`,
            "error"
          );
        }, 2000);
      }
    }, 500);
  };

  const handleSubmit = (values: any, type: string) => {
    updateManager(values);
  };

  const delImage = () => {
    setPreviewImage(null);
    setImage(null);
  };

  const chooseImg = () => {
    const inputElem = document.createElement("input");
    inputElem.type = "file";
    inputElem.name = "image";
    inputElem.setAttribute("accept", ".jpg, .png, .jpeg");
    inputElem.click();
    inputElem.onchange = (ev: any) => {
      if (ev.target.files[0].size > 3097152) {
        showToast(`Image must be 3MB maximum!`, "warning");
      } else {
        setPreviewImage({ url: URL.createObjectURL(ev.target.files[0]) });
        setImage(ev.target.files[0]);
        handleImageUpload(ev.target.files[0]);
      }
    };
  };

  const handleImageUpload = async (file: any) => {
    showLoading();
    let formData = new FormData();
    formData.append("image", file);
    try {
      const uploadRes = await axiosPrivate.put(
        `/accounts/profile-img/${data._id}`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      closeLoading();
      showToast(`Profile image updated successfully!`, "success");
      const responseData = uploadRes.data.data;
      setPreviewImage({
        url: responseData.image.url,
      });
      updateFunc(responseData);
    } catch (error: any) {
      setTimeout(() => {
        closeLoading();
        showToast(`${error.response.data.message}\n Try again!`, "error");
      }, 2000);
    }
  };

  useEffect(() => {
    if (dashData) {
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card x-chunk="dashboard-05-chunk-3" className="shadow-none border-none">
      <CardHeader className="p-0">
        <CardTitle>Edit Profile</CardTitle>
        <CardDescription>Make changes to your profile here</CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        <Tabs defaultValue="basic">
          <TabsList className="grid w-full mb-7 grid-cols-2">
            <TabsTrigger value="basic">Basic Information</TabsTrigger>
            <TabsTrigger value="photos">Photos & Socials</TabsTrigger>
          </TabsList>
          <TabsContent value="basic">
            <Formik
              validationSchema={accountSchema}
              onSubmit={(values) => {
                handleSubmit(values, "basic");
              }}
              initialValues={initManagerValue}
            >
              {({
                values,
                errors,
                handleChange,
                touched,
                setFieldValue,
                handleSubmit,
              }: any) => (
                <form onSubmit={handleSubmit}>
                  <div className="px-1">
                    <div className="grid gap-4 mb-4 sm:grid-cols-2">
                      <div>
                        <div className="flex flex-col space-y-1.5">
                          <Label htmlFor="name">Name</Label>
                          <Input
                            id="name"
                            type="text"
                            placeholder="OutFun Creations"
                            onChange={handleChange}
                            name="name"
                            value={values.name}
                          />
                          {errors.name && touched.name && (
                            <span className="text-sm font-light text-red-500">
                              {errors.name}
                            </span>
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="flex flex-col space-y-1.5">
                          <Label htmlFor="username">Username</Label>
                          <Input
                            id="username"
                            type="text"
                            placeholder="@outfunstudios"
                            readOnly
                            onChange={handleChange}
                            name="username"
                            value={values.username}
                          />
                          {errors.username && touched.username && (
                            <span className="text-sm font-light text-red-500">
                              {errors.username}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="grid gap-4 mb-4 sm:grid-cols-2">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="business_type">Business Type</Label>
                        <MainSelect
                          name="business_type"
                          onValueChange={(e) => {
                            setFieldValue("business_type", e);
                          }}
                          disabled
                          value={values.business_type}
                        >
                          <SelectTrigger
                            className="w-full focus:ring-0 mt-1"
                            id="business_type"
                          >
                            <SelectValue
                              className="focus:ring-0 font-normal"
                              placeholder="Select"
                            />
                          </SelectTrigger>
                          <SelectContent className="h-36">
                            <SelectGroup>
                              <SelectGroup>
                                <SelectItem value="event_organizer">
                                  Event Organizer
                                </SelectItem>
                                <SelectItem value="venue_manager">
                                  Venue Manager
                                </SelectItem>
                              </SelectGroup>
                            </SelectGroup>
                          </SelectContent>
                        </MainSelect>
                        {errors.business_type && touched.business_type && (
                          <span className="text-sm font-light text-red-500">
                            {errors.business_type}
                          </span>
                        )}
                      </div>
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="website">Website</Label>
                        <Input
                          id="website"
                          type="text"
                          placeholder="https://outfun.app"
                          onChange={handleChange}
                          name="website"
                          value={values.website}
                        />
                        {errors.website && touched.website && (
                          <span className="text-sm font-light text-red-500">
                            {errors.website}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <Label htmlFor="origin">Country</Label>
                      <MainSelect
                        name="origin"
                        onValueChange={(e) => {
                          setFieldValue("origin", e);
                        }}
                        value={values.origin}
                      >
                        <SelectTrigger
                          className="w-full focus:ring-0 mt-1"
                          id="origin"
                        >
                          <SelectValue
                            className="focus:ring-0 font-normal"
                            placeholder="Select country"
                          />
                        </SelectTrigger>
                        <SelectContent className="h-36">
                          <SelectGroup>
                            {mainCountries.map((country) => (
                              <SelectItem
                                value={country.name}
                                key={country.name}
                              >
                                {country.name} {country.flag}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </MainSelect>
                      {errors.origin && touched.origin && (
                        <span className="text-sm font-light text-red-500">
                          {errors.origin}
                        </span>
                      )}
                    </div>
                    <div className="mb-4">
                      <div className="flex flex-col space-y-1.5">
                        <Label htmlFor="bio">Bio</Label>
                        <Textarea
                          placeholder="Write a brief..."
                          className="resize-none"
                          rows={5}
                          onChange={handleChange}
                          name="bio"
                          value={values.bio}
                        />
                        {errors.bio && touched.bio && (
                          <span className="text-sm font-light text-red-500">
                            {errors.bio}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <Button
                      type="submit"
                      disabled={subDisable}
                      className="bg-black"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              )}
            </Formik>
          </TabsContent>
          <TabsContent value="photos" title="Photos & Socials">
            <div>
              <div className="flex justify-between items-start">
                <div>
                  <Label htmlFor="poster">Profile Image</Label>
                  <p className="text-muted-foreground text-sm mb-3">
                    Will appear on your profile
                  </p>
                </div>
                <Button
                  className={`bg-black hover:bg-black hover:text-white ${
                    previewImage !== null && "bg-red-700 text-white"
                  }`}
                  onClick={
                    previewImage === null ? () => chooseImg() : () => delImage()
                  }
                >
                  {previewImage === null ? (
                    <>
                      <Image size={13} className="me-1" />
                      Change image
                    </>
                  ) : (
                    <>
                      Delete image
                      <Trash2 size={13} className="me-1" />
                    </>
                  )}
                </Button>
              </div>
              <div className="grid grid-cols-5 gap-4">
                <div className="w-24 h-24 rounded-lg mb-7">
                  <img
                    src={
                      previewImage !== null && previewImage !== undefined
                        ? previewImage.url
                        : ppholder
                    }
                    alt=""
                    className="w-full h-full object-cover rounded-lg"
                  />
                </div>
              </div>
              <Formik
                validationSchema={socialSchema}
                onSubmit={(values) => {
                  handleSubmit(values, "social");
                }}
                initialValues={initSocialValues}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  touched,
                  handleSubmit,
                }: any) => (
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-2 gap-4">
                      <div className="space-y-1">
                        <Label htmlFor="facebook">Facebook Link</Label>
                        <Input
                          id="facebook"
                          className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                          placeholder="https://web.facebook.com/outfunapp"
                          onChange={handleChange}
                          name="facebook"
                          value={values.facebook}
                        />
                        {errors.facebook && touched.facebook && (
                          <span className="text-sm font-light text-red-500">
                            {errors.facebook}
                          </span>
                        )}
                      </div>
                      <div className="space-y-1">
                        <Label htmlFor="instagram">Instagram Link</Label>
                        <Input
                          id="instagram"
                          className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                          placeholder="https://instagram.com/outfunapp"
                          name="instagram"
                          onChange={handleChange}
                          value={values.instagram}
                        />
                        {errors.instagram && touched.instagram && (
                          <span className="text-sm font-light text-red-500">
                            {errors.instagram}
                          </span>
                        )}
                      </div>
                      <div className="space-y-1">
                        <Label htmlFor="twitter">Twitter Link</Label>
                        <Input
                          id="twitter"
                          className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                          placeholder="https://twitter.com/outfunapp"
                          name="twitter"
                          onChange={handleChange}
                          value={values.twitter}
                        />
                        {errors.twitter && touched.twitter && (
                          <span className="text-sm font-light text-red-500">
                            {errors.twitter}
                          </span>
                        )}
                      </div>
                      <div className="space-y-1">
                        <Label htmlFor="snapchat">Snapchat Link</Label>
                        <Input
                          id="snapchat"
                          className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                          placeholder="https://snapchat.com/outfunapp"
                          name="snapchat"
                          onChange={handleChange}
                          value={values.snapchat}
                        />
                        {errors.snapchat && touched.snapchat && (
                          <span className="text-sm font-light text-red-500">
                            {errors.snapchat}
                          </span>
                        )}
                      </div>
                      <div className="space-y-1">
                        <Label htmlFor="youtube">Youtube Link</Label>
                        <Input
                          id="youtube"
                          className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                          placeholder="https://youtube.com/outfunapp"
                          name="youtube"
                          onChange={handleChange}
                          value={values.youtube}
                        />
                        {errors.youtube && touched.youtube && (
                          <span className="text-sm font-light text-red-500">
                            {errors.youtube}
                          </span>
                        )}
                      </div>
                      <div className="space-y-1">
                        <Label htmlFor="linkedin">LinkedIn Link</Label>
                        <Input
                          id="linkedin"
                          className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                          placeholder="https://linkedin.com/outfunapp"
                          name="linkedin"
                          onChange={handleChange}
                          value={values.linkedin}
                        />
                        {errors.linkedin && touched.linkedin && (
                          <span className="text-sm font-light text-red-500">
                            {errors.linkedin}
                          </span>
                        )}
                      </div>
                      <div className="space-y-1">
                        <Label htmlFor="tiktok">Tiktok Link</Label>
                        <Input
                          id="tiktok"
                          className="focus-visible:outline-0 focus:outline-none text-md focus-visible:ring-0 text-sm font-light resize-none"
                          placeholder="https://tiktok.com/outfunapp"
                          name="tiktok"
                          onChange={handleChange}
                          value={values.tiktok}
                        />
                        {errors.tiktok && touched.tiktok && (
                          <span className="text-sm font-light text-red-500">
                            {errors.tiktok}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="text-center mt-5">
                      <Button type="submit" className="bg-black">
                        Submit
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </TabsContent>
        </Tabs>
      </CardContent>
    </Card>
  );
};

export default EditManager;
