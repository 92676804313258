import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { ListFilter } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import DEventCard from "./DEventCard";
import DVenueCard from "./DVenueCard";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import AddEditEvent from "pages/components/AddEditEvent";
import AddEditVenue from "pages/components/AddEditVenue";
import { useDispatch, useSelector } from "react-redux";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { uniqBy } from "lodash";
import { SkeletonCard } from "components/custom/SkeletonCards";
import { EmptyContainer } from "components/custom/EmptyState";
import SearchInput from "components/custom/SearchInput";
import { EVENT_FILTER } from "constants/data";
import { useNavigate } from "react-router-dom";
import {
  removeDashboardData,
  setDashboardData,
} from "redux/features/dashboardSlice";

const PageManager = () => {
  const { accountData } = useSelector((state: any) => state.dashboard);
  const [openModal, setOpenModal] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  const initFilter = {
    status: "all",
    sort: "desc",
  };
  const [filter, setFilter] = useState(initFilter);
  const [allPages, setAllPages] = useState<any[]>([]);
  const [emptyState, setEmptyState] = useState(false);
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const initPageData = {
    searchPage: 1,
    searchTotal: 0,
    page: 1,
    total: 0,
  };
  const [pageData, setPageData] = useState(initPageData);

  const getEvents = async () => {
    try {
      const getAllPages = await axiosPrivate.get(
        `/events/manager/${accountData._id}`,
        {
          params: {
            page: pageData.page,
            status: filter.status,
          },
        }
      );
      const responseData = getAllPages.data.data;
      setPageData({ ...pageData, total: getAllPages.data.total });
      setAllPages([...allPages, ...responseData]);
      if (responseData.length === 0 && allPages.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  const getBusinessVenue = async () => {
    try {
      const getAllPages = await axiosPrivate.get(
        `/business/manager/venue/${accountData._id}`,
        {
          params: {
            page: pageData.page,
            status: filter.status,
          },
        }
      );
      const responseData = getAllPages.data.data;
      setPageData({ ...pageData, total: getAllPages.data.total });
      setAllPages([...allPages, ...responseData]);
      if (responseData.length === 0 && allPages.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  const searchPages = async (type: string, pageNum?: number) => {
    // setSearchTerm(value);
    if (searchTerm === "") {
      setAllPages([]);
      setSearchTerm("");
      setPageData(initPageData);
    } else {
      try {
        const searchResults = await axiosPrivate.get(
          accountData.business_type === "event_organizer"
            ? `/events/manager/search/${accountData._id}`
            : `/business/manager/search/${accountData._id}`,
          {
            params: {
              q: searchTerm,
              page: type === "new" ? 1 : pageNum,
            },
          }
        );
        if (type === "more") {
          setAllPages([...allPages, ...searchResults.data.data]);
        } else {
          setAllPages(searchResults.data.data);
          setPageData({ ...pageData, searchTotal: searchResults.data.total });
          if (searchResults.data.data.length === 0) {
            setEmptyState(true);
          }
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (searchTerm !== "" && searchTerm.length > 3) {
      searchPages("new", 1);
    }
  }, [searchTerm]);

  const openEvent = (data: any) => {
    // setPageSelected(data);
    dispatch(setDashboardData(data));
    navigate("/outfunner/dashboard");
  };
  const openVenue = (data: any) => {
    // setPageSelected(data);
    dispatch(setDashboardData(data));
    navigate("/outfunner/dashboard");
  };

  const searchLoadMore = () => {
    if (pageData.searchTotal !== allPages.length) {
      setPageData({ ...pageData, searchPage: pageData.searchPage + 1 });
      searchPages("more", pageData.searchPage + 1);
    }
  };

  const changeFilter = (filterType: string) => {
    setSearchTerm("");
    setAllPages([]);
    setPageData(initPageData);
    setFilter({ ...filter, status: filterType });
  };

  const addNewData = (data: any) => {
    const allData = [data, ...allPages];
    setAllPages(allData);
    setPageData({ ...pageData, total: pageData.total + 1 });
    setOpenModal(false);
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (accountData.business_type === "event_organizer" && searchTerm === "") {
      getEvents();
    } else if (
      accountData.business_type === "venue_manager" &&
      searchTerm === ""
    ) {
      getBusinessVenue();
    }
  }, [accountData._id, searchTerm, pageData.page, filter.status]);

  useEffect(() => {
    dispatch(removeDashboardData());
  }, []);

  return (
    <div>
      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent className="max-w-3xl max-h-screen">
          {accountData.business_type === "event_organizer" ? (
            <AddEditEvent viewType="add" addFunc={addNewData} />
          ) : (
            <AddEditVenue
              pageType="manager"
              viewType="add"
              addFunc={addNewData}
            />
          )}
        </DialogContent>
      </Dialog>
      <div className="mx-auto xl:max-w-screen-xl md:max-w-screen-sm">
        <div className="flex justify-between items-center mb-5">
          <h5 className="font-bold text-3xl">
            {accountData.business_type === "event_organizer"
              ? "Events"
              : "Venues"}{" "}
            <Badge>{pageData.total}</Badge>
          </h5>
          <div className="flex justify-end mt-4 gap-3">
            <SearchInput searchFunc={setSearchTerm} />
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">
                  <ListFilter /> Filter
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent className="w-56">
                {EVENT_FILTER.map((accFilter) => (
                  <DropdownMenuItem
                    onClick={() => changeFilter(accFilter.slug)}
                  >
                    {accFilter.name}
                  </DropdownMenuItem>
                ))}
              </DropdownMenuContent>
            </DropdownMenu>
            <Button className="bg-black" onClick={() => setOpenModal(true)}>
              Add{" "}
              {accountData.business_type === "event_organizer"
                ? "Event"
                : "Venue"}
            </Button>
          </div>
        </div>
        <div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-5">
          {allPages !== undefined && allPages.length !== 0 ? (
            <>
              {uniqBy(allPages, "_id").map((page) => (
                <>
                  {accountData.business_type === "event_organizer" ? (
                    <DEventCard
                      data={page}
                      key={page._id}
                      viewFunc={openEvent}
                    />
                  ) : accountData.business_type === "venue_manager" ? (
                    <DVenueCard
                      data={page}
                      key={page._id}
                      viewFunc={openVenue}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </>
          ) : allPages.length === 0 && !emptyState ? (
            <>
              {Array.from({ length: 4 }).map((card) => (
                <SkeletonCard />
              ))}
            </>
          ) : (
            <div className="col-span-3">
              <EmptyContainer
                message={
                  accountData.business_type === "event_organizer"
                    ? "No events yet!"
                    : accountData.business_type === "venue_manager"
                    ? "No venues yet!"
                    : ""
                }
                instructions={`Click the "Add" button to create one`}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageManager;
