import { Button } from "@/components/ui/button";
import {
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
  Card,
} from "@/components/ui/card";
import React, { useState } from "react";
import TeamCard from "./TeamCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Clock, Ticket, Trash2 } from "lucide-react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { TEAM_ROLES } from "constants/data";
import useUIMisc from "hooks/useUIMisc";

type VTProps = {
  data: any;
  delFunc: (data: any) => void;
  updateFunc: (data: any, updateData: any) => void;
};

const TicketCount = () => {
  return (
    <>
      <Accordion type="single" collapsible className="w-full border-0">
        <AccordionItem value="item-1" className="border-0">
          <AccordionTrigger className="w-full border p-0 rounded mb-2 hover:decoration-transparent shadow-none">
            <div className=" w-full text-left p-2 px-4">
              <h6 className="font-medium text-md">
                Ticket Order ID: <span className="uppercase">#B84630d</span>
              </h6>
              <div className="flex justify-start items-center divide-x gap-3">
                <div className="flex items-center gap-1">
                  <Ticket size={20} className="text-gray-500" />
                  <p className="text-md font-normal text-gray-500">
                    No. of Tickets: 4
                  </p>
                </div>
                {""}
                <div className="flex items-center gap-1">
                  <Clock size={20} className="text-gray-500 ms-2" />
                  <p className="text-md font-normal text-gray-500">
                    {" "}
                    3rd Jun. 2024 - 4pm
                  </p>
                </div>
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent className="bg-gray-50 mb-3 p-1 rounded-lg px-4">
            <ol className="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
              <li>
                <span>VVIP - 70</span>
              </li>
            </ol>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </>
  );
};

const ViewTeam = ({ data, delFunc, updateFunc }: VTProps) => {
  const [teamData, setTeamData] = useState(data);
  const { showToast } = useUIMisc();
  const updateTeamData = (type: any, value: any) => {
    setTeamData({ ...teamData, [type]: value });
  };
  const updateTeam = () => {
    if (teamData.allocatedTickets > 0 && teamData.desc.length > 9) {
      delete teamData.userData;
      updateFunc(data, teamData);
    } else {
      showToast(
        "Allocated Tickets must not 0 or Description must at least 10 characters!",
        "warning"
      );
    }
  };
  // const getScannedTickets = () => {};
  // const getSoldTickets = () => {};

  return (
    <Card className="w-full p-0 border-0 shadow-none">
      <CardHeader>
        <CardTitle>{data.username}'s Stats</CardTitle>
        <CardDescription>Shows detailed stats of team member</CardDescription>
      </CardHeader>
      <CardContent>
        <TeamCard viewBool={true} data={data} />
        <div className="mt-3">
          <Tabs defaultValue="actions">
            <TabsList className="grid w-full grid-cols-3">
              {/* <TabsTrigger value="scanned">Scanned</TabsTrigger>
              <TabsTrigger value="sold">Sold</TabsTrigger> */}
              <TabsTrigger value="actions">Actions</TabsTrigger>
            </TabsList>
            {/* <TabsContent
              className="max-h-[50vh] overflow-y-scroll"
              value="scanned"
            >
              <div className="grid grid-col-3">
                {Array.from({ length: 50 }).map((ticket) => (
                  <TicketCount />
                ))}
              </div>
            </TabsContent>
            <TabsContent
              className="max-h-[50vh] overflow-y-scroll"
              value="sold"
            >
              Sold
            </TabsContent> */}
            <TabsContent className="max-h-[50vh]" value="actions">
              <div>
                <form>
                  <div className="grid w-full items-center gap-4">
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="framework">Roles</Label>
                      <Select
                        onValueChange={(e) => updateTeamData("roles", e)}
                        value={teamData.roles}
                      >
                        <SelectTrigger id="framework">
                          <SelectValue placeholder="Select" />
                        </SelectTrigger>
                        <SelectContent position="popper">
                          {TEAM_ROLES.map((role) => (
                            <SelectItem value={role.value}>
                              {role.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="tickets">Allocated Tickets</Label>
                      <Input
                        id="tickets"
                        type="number"
                        placeholder="10"
                        value={teamData.allocatedTickets}
                        onChange={(e) =>
                          updateTeamData("allocatedTickets", e.target.value)
                        }
                      />
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="info">Info</Label>
                      <Textarea
                        value={teamData.desc}
                        placeholder="Type your message here."
                        className="resize-none"
                        onChange={(e) => updateTeamData("desc", e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-4">
                    <Button
                      className="bg-red-600"
                      onClick={(e) => {
                        e.preventDefault();
                        delFunc(data);
                      }}
                    >
                      <Trash2 color="#fff" /> Delete Team
                    </Button>
                    <Button
                      className="bg-black"
                      onClick={(e) => {
                        e.preventDefault();
                        updateTeam();
                      }}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </CardContent>
      {/* <CardFooter className="flex justify-end"> 
            <Button className="bg-black">Submit</Button>
          </CardFooter> */}
    </Card>
  );
};

export default ViewTeam;
