import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { EmptyState } from "components/custom/EmptyState";
import { ppholder } from "constants/assets";
import { TEAM_ROLES } from "constants/data";
import { COLORS } from "constants/ui";
import { Formik } from "formik";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { uniqBy } from "lodash";
import { Trash2 } from "lucide-react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { formValidations } from "utils/validations/formValidations";
import * as yup from "yup";

type AddTeamProps = {
  addFunc: (data: any) => void;
  updateFunc: (data: any, type: string) => void;
};

const AddTeam = ({ addFunc, updateFunc }: AddTeamProps) => {
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );

  const [searchTerm, setSearchTerm] = useState("");
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const [selectedTeam, setSelectedTeam]: any = useState(null);
  const [emptyState, setEmptyState] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const initPageData = {
    searchPage: 1,
    searchTotal: 0,
  };
  const [pageData, setPageData] = useState(initPageData);

  const initTeamValues = {
    roles: "",
    allocatedTickets: 0,
    desc: "",
  };

  const teamSchema = yup.object().shape({
    roles: formValidations.default,
    desc: formValidations.desc,
    allocatedTickets: formValidations.defaultNum,
  });

  const handleSearchChange = (e: any) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9._]*$/;
    if (regex.test(value)) {
      searchUsers(value, "new");
      setSearchTerm(value);
    }
  };

  const handleSubmit = async (values: any) => {
    showLoading();
    const teamData = {
      ...values,
      eventId: dashData._id,
      userId: selectedTeam._id,
      username: selectedTeam.username,
    };
    try {
      const teamRes = await axiosPrivate.post(
        `/teams/manager/${accountData._id}`,
        teamData
      );
      const team = teamRes.data.data;
      closeLoading();
      showToast(`Team member added successful`, "success");
      addFunc(team);
      updateFunc(team, "add");
    } catch (error: any) {
      closeLoading();
      showToast(`${error.response.data.message}\n Try again!`, "error");
    }
  };

  const searchUsers = async (value: any, type: string, pageNum?: number) => {
    setSearchTerm(value);
    if (value === "") {
      setAllUsers([]);
      setSearchTerm("");
      setPageData(initPageData);
    } else {
      try {
        const searchResults = await axiosPrivate.get("/teams/users/search", {
          params: {
            q: value,
            page: type === "new" ? 1 : pageNum,
          },
        });
        const responseData = searchResults.data.data;
        if (type === "more") {
          setAllUsers([...allUsers, ...responseData]);
        } else {
          setAllUsers(responseData);
          if (responseData.length === 0 && allUsers.length === 0) {
            setEmptyState(true);
          }
          setPageData({ ...pageData, searchTotal: searchResults.data.total });
        }
      } catch (error) {}
    }
  };

  const teamFunc = (type: string, data?: any) => {
    type === "add" ? setSelectedTeam(data) : setSelectedTeam(null);
  };

  const TeamInnerCard = ({ data }: any) => {
    return (
      <div
        className="flex justify-start item-center p-2 rounded-md gap-3 border-b pb-1 hover:bg-black hover:text-white cursor-pointer"
        onClick={() => {
          if (selectedTeam === null) {
            teamFunc("add", data);
          }
        }}
      >
        <img
          src={data.profile_img !== null ? data.profile_img.url : ppholder}
          alt=""
          className="w-10 h-10 rounded-xl object-cover"
        />
        <div>
          <h4 className="font-medium text-sm mb-0">
            @{data !== null && data.username}
          </h4>
          <span className="text-gray-500 text-sm">
            {data !== null && data.email}
          </span>
        </div>
      </div>
    );
  };

  return (
    <Card className="w-full p-0 border-0 shadow-none">
      <CardHeader>
        <CardTitle>Add Team</CardTitle>
        <CardDescription>
          Build your event team with suitable roles.
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Formik
          validationSchema={teamSchema}
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          initialValues={initTeamValues}
        >
          {({
            values,
            errors,
            handleChange,
            touched,
            setFieldValue,
            resetForm,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="grid w-full items-center gap-4">
                {selectedTeam !== null ? (
                  <div className="flex justify-between items-center">
                    <TeamInnerCard data={selectedTeam} />
                    <Button
                      className="bg-red-600"
                      onClick={(e) => {
                        e.preventDefault();
                        teamFunc("delete");
                        setAllUsers([]);
                      }}
                    >
                      <Trash2 color={COLORS.white} size={14} />
                    </Button>
                  </div>
                ) : (
                  <div className="p-2 border rounded-md">
                    <Input
                      type="search"
                      // value={searchTerm}
                      name="search"
                      placeholder="Search by username..."
                      onChange={(e) => {
                        if (e.target.value.length > 3) {
                          handleSearchChange(e);
                        }
                      }}
                    />

                    <div className="w-full h-52 p-3 overflow-y-scroll">
                      {allUsers !== undefined && allUsers.length !== 0 ? (
                        <>
                          {uniqBy(allUsers, "_id").map((user) => (
                            <TeamInnerCard data={user} />
                          ))}
                        </>
                      ) : allUsers.length === 0 && !emptyState ? (
                        <></>
                      ) : (
                        <>
                          <EmptyState
                            message="No search results"
                            instructions=""
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="roles">Roles</Label>
                  <Select
                    name="roles"
                    onValueChange={(e) => {
                      setFieldValue("roles", e);
                    }}
                    value={values.roles}
                  >
                    <SelectTrigger id="roles">
                      <SelectValue placeholder="Select a role" />
                    </SelectTrigger>
                    <SelectContent position="popper">
                      {TEAM_ROLES.map((role) => (
                        <SelectItem value={role.value}>{role.label}</SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  {errors.roles && touched.roles && (
                    <span className="text-sm font-light text-red-500">
                      {errors.roles}
                    </span>
                  )}
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="allocatedTickets">Allocated Tickets</Label>
                  <Input
                    id="allocatedTickets"
                    type="text"
                    placeholder="10"
                    onChange={handleChange}
                    name="allocatedTickets"
                    value={values.allocatedTickets}
                  />
                  {errors.allocatedTickets && touched.allocatedTickets && (
                    <span className="text-sm font-light text-red-500">
                      {errors.allocatedTickets}
                    </span>
                  )}
                </div>
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="desc">Info</Label>
                  <Textarea
                    placeholder="Describe role or add place of stand or task..."
                    className="resize-none"
                    onChange={handleChange}
                    name="desc"
                    value={values.desc}
                  />
                  {errors.desc && touched.desc && (
                    <span className="text-sm font-light text-red-500">
                      {errors.desc}
                    </span>
                  )}
                </div>
              </div>
              <div className="flex justify-end mt-4">
                <Button className="bg-black">Submit</Button>
              </div>
            </form>
          )}
        </Formik>
      </CardContent>
    </Card>
  );
};

export default AddTeam;
