import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Banknote, ListFilter, Tag, Ticket, TicketCheck } from "lucide-react";
import { StatsCard } from "pages/components";
import { useEffect, useState } from "react";
import TicketCard from "./components/TicketCard";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import AddTicket from "./components/AddTicket";
import ViewTicket from "./components/ViewTicket";
import useUIMisc from "hooks/useUIMisc";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import { uniqBy } from "lodash";
import { EmptyContainer } from "components/custom/EmptyState";
import { SkeletonCard } from "components/custom/SkeletonCards";
import useScrollToBottom from "hooks/useScrollToBottom";
import { getCurrencyByCountryName } from "services/misc";

const TicketsPage = () => {
  const initFilter = {
    status: "all",
    sort: "desc",
  };
  const [openModal, setOpenModal] = useState(false);
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const [filter, setFilter] = useState(initFilter);
  const [allTickets, setAllTickets] = useState<any[]>([]);
  const [emptyState, setEmptyState] = useState(false);
  const initPageData = {
    searchPage: 1,
    searchTotal: 0,
    page: 1,
    total: 0,
  };
  const initStats = {
    totalRevenue: 0,
    totalSoldTickets: 0,
    totalTickets: 0,
    ticketTypes: 0,
  };
  const [statsData, setStatsData]: any = useState(initStats);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTicket, setSelectedTicket] = useState<any>({});
  const [pageData, setPageData] = useState(initPageData);
  const axiosPrivate = useAxiosPrivate();
  const [activeModal, setActiveModal] = useState("");
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );

  const getTickets = async () => {
    try {
      const tickets = await axiosPrivate.get(
        `/tickets/manager/${accountData._id}/${dashData._id}`,
        {
          params: {
            page: pageData.page,
            status: filter.status,
          },
        }
      );
      const responseData = tickets.data.data;
      setPageData({ ...pageData, total: tickets.data.total });
      setAllTickets([...allTickets, ...responseData]);
      if (responseData.length === 0 && allTickets.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  const addTicket = async (data: any) => {
    const allData = [data, ...allTickets];
    setAllTickets(allData);
    setPageData({ ...pageData, total: pageData.total + 1 });
    setOpenModal(false);
  };

  const searchTicket = async (type: string, pageNum?: number) => {
    // setSearchTerm(value);
    if (searchTerm === "") {
      setAllTickets([]);
      setSearchTerm("");
      setPageData(initPageData);
    } else {
      try {
        const searchResults = await axiosPrivate.get(
          `/tickets/manager/${accountData._id}/${dashData._id}/search/`,
          {
            params: {
              q: searchTerm,
              page: type === "new" ? 1 : pageNum,
            },
          }
        );

        const responseData = searchResults.data.data;
        if (type === "more") {
          setAllTickets([...allTickets, ...responseData]);
        } else {
          setAllTickets(responseData);
          setPageData({ ...pageData, searchTotal: searchResults.data.total });
          if (responseData.length === 0) {
            setEmptyState(true);
          }
        }
      } catch (error) {}
    }
  };

  const viewTicket = (ticket: any) => {
    setActiveModal("view");
    setOpenModal(true);
    setSelectedTicket(ticket);
  };

  useEffect(() => {
    if (searchTerm !== "" && searchTerm.length > 3) {
      searchTicket("new", 1);
    }
  }, [searchTerm]);

  const searchLoadMore = () => {
    if (pageData.searchTotal !== allTickets.length) {
      setPageData({ ...pageData, searchPage: pageData.searchPage + 1 });
      searchTicket("more", pageData.searchPage + 1);
    }
  };

  const changeFilter = (filterType: string) => {
    setSearchTerm("");
    setAllTickets([]);
    setPageData(initPageData);
    setFilter({ ...filter, status: filterType });
  };

  const getStats = async () => {
    try {
      const statsRes = await axiosPrivate.get(
        `/tickets/stats/${accountData._id}/${dashData._id}`
      );

      setStatsData(statsRes.data.data);
    } catch (error) {}
  };

  const updateStats = (type: string, data: any) => {
    setStatsData({
      ...statsData,
      totalTickets:
        type === "add"
          ? statsData.totalTickets + data.qty
          : type === "del"
          ? statsData.totalTickets - data.qty
          : statsData.totalTickets,
      ticketTypes:
        type === "add"
          ? statsData.ticketTypes + 1
          : type === "del"
          ? statsData.ticketTypes - 1
          : statsData.ticketTypes,
    });
  };

  const removeTicket = (ticketId: any) => {
    const mainTickets = [...allTickets];
    const filteredTickets = mainTickets.filter((t) => t._id !== ticketId);
    setAllTickets(filteredTickets);
  };

  const updateAllTickets = (data: any, status: string) => {
    const mainTickets = [...allTickets];

    const findIndex = mainTickets.findIndex((a: any) => a._id === data._id);
    mainTickets[findIndex] = { ...data, status };
    setAllTickets([...mainTickets]);
  };

  const deleteTicket = async (data: any) => {
    try {
      await axiosPrivate.delete(
        `/tickets/manager/${accountData._id}/${dashData._id}/${data._id}`
      );
      updateStats("del", data);
      removeTicket(data._id);
      setOpenModal(false);
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };
  const updateStatus = async (data: any, status: any) => {
    try {
      await axiosPrivate.put(
        `/tickets/manager/${accountData._id}/${dashData._id}/${data._id}`,
        {
          status,
        }
      );
      setOpenModal(false);
      updateAllTickets(data, status);
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      getTickets();
    }
  }, [searchTerm, pageData.page, filter.status]);

  const loadMore = () => {
    if (pageData.total !== allTickets.length) {
      setPageData({ ...pageData, page: pageData.page + 1 });
    }
  };

  const loadMoreData = () => {
    setTimeout(() => {
      if (searchTerm !== "" && searchTerm.length > 3) {
        searchLoadMore();
      } else {
        loadMore();
      }
      resetFetching();
    }, 1000);
  };

  const { isFetching, resetFetching } = useScrollToBottom(loadMoreData, 100);

  return (
    <div>
      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent className="max-w-3xl max-h-screen">
          {activeModal === "add" ? (
            <AddTicket addFunc={addTicket} updateFunc={updateStats} />
          ) : activeModal === "view" ? (
            <ViewTicket
              data={selectedTicket}
              event={dashData}
              delFunc={deleteTicket}
              updateFunc={updateStatus}
              currency={getCurrencyByCountryName(
                accountData.business_type === "event_organizer"
                  ? dashData.venue.country
                  : dashData.country
              )}
            />
          ) : null}
        </DialogContent>
      </Dialog>
      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
        <StatsCard
          name="Total tickets"
          figure={statsData.totalTickets || 0}
          iconData={{ icon: Ticket }}
          info="Number of tickets"
        />
        <StatsCard
          name="Total Revenue"
          figure={statsData.totalRevenue || 0}
          iconData={{ icon: Banknote }}
          info="Generated from sales"
          currency={getCurrencyByCountryName(
            accountData.business_type === "event_organizer"
              ? dashData.venue.country
              : dashData.country
          )}
        />
        <StatsCard
          name="Total Sold Tickets"
          figure={statsData.totalSoldTickets || 0}
          iconData={{ icon: TicketCheck }}
          info="Number of sold"
        />
        <StatsCard
          name="Total Ticket Types "
          figure={statsData.ticketTypes || 0}
          iconData={{ icon: Tag }}
          info="Number of ticket types"
        />
      </div>

      <div className="flex justify-between items-center mb-5">
        <h5 className="font-bold text-3xl">Tickets</h5>
        <div className="flex justify-end mt-4 gap-3">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <ListFilter /> Filter
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              {["all", "live", "on hold", "ended"].map((status) => (
                <DropdownMenuItem
                  className="capitalize"
                  onClick={() => changeFilter(status)}
                >
                  {status}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <Button
            className="bg-black"
            onClick={() => {
              setActiveModal("add");
              setOpenModal(true);
            }}
          >
            Add Tickets
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-5">
        {allTickets !== undefined && allTickets.length !== 0 ? (
          <>
            {uniqBy(allTickets, "_id").map((ticket) => (
              <>
                <TicketCard
                  viewBool={false}
                  key={ticket._id}
                  data={ticket}
                  viewFunc={viewTicket}
                  currency={getCurrencyByCountryName(
                    accountData.business_type === "event_organizer"
                      ? dashData.venue.country
                      : dashData.country
                  )}
                />
              </>
            ))}
          </>
        ) : allTickets.length === 0 && !emptyState ? (
          <>
            {Array.from({ length: 4 }).map((card) => (
              <SkeletonCard />
            ))}
          </>
        ) : (
          <div className="col-span-4">
            <EmptyContainer
              message={"No tickets yet!"}
              instructions={`Click the "Add" button to create one`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketsPage;
