import { useState } from "react";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Card, Label } from "flowbite-react";
import { Switch } from "@/components/ui/switch";
import AddEditEvent from "pages/components/AddEditEvent";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { useDispatch, useSelector } from "react-redux";
import { setDashboardData } from "redux/features/dashboardSlice";
import {
  SelectTrigger,
  SelectValue,
  Select as MainSelect,
  SelectContent,
  SelectItem,
  SelectGroup,
} from "@/components/ui/select";

type Props = {};

const EventSettings = (props: Props) => {
  const { showToast, closeLoading, showLoading } = useUIMisc();
  const axiosPrivate = useAxiosPrivate();
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );
  const dispatch = useDispatch();
  const [toogleBool, setToogleBool] = useState(dashData.event_published);
  const [status, setStatus] = useState(dashData.status);
  const toggleSwitch = (e: any) => {
    updateStatus({ event_published: !toogleBool });
    setToogleBool(!toogleBool);
  };

  const updateStatus = async (dataBody: any) => {
    showLoading();
    try {
      const eventUpdate = await axiosPrivate.put(
        `/events/manager/${accountData._id}/${dashData._id}`,
        dataBody
      );
      closeLoading();
      dispatch(setDashboardData(eventUpdate.data.data));
      showToast("Event published updated successfully!", "success");
    } catch (error: any) {
      closeLoading();
      showToast(`${error.response.data.message}`, "error");
    }
  };

  return (
    <div className="grid flex-1 items-start gap-4 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
      <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
        <AddEditEvent viewType="settings" />
      </div>
      <div>
        <Card x-chunk="dashboard-05-chunk-3" className="mb-3">
          <CardHeader className="p-0">
            <CardTitle>Additional Settings</CardTitle>
            <CardDescription>Extra actions on your event.</CardDescription>
          </CardHeader>
          <CardContent className="p-0">
            <div className="flex justify-between items-center">
              <h6 className="text-md font-normal capitalize">Published</h6>
              <div>
                <div className="flex items-center space-x-2">
                  <Switch
                    id="status_toggle"
                    checked={toogleBool}
                    onCheckedChange={toggleSwitch}
                  />
                  <Label htmlFor="status_toggle">Live</Label>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card x-chunk="dashboard-05-chunk-3">
          <CardHeader className="p-0">
            <CardTitle>Event Status</CardTitle>
            <CardDescription>
              Change the main status of your event.
            </CardDescription>
          </CardHeader>
          <CardContent className="p-0">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="status">Status</Label>
              <MainSelect
                name="status"
                onValueChange={(e) => {
                  updateStatus({ status: e });
                  setStatus(e);
                }}
                value={status}
              >
                <SelectTrigger className="w-full focus:ring-0 mt-1" id="status">
                  <SelectValue
                    className="focus:ring-0 font-normal"
                    placeholder="Select"
                  />
                </SelectTrigger>
                <SelectContent className="h-36">
                  <SelectGroup>
                    <SelectItem value="active">Live</SelectItem>
                    <SelectItem value="ended">Ended</SelectItem>
                    <SelectItem value="postponed">Postponed</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </MainSelect>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default EventSettings;
