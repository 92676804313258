import {
  CardHeader,
  CardTitle,
  CardContent,
  Card,
} from "../../@/components/ui/card";

type StatsProps = {
  name: string;
  figure: string | number;
  iconData: any;
  info?: string;
  currency?: any;
};

// interface StatsInterface {
//   statData: [StatsProps];
// }

const StatsCard = ({ name, figure, iconData, info, currency }: StatsProps) => {
  return (
    <Card x-chunk="dashboard-01-chunk-0">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{name}</CardTitle>
        <iconData.icon className="h-6 w-6 text-muted-foreground" />
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">
          {currency || ""}
          {figure}
        </div>
        <p className="text-xs text-muted-foreground">{info}</p>
      </CardContent>
    </Card>
  );
};

export default StatsCard;
