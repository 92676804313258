import { LandingHeader, LandingFooter } from "pages/components";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
type Props = {};

const Privacy = (props: Props) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <title>OutFun Privacy Policy - Your Data and Security</title>
        <meta
          name="description"
          content="Learn how OutFun collects, uses, and protects your personal information."
        />
        <meta
          name="keywords"
          content="privacy policy, data protection, user privacy, OutFun"
        />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "OutFun Privacy Policy",
        "url": "https://outfun.app/privacy-policy",
        "description": "Learn how OutFun collects, uses, and protects your personal information when using our event booking platform.",
        "inLanguage": "en",
        "publisher": {
          "@type": "Organization",
          "name": "OutFun",
          "url": "https://outfun.app"
        }
      }
    `}
        </script>
      </Helmet>

      <div>
        <LandingHeader />
        <div className="mx-auto max-w-screen-xl">
          <section className="bg-white dark:bg-gray-900">
            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                Privacy Policy
              </h2>
              <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                How OutFun handles your data
              </p>
            </div>
          </section>
          <div className="max-w-3xl mx-auto p-6 bg-white mb-8 pt-0">
            <p className="text-gray-600 mb-4">
              Last Updated: 21st September 2024
            </p>
            <p className="mb-4">
              At OutFun, we are committed to protecting your privacy. This
              Privacy Policy outlines how we collect, use, disclose, and
              safeguard your information when you use our application and
              services.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              1. Information We Collect
            </h2>
            <ul className="list-disc list-inside mb-4 text-gray-600">
              <li>
                <strong>Personal Information</strong>: This includes your name,
                email address, phone number, and payment information when you
                create an account, book tickets, or use our services.
              </li>
              <li>
                <strong>Usage Data</strong>: We collect information about how
                you use our app, including your IP address, browser type, device
                information, and pages visited.
              </li>
            </ul>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              2. How We Use Your Information
            </h2>
            <ul className="list-disc list-inside mb-4 text-gray-600">
              <li>To provide and maintain our services</li>
              <li>To process transactions and manage bookings</li>
              <li>
                To communicate with you, including sending updates and
                promotional materials
              </li>
              <li>
                To improve our app and services based on user feedback and usage
                patterns
              </li>
              <li>
                To monitor usage and analyze trends to enhance user experience
              </li>
            </ul>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              3. Disclosure of Your Information
            </h2>
            <ul className="list-disc list-inside mb-4 text-gray-600">
              <li>
                <strong>With Service Providers</strong>: We may share your
                information with third-party service providers who assist us in
                operating our app, processing payments, or delivering services.
              </li>
              <li>
                <strong>For Legal Reasons</strong>: We may disclose your
                information to comply with applicable laws, regulations, or
                legal requests, or to protect our rights or the rights of
                others.
              </li>
              <li>
                <strong>Business Transfers</strong>: If we are involved in a
                merger, acquisition, or asset sale, your information may be
                transferred as part of that transaction.
              </li>
            </ul>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              4. Data Security
            </h2>
            <p className="mb-4 text-gray-600">
              We take the security of your information seriously and implement
              appropriate measures to protect it. However, no method of
              transmission over the internet or electronic storage is 100%
              secure, so we cannot guarantee its absolute security.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">5. Your Rights</h2>
            <ul className="list-disc list-inside mb-4 text-gray-600">
              <li>
                <strong>Access</strong>: You can request access to the personal
                information we hold about you.
              </li>
              <li>
                <strong>Correction</strong>: You can request that we correct any
                inaccuracies in your personal information.
              </li>
              <li>
                <strong>Deletion</strong>: You can request that we delete your
                personal information, subject to certain legal obligations.
              </li>
              <li>
                <strong>Opt-out</strong>: You can opt out of receiving marketing
                communications from us at any time.
              </li>
            </ul>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              6. Children's Privacy
            </h2>
            <p className="mb-4 text-gray-600">
              Our app is not intended for children under the age of 13. We do
              not knowingly collect personal information from children. If we
              become aware that we have collected such information, we will take
              steps to delete it.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              7. Changes to This Privacy Policy
            </h2>
            <p className="mb-4 text-gray-600">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new policy on this page and
              updating the "Last Updated" date.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">8. Contact Us</h2>
            <p className="mb-4 text-gray-600">
              If you have any questions or concerns about this Privacy Policy,
              please contact us at:
            </p>
            <p className="mb-4 text-gray-600">
              <strong>OutFun Support</strong>
            </p>
            <p className="mb-4 text-gray-600">
              Email:{" "}
              <a href="mailto:hello@outfun.app" className="text-[#d60c0c]">
                hello@outfun.app
              </a>
            </p>
            <p className="mb-4 text-gray-600">Phone: +233546522014</p>
          </div>
        </div>
        <LandingFooter />
      </div>
    </>
  );
};

export default Privacy;
