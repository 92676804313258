import { Button } from "../../@/components/ui/button";
import { ChevronRight } from "lucide-react";
import PlayStore from "../../assets/img/main/playstore.svg";
import AppStore from "../../assets/img/main/apple.svg";
import { logo } from "../../constants/assets";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { getStartedBtn } from "services/misc";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FiMenu, FiX } from "react-icons/fi";

const LandingHeader = ({ toRefFunc, pricingRef }: any) => {
  const { accountData } = useSelector((state: any) => state.dashboard);
  const location = useLocation();
  const navigate = useNavigate();
  const links = [
    { name: "appstore", img: AppStore },
    { name: "playstore", img: PlayStore },
  ];

  const [activeTab, setActiveTab] = useState("/home");
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const mainScroll = () => {
    if (location.pathname === "/" || location.pathname === "/home") {
      toRefFunc(pricingRef);
    } else {
      navigate("/", { state: { scrollToSection: "pricing" } });
      setTimeout(() => {
        const pricingSection = document.getElementById("pricing");
        pricingSection?.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };

  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location.pathname]);

  return (
    <div className="w-full px-4 h-auto py-5 border-b-[1px] border-b-gray-100">
      <div className="mx-auto xl:max-w-screen-xl md:max-w-screen-sm">
        <div className="flex justify-between items-center gap-4">
          <div className="flex justify-start items-center gap-4">
            <div>
              <Link to="/">
                <img src={logo} alt="homelogo" className="w-auto h-8 lg:h-8" />
              </Link>
            </div>
          </div>

          <div className="flex justify-end items-center gap-3 md:hidden">
            <button onClick={toggleMenu} className="text-gray-700">
              {isMenuOpen ? <FiX size={28} /> : <FiMenu size={28} />}
            </button>
          </div>

          <div className="hidden md:flex justify-between items-center w-full">
            <div className="flex justify-start items-center gap-4">
              <ul className="font-regular flex p-4 md:p-0 mt-4 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                <li>
                  <Link
                    to="/home"
                    className={`block py-2 px-3 font-medium text-gray-400 rounded md:bg-transparent md:hover:text-gray-700 md:p-0 ${
                      activeTab === "/" || activeTab === "/home"
                        ? "!text-black"
                        : ""
                    }`}
                    aria-current="page"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    onClick={mainScroll}
                    className="block py-2 px-3 text-gray-400 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-gray-700 md:p-0"
                  >
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact"
                    className={`block py-2 px-3 font-medium text-gray-400 rounded md:bg-transparent md:hover:text-gray-700 md:p-0 ${
                      activeTab === "/contact" ? "!text-black" : ""
                    }`}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div className="flex justify-end items-center gap-3">
              {links.map((link) => (
                <div
                  className="p-1 rounded-full border border-gray-300 cursor-pointer"
                  key={link.name}
                >
                  <img src={link.img} alt="" className="w-7 h-7" />
                </div>
              ))}
              <Link to={"/login"}>
                <Button className="bg-black">
                  {accountData !== null
                    ? "Go to Dashboard"
                    : "Login / Register"}
                  <ChevronRight className="ml-2" size={20} />
                </Button>
              </Link>
            </div>
          </div>
        </div>

        <div
          className={`${
            isMenuOpen ? "max-h-screen opacity-100" : "max-h-0 opacity-0"
          } overflow-hidden transition-all duration-300 ease-in-out transform ${
            isMenuOpen ? "translate-y-0" : "-translate-y-10"
          } flex flex-col md:hidden space-y-3`}
        >
          <Link
            to="/home"
            className={`block py-2 px-3 font-medium text-gray-400 rounded hover:bg-gray-100 ${
              activeTab === "/" || activeTab === "/home" ? "!text-black" : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/"
            onClick={mainScroll}
            className="block py-2 px-3 text-gray-400 rounded hover:bg-gray-100"
          >
            Pricing
          </Link>
          <Link
            to="/contact"
            className={`block py-2 px-3 font-medium text-gray-400 rounded hover:bg-gray-100 ${
              activeTab === "/contact" ? "!text-black" : ""
            }`}
          >
            Contact
          </Link>
          <div className="flex items-start gap-3">
            {links.map((link) => (
              <div
                className="p-1 rounded-full border border-gray-300 cursor-pointer"
                key={link.name}
              >
                <img src={link.img} alt="" className="w-7 h-7" />
              </div>
            ))}
            <Link to={"/login"}>
              <Button className="bg-black w-full">
                {accountData !== null ? "Go to Dashboard" : "Login / Register"}
                <ChevronRight className="ml-2" size={20} />
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHeader;
