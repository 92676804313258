import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

import { Ban, CheckCheck, Hourglass, ListFilter, Ticket } from "lucide-react";
import { StatsCard } from "pages/components";
import { useEffect, useState } from "react";
import CampaignCard from "./components/CampaignCard";
import AddCampaign from "./components/AddCampaign";
import ViewCampaign from "./components/ViewCampaign";
import useUIMisc from "hooks/useUIMisc";
import { useSelector } from "react-redux";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { uniqBy } from "lodash";
import { SkeletonCard } from "components/custom/SkeletonCards";
import { EmptyContainer } from "components/custom/EmptyState";
import SearchInput from "components/custom/SearchInput";
import { CAMPAIGN_FILTER } from "constants/data";
import { getCurrencyByCountryName, isObjEmpty } from "services/misc";
import PaginationComp from "components/custom/PaginationComp";
import useScrollToBottom from "hooks/useScrollToBottom";

type Props = {};

const CampaignsPage = (props: Props) => {
  const [activeModal, setActiveModal] = useState("add");
  const [searchTerm, setSearchTerm] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const [selectedCampaign, setSelectedCampaign] = useState<any>({});
  const initStats = {
    total: 0,
    pending: 0,
    active: 0,
    ended: 0,
  };
  const [statsData, setStatsData]: any = useState(initStats);

  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );
  const [openModal, setOpenModal] = useState(false);
  const initFilter = {
    status: "all",
    sort: "desc",
  };
  const initPageData = {
    searchPage: 1,
    searchTotal: 0,
    page: 1,
    total: 0,
  };
  const [filter, setFilter] = useState(initFilter);
  const [allCampaigns, setAllCampaigns] = useState<any[]>([]);
  const [emptyState, setEmptyState] = useState(false);
  const [pageData, setPageData] = useState(initPageData);
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const openModalFunc = (modalType: string) => {
    setActiveModal(modalType);
    setOpenModal(true);
  };

  const addCampaigns = async (data: any) => {
    const allData = [data, ...allCampaigns];
    setStatsData({
      ...statsData,
      total: statsData.total + 1,
      pending: statsData.pending + 1,
    });
    setAllCampaigns(allData);
    setPageData({ ...pageData, total: pageData.total + 1 });
    setOpenModal(false);
  };

  const getCampaigns = async () => {
    try {
      const campaigns = await axiosPrivate.get(
        `/campaigns/${dashData._id}/${
          accountData.business_type === "venue_manager"
            ? "venue_manager"
            : "event_organizer"
        }`,
        {
          params: {
            page: pageData.page,
            status: filter.status,
          },
        }
      );
      const responseData = campaigns.data.data;
      setPageData({ ...pageData, total: campaigns.data.total });
      setAllCampaigns([...allCampaigns, ...responseData]);
      if (responseData.length === 0 && allCampaigns.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {}
  };

  const viewCampaignModal = (campaignData: any) => {
    setActiveModal("view");
    setOpenModal(true);
    setSelectedCampaign(campaignData);
  };

  const searchTeams = async (type: string, pageNum?: number) => {
    // setSearchTerm(value);
    if (searchTerm === "") {
      setAllCampaigns([]);
      setSearchTerm("");
      setPageData(initPageData);
    } else {
      try {
        const searchResults = await axiosPrivate.get(
          `/campaigns/search/${dashData._id}/event_organizer`,
          {
            params: {
              q: searchTerm,
              page: type === "new" ? 1 : pageNum,
            },
          }
        );

        const responseData = searchResults.data.data;
        if (type === "more") {
          setAllCampaigns([...allCampaigns, ...responseData]);
        } else {
          setAllCampaigns(responseData);
          setPageData({ ...pageData, searchTotal: searchResults.data.total });
          if (responseData.length === 0) {
            setEmptyState(true);
          }
        }
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (searchTerm !== "" && searchTerm.length > 3) {
      searchTeams("new", 1);
    }
  }, [searchTerm]);

  const searchLoadMore = () => {
    if (pageData.searchTotal !== allCampaigns.length) {
      setPageData({ ...pageData, searchPage: pageData.searchPage + 1 });
      searchTeams("more", pageData.searchPage + 1);
    }
  };

  const changeFilter = (filterType: string) => {
    setSearchTerm("");
    setAllCampaigns([]);
    setPageData(initPageData);
    setFilter({ ...filter, status: filterType });
  };

  const getStats = async () => {
    try {
      const statsRes = await axiosPrivate.get(
        `/campaigns/stats/${dashData._id}/${
          accountData.business_type === "venue_manager"
            ? "venue_manager"
            : "event_organizer"
        }`
      );
      setStatsData(
        !isObjEmpty(statsRes.data.data) ? statsRes.data.data : initStats
      );
    } catch (error) {}
  };

  const loadMore = () => {
    if (pageData.total !== allCampaigns.length) {
      setPageData({ ...pageData, page: pageData.page + 1 });
    }
  };

  const loadMoreData = () => {
    setTimeout(() => {
      if (searchTerm !== "" && searchTerm.length > 3) {
        searchLoadMore();
      } else {
        loadMore();
      }
      resetFetching();
    }, 1000);
  };

  const { isFetching, resetFetching } = useScrollToBottom(loadMoreData, 100);

  useEffect(() => {
    getStats();
  }, []);

  useEffect(() => {
    if (searchTerm === "") {
      getCampaigns();
    }
  }, [searchTerm, pageData.page, filter.status]);

  return (
    <div>
      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent className="max-w-3xl max-h-screen p-0">
          {activeModal === "add" ? (
            <AddCampaign setOpenModal={setOpenModal} addFunc={addCampaigns} />
          ) : (
            <ViewCampaign
              data={selectedCampaign}
              currency={getCurrencyByCountryName(
                accountData.business_type === "event_organizer"
                  ? dashData.venue.country
                  : dashData.country
              )}
            />
          )}
        </DialogContent>
      </Dialog>
      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
        <StatsCard
          name="Total Campaigns"
          figure={statsData.total}
          iconData={{ icon: Ticket }}
          info="Number of campaigns"
        />
        <StatsCard
          name="Active Campaigns"
          figure={statsData.active}
          iconData={{ icon: CheckCheck }}
          info="Number of active"
        />
        <StatsCard
          name="Pending Campaigns"
          figure={statsData.pending}
          iconData={{ icon: Hourglass }}
          info="Number of pending"
        />
        <StatsCard
          name="Ended Campaigns"
          figure={statsData.ended}
          iconData={{ icon: Ban }}
          info="Number of ended or rejected"
        />
      </div>
      <div className="flex justify-between items-center mb-5">
        <h5 className="font-bold text-3xl">Campaigns</h5>
        <div className="flex justify-end mt-4 gap-3">
          <SearchInput searchFunc={setSearchTerm} />
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <ListFilter /> Filter
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              {CAMPAIGN_FILTER.map((campaignFilter) => (
                <DropdownMenuItem
                  onClick={() => changeFilter(campaignFilter.slug)}
                >
                  {campaignFilter.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
          <Button className="bg-black" onClick={() => openModalFunc("add")}>
            Add Campaign
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-5">
        {allCampaigns !== undefined && allCampaigns.length !== 0 ? (
          <>
            {uniqBy(allCampaigns, "_id").map((campaign) => (
              <>
                <CampaignCard
                  data={campaign}
                  viewBool={false}
                  func={openModalFunc}
                  viewFunc={viewCampaignModal}
                  currency={getCurrencyByCountryName(
                    accountData.business_type === "event_organizer"
                      ? dashData.venue.country
                      : dashData.country
                  )}
                />
              </>
            ))}
          </>
        ) : allCampaigns.length === 0 && !emptyState ? (
          <>
            {Array.from({ length: 4 }).map((card) => (
              <SkeletonCard />
            ))}
          </>
        ) : (
          <div className="col-span-5">
            <EmptyContainer
              message={"No campaigns yet!"}
              instructions={`Click the "Add" button to create one`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CampaignsPage;
