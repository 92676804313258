import { Facebook, Instagram, Linkedin, Twitter, Youtube } from "lucide-react";
import { logo } from "../../constants/assets";
import { Link, useLocation, useNavigate } from "react-router-dom";

const LandingFooter = ({ toRefFunc, pricingRef }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const mainScroll = () => {
    if (location.pathname === "/" || location.pathname === "/home") {
      toRefFunc(pricingRef);
    } else {
      navigate("/", { state: { scrollToSection: "pricing" } });
      setTimeout(() => {
        const pricingSection = document.getElementById("pricing");
        pricingSection?.scrollIntoView({ behavior: "smooth" });
      }, 1000);
    }
  };

  const socialLinks = [
    {
      name: "facebook",
      icon: Facebook,
      link: "https://web.facebook.com/outfunapp/",
    },
    {
      name: "instagram",
      icon: Instagram,
      link: "https://instagram.com/outfunapp",
    },
    {
      name: "linkedin",
      icon: Linkedin,
      link: "https://gh.linkedin.com/company/outfun",
    },
    { name: "twitter", icon: Twitter, link: "https://twitter.com/outfunapp" },
    { name: "youtube", icon: Youtube, link: "https://instagram.com/outfunapp" },
  ];
  return (
    <footer className="p-4 bg-white sm:p-6 dark:bg-gray-800">
      <div className="mx-auto max-w-screen-xl">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <Link to="/home" className="flex items-center">
              <img src={logo} className="mr-3 h-10" alt="OutFun Logo" />
            </Link>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Links
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-4">
                  <Link to="/" className="hover:underline">
                    Home
                  </Link>
                </li>
                <li className="mb-4">
                  <Link to="/" onClick={mainScroll} className="hover:underline">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className="hover:underline">
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                Legal
              </h2>
              <ul className="text-gray-600 dark:text-gray-400">
                <li className="mb-4">
                  <Link to="/privacy-policy" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/terms" className="hover:underline">
                    Terms &amp; Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between text-center">
          <span className="text-sm text-gray-500 sm:text-center text-center dark:text-gray-400">
            © {new Date().getFullYear()}{" "}
            <Link to="/home" className="hover:underline">
              OutFun
            </Link>
            . All Rights Reserved.
          </span>
          <div className="flex mt-4 space-x-6 justify-center sm:justify-center sm:mt-0">
            {socialLinks.map((link) => (
              <Link
                to={link.link}
                target="_blank"
                className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
                key={link.name}
              >
                <link.icon />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default LandingFooter;
