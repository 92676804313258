import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import OrganizerLayout from "pages/dashboard/layouts/OrganizerLayout";
// import DashboardLayout from "../pages/dashboard/layout";

const ProtectRoutes = ({ children }: any) => {
  const { loggedIn, oforg } = useSelector((state: any) => state.auth);

  if ((!loggedIn && !oforg) || (loggedIn && !oforg)) {
    return <Navigate to="/login" replace />;
  }
  return (
    <>
      {/* <DashboardLayout> */}
      <Outlet />
      {/* </DashboardLayout> */}
    </>
  );
};

const OrganizerRoutes = ({ children }: any) => {
  const { loggedIn, oforg } = useSelector((state: any) => state.auth);

  if ((!loggedIn && !oforg) || (loggedIn && !oforg)) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <OrganizerLayout>
        <Outlet />
      </OrganizerLayout>
    </>
  );
};

export { ProtectRoutes, OrganizerRoutes };
