import { Fragment } from "react";
import AllRoutes from "./routes";
import { ToastProvider } from "react-toast-notifications";
import { useSelector } from "react-redux";
import Loader from "./components/custom/Loader";

const App = () => {
  const { loading } = useSelector((store: any) => store.loader);

  return (
    <ToastProvider>
      <Fragment>
        <Loader loading={loading} />
        <AllRoutes />
      </Fragment>
    </ToastProvider>
  );
};

export default App;
