export const countries = [
    { name: "Afghanistan", dial_code: "+93", code: "AF", flag: "🇦🇫", currency: "AFN", symbol: "؋" },
    { name: "Åland Islands", dial_code: "+358", code: "AX", flag: "🇦🇽", currency: "EUR", symbol: "€" },
    { name: "Albania", dial_code: "+355", code: "AL", flag: "🇦🇱", currency: "ALL", symbol: "Lek" },
    { name: "Algeria", dial_code: "+213", code: "DZ", flag: "🇩🇿", currency: "DZD", symbol: "د.ج" },
    { name: "American Samoa", dial_code: "+1684", code: "AS", flag: "🇦🇸", currency: "USD", symbol: "$" },
    { name: "Andorra", dial_code: "+376", code: "AD", flag: "🇦🇩", currency: "EUR", symbol: "€" },
    { name: "Angola", dial_code: "+244", code: "AO", flag: "🇦🇴", currency: "AOA", symbol: "Kz" },
    { name: "Anguilla", dial_code: "+1264", code: "AI", flag: "🇦🇮", currency: "XCD", symbol: "$" },
    { name: "Antarctica", dial_code: "+672", code: "AQ", flag: "🇦🇶", currency: "USD", symbol: "$" },
    { name: "Antigua and Barbuda", dial_code: "+1268", code: "AG", flag: "🇦🇬", currency: "XCD", symbol: "$" },
    { name: "Argentina", dial_code: "+54", code: "AR", flag: "🇦🇷", currency: "ARS", symbol: "$" },
    { name: "Armenia", dial_code: "+374", code: "AM", flag: "🇦🇲", currency: "AMD", symbol: "֏" },
    { name: "Aruba", dial_code: "+297", code: "AW", flag: "🇦🇼", currency: "AWG", symbol: "ƒ" },
    { name: "Australia", dial_code: "+61", code: "AU", flag: "🇦🇺", currency: "AUD", symbol: "$" },
    { name: "Austria", dial_code: "+43", code: "AT", flag: "🇦🇹", currency: "EUR", symbol: "€" },
    { name: "Azerbaijan", dial_code: "+994", code: "AZ", flag: "🇦🇿", currency: "AZN", symbol: "₼" },
    { name: "Bahamas", dial_code: "+1242", code: "BS", flag: "🇧🇸", currency: "BSD", symbol: "$" },
    { name: "Bahrain", dial_code: "+973", code: "BH", flag: "🇧🇭", currency: "BHD", symbol: "ب.د" },
    { name: "Bangladesh", dial_code: "+880", code: "BD", flag: "🇧🇩", currency: "BDT", symbol: "৳" },
    { name: "Barbados", dial_code: "+1246", code: "BB", flag: "🇧🇧", currency: "BBD", symbol: "$" },
    { name: "Belarus", dial_code: "+375", code: "BY", flag: "🇧🇾", currency: "BYN", symbol: "Br" },
    { name: "Belgium", dial_code: "+32", code: "BE", flag: "🇧🇪", currency: "EUR", symbol: "€" },
    { name: "Belize", dial_code: "+501", code: "BZ", flag: "🇧🇿", currency: "BZD", symbol: "$" },
    { name: "Benin", dial_code: "+229", code: "BJ", flag: "🇧🇯", currency: "XOF", symbol: "₣" },
    { name: "Bermuda", dial_code: "+1441", code: "BM", flag: "🇧🇲", currency: "BMD", symbol: "$" },
    { name: "Bhutan", dial_code: "+975", code: "BT", flag: "🇧🇹", currency: "INR", symbol: "₹" },
    { name: "Bolivia", dial_code: "+591", code: "BO", flag: "🇧🇴", currency: "BOB", symbol: "Bs" },
    { name: "Bosnia and Herzegovina", dial_code: "+387", code: "BA", flag: "🇧🇦", currency: "BAM", symbol: "KM" },
    { name: "Botswana", dial_code: "+267", code: "BW", flag: "🇧🇼", currency: "BWP", symbol: "P" },
    { name: "Bouvet Island", dial_code: "+47", code: "BV", flag: "🏳", currency: "NOK", symbol: "kr" },
    { name: "Brazil", dial_code: "+55", code: "BR", flag: "🇧🇷", currency: "BRL", symbol: "R$" },
    { name: "British Indian Ocean Territory", dial_code: "+246", code: "IO", flag: "🇮🇴", currency: "USD", symbol: "$" },
    { name: "Brunei Darussalam", dial_code: "+673", code: "BN", flag: "🇧🇳", currency: "BND", symbol: "$" },
    { name: "Bulgaria", dial_code: "+359", code: "BG", flag: "🇧🇬", currency: "BGN", symbol: "лв" },
    { name: "Burkina Faso", dial_code: "+226", code: "BF", flag: "🇧🇫", currency: "XOF", symbol: "₣" },
    { name: "Burundi", dial_code: "+257", code: "BI", flag: "🇧🇮", currency: "BIF", symbol: "FBu" },
    { name: "Cambodia", dial_code: "+855", code: "KH", flag: "🇰🇭", currency: "KHR", symbol: "៛" },
    { name: "Cameroon", dial_code: "+237", code: "CM", flag: "🇨🇲", currency: "XAF", symbol: "₣" },
    { name: "Canada", dial_code: "+1", code: "CA", flag: "🇨🇦", currency: "CAD", symbol: "$" },
    { name: "Cape Verde", dial_code: "+238", code: "CV", flag: "🇨🇻", currency: "CVE", symbol: "$" },
    { name: "Cayman Islands", dial_code: "+345", code: "KY", flag: "🇰🇾", currency: "KYD", symbol: "$" },
    { name: "Central African Republic", dial_code: "+236", code: "CF", flag: "🇨🇫", currency: "XAF", symbol: "₣" },
    { name: "Chad", dial_code: "+235", code: "TD", flag: "🇹🇩", currency: "XAF", symbol: "₣" },
    { name: "Chile", dial_code: "+56", code: "CL", flag: "🇨🇱", currency: "CLP", symbol: "$" },
    { name: "China", dial_code: "+86", code: "CN", flag: "🇨🇳", currency: "CNY", symbol: "¥" },
    { name: "Christmas Island", dial_code: "+61", code: "CX", flag: "🇨🇽", currency: "AUD", symbol: "$" },
    { name: "Cocos (Keeling) Islands", dial_code: "+61", code: "CC", flag: "🇨🇨", currency: "AUD", symbol: "$" },
    { name: "Colombia", dial_code: "+57", code: "CO", flag: "🇨🇴", currency: "COP", symbol: "$" },
    { name: "Comoros", dial_code: "+269", code: "KM", flag: "🇰🇲", currency: "KMF", symbol: "CF" },
    { name: "Congo", dial_code: "+242", code: "CG", flag: "🇨🇬", currency: "XAF", symbol: "₣" },
    { name: "Congo, The Democratic Republic of the Congo", dial_code: "+243", code: "CD", flag: "🇨🇩", currency: "CDF", symbol: "FC" },
    { name: "Cook Islands", dial_code: "+682", code: "CK", flag: "🇨🇰", currency: "NZD", symbol: "$" },
    { name: "Costa Rica", dial_code: "+506", code: "CR", flag: "🇨🇷", currency: "CRC", symbol: "₡" },
    { name: "Croatia", dial_code: "+385", code: "HR", flag: "🇭🇷", currency: "HRK", symbol: "kn" },
    { name: "Cuba", dial_code: "+53", code: "CU", flag: "🇨🇺", currency: "CUP", symbol: "$" },
    { name: "Curaçao", dial_code: "+599", code: "CW", flag: "🇨🇼", currency: "ANG", symbol: "ƒ" },
    { name: "Cyprus", dial_code: "+357", code: "CY", flag: "🇨🇾", currency: "EUR", symbol: "€" },
    { name: "Czechia", dial_code: "+420", code: "CZ", flag: "🇨🇿", currency: "CZK", symbol: "Kč" },
    { name: "Denmark", dial_code: "+45", code: "DK", flag: "🇩🇰", currency: "DKK", symbol: "kr" },
    { name: "Djibouti", dial_code: "+253", code: "DJ", flag: "🇩🇯", currency: "DJF", symbol: "Fdj" },
    { name: "Dominica", dial_code: "+1767", code: "DM", flag: "🇩🇲", currency: "XCD", symbol: "$" },
    { name: "Dominican Republic", dial_code: "+1809", code: "DO", flag: "🇩🇴", currency: "DOP", symbol: "RD$" },
    { name: "Ecuador", dial_code: "+593", code: "EC", flag: "🇪🇨", currency: "USD", symbol: "$" },
    { name: "Egypt", dial_code: "+20", code: "EG", flag: "🇪🇬", currency: "EGP", symbol: "ج.م" },
    { name: "El Salvador", dial_code: "+503", code: "SV", flag: "🇸🇻", currency: "USD", symbol: "$" },
    { name: "Equatorial Guinea", dial_code: "+240", code: "GQ", flag: "🇬🇶", currency: "XAF", symbol: "₣" },
    { name: "Eritrea", dial_code: "+291", code: "ER", flag: "🇪🇷", currency: "ERN", symbol: "Nfa" },
    { name: "Estonia", dial_code: "+372", code: "EE", flag: "🇪🇪", currency: "EUR", symbol: "€" },
    { name: "Eswatini", dial_code: "+268", code: "SZ", flag: "🇸🇿", currency: "SZL", symbol: "L" },
    { name: "Ethiopia", dial_code: "+251", code: "ET", flag: "🇪🇹", currency: "ETB", symbol: "Br" },
    { name: "Falkland Islands (Malvinas)", dial_code: "+500", code: "FK", flag: "🇫🇰", currency: "FKP", symbol: "£" },
    { name: "Faroe Islands", dial_code: "+298", code: "FO", flag: "🇫🇴", currency: "DKK", symbol: "kr" },
    { name: "Fiji", dial_code: "+679", code: "FJ", flag: "🇫🇯", currency: "FJD", symbol: "$" },
    { name: "Finland", dial_code: "+358", code: "FI", flag: "🇫🇮", currency: "EUR", symbol: "€" },
    { name: "France", dial_code: "+33", code: "FR", flag: "🇫🇷", currency: "EUR", symbol: "€" },
    { name: "French Guiana", dial_code: "+594", code: "GF", flag: "🇬🇭", currency: "EUR", symbol: "€" },
    { name: "French Polynesia", dial_code: "+689", code: "PF", flag: "🇵🇫", currency: "XPF", symbol: "₣" },
    { name: "Gabon", dial_code: "+241", code: "GA", flag: "🇲🇬", currency: "XAF", symbol: "₣" },
    { name: "Gambia", dial_code: "+220", code: "GM", flag: "🇲🇬", currency: "GMD", symbol: "D" },
    { name: "Georgia", dial_code: "+995", code: "GE", flag: "🇬🇪", currency: "GEL", symbol: "₾" },
    { name: "Germany", dial_code: "+49", code: "DE", flag: "🇩🇪", currency: "EUR", symbol: "€" },
    { name: "Ghana", dial_code: "+233", code: "GH", flag: "🇬🇭", currency: "GHS", symbol: "¢" },
    { name: "Gibraltar", dial_code: "+350", code: "GI", flag: "🇬🇮", currency: "GIP", symbol: "£" },
    { name: "Greece", dial_code: "+30", code: "GR", flag: "🇬🇷", currency: "EUR", symbol: "€" },
    { name: "Greenland", dial_code: "+299", code: "GL", flag: "🇬🇱", currency: "DKK", symbol: "kr" },
    { name: "Grenada", dial_code: "+1473", code: "GD", flag: "🇲🇸", currency: "XCD", symbol: "$" },
    { name: "Guadeloupe", dial_code: "+590", code: "GP", flag: "🇬🇵", currency: "EUR", symbol: "€" },
    { name: "Guam", dial_code: "+1671", code: "GU", flag: "🇬🇺", currency: "USD", symbol: "$" },
    { name: "Guatemala", dial_code: "+502", code: "GT", flag: "🇵🇪", currency: "GTQ", symbol: "Q" },
    { name: "Guernsey", dial_code: "+44", code: "GG", flag: "🇬🇬", currency: "GBP", symbol: "£" },
    { name: "Guinea", dial_code: "+224", code: "GN", flag: "🇬🇳", currency: "GNF", symbol: "FG" },
    { name: "Guinea-Bissau", dial_code: "+245", code: "GW", flag: "🇬🇼", currency: "XOF", symbol: "₣" },
    { name: "Guyana", dial_code: "+592", code: "GY", flag: "🇬🇾", currency: "GYD", symbol: "$" },
    { name: "Haiti", dial_code: "+509", code: "HT", flag: "🇭🇹", currency: "HTG", symbol: "G" },
    { name: "Honduras", dial_code: "+504", code: "HN", flag: "🇭🇳", currency: "HNL", symbol: "L" },
    { name: "Hong Kong", dial_code: "+852", code: "HK", flag: "🇭🇰", currency: "HKD", symbol: "$" },
    { name: "Hungary", dial_code: "+36", code: "HU", flag: "🇭🇺", currency: "HUF", symbol: "Ft" },
    { name: "Iceland", dial_code: "+354", code: "IS", flag: "🇮🇸", currency: "ISK", symbol: "kr" },
    { name: "India", dial_code: "+91", code: "IN", flag: "🇮🇳", currency: "INR", symbol: "₹" },
    { name: "Indonesia", dial_code: "+62", code: "ID", flag: "🇮🇩", currency: "IDR", symbol: "Rp" },
    { name: "Iran", dial_code: "+98", code: "IR", flag: "🇮🇷", currency: "IRR", symbol: "﷼" },
    { name: "Iraq", dial_code: "+964", code: "IQ", flag: "🇮🇶", currency: "IQD", symbol: "ع.د" },
    { name: "Ireland", dial_code: "+353", code: "IE", flag: "🇮🇪", currency: "EUR", symbol: "€" },
    { name: "Isle of Man", dial_code: "+44", code: "IM", flag: "🇮🇲", currency: "GBP", symbol: "£" },
    { name: "Israel", dial_code: "+972", code: "IL", flag: "🇮🇱", currency: "ILS", symbol: "₪" },
    { name: "Italy", dial_code: "+39", code: "IT", flag: "🇮🇹", currency: "EUR", symbol: "€" },
    { name: "Jamaica", dial_code: "+1876", code: "JM", flag: "🇯🇲", currency: "JMD", symbol: "$" },
    { name: "Japan", dial_code: "+81", code: "JP", flag: "🇯🇵", currency: "JPY", symbol: "¥" },
    { name: "Jersey", dial_code: "+44", code: "JE", flag: "🇯🇪", currency: "GBP", symbol: "£" },
    { name: "Jordan", dial_code: "+962", code: "JO", flag: "🇯🇴", currency: "JOD", symbol: "د.أ" },
    { name: "Kazakhstan", dial_code: "+7", code: "KZ", flag: "🇰🇿", currency: "KZT", symbol: "₸" },
    { name: "Kenya", dial_code: "+254", code: "KE", flag: "🇰🇪", currency: "KES", symbol: "KSh" },
    { name: "Kiribati", dial_code: "+686", code: "KI", flag: "🇰🇮", currency: "AUD", symbol: "$" },
    { name: "Korea, Democratic People's Republic of", dial_code: "+850", code: "KP", flag: "🇰🇵", currency: "KPW", symbol: "₩" },
    { name: "Korea, Republic of", dial_code: "+82", code: "KR", flag: "🇰🇷", currency: "KRW", symbol: "₩" },
    { name: "Kuwait", dial_code: "+965", code: "KW", flag: "🇰🇼", currency: "KWD", symbol: "د.ك" },
    { name: "Kyrgyzstan", dial_code: "+996", code: "KG", flag: "🇰🇬", currency: "KGS", symbol: "с" },
    { name: "Lao People's Democratic Republic", dial_code: "+856", code: "LA", flag: "🇱🇦", currency: "LAK", symbol: "₭" },
    { name: "Latvia", dial_code: "+371", code: "LV", flag: "🇱🇻", currency: "EUR", symbol: "€" },
    { name: "Lebanon", dial_code: "+961", code: "LB", flag: "🇱🇧", currency: "LBP", symbol: "ل.ل" },
    { name: "Lesotho", dial_code: "+266", code: "LS", flag: "🇱🇸", currency: "LSL", symbol: "L" },
    { name: "Liberia", dial_code: "+231", code: "LR", flag: "🇱🇸", currency: "LRD", symbol: "$" },
    { name: "Libya", dial_code: "+218", code: "LY", flag: "🇱🇾", currency: "LYD", symbol: "د.ل" },
    { name: "Liechtenstein", dial_code: "+423", code: "LI", flag: "🇱🇮", currency: "CHF", symbol: "CHF" },
    { name: "Lithuania", dial_code: "+370", code: "LT", flag: "🇱🇹", currency: "EUR", symbol: "€" },
    { name: "Luxembourg", dial_code: "+352", code: "LU", flag: "🇱🇺", currency: "EUR", symbol: "€" },
    { name: "Macao", dial_code: "+853", code: "MO", flag: "🇲🇴", currency: "MOP", symbol: "MOP$" },
    { name: "Madagascar", dial_code: "+261", code: "MG", flag: "🇲🇬", currency: "MGA", symbol: "Ar" },
    { name: "Malawi", dial_code: "+265", code: "MW", flag: "🇲🇼", currency: "MWK", symbol: "MK" },
    { name: "Malaysia", dial_code: "+60", code: "MY", flag: "🇲🇾", currency: "MYR", symbol: "RM" },
    { name: "Maldives", dial_code: "+960", code: "MV", flag: "🇲🇻", currency: "MVR", symbol: "Rf" },
    { name: "Mali", dial_code: "+223", code: "ML", flag: "🇲🇱", currency: "XOF", symbol: "₣" },
    { name: "Malta", dial_code: "+356", code: "MT", flag: "🇲🇹", currency: "EUR", symbol: "€" },
    { name: "Marshall Islands", dial_code: "+692", code: "MH", flag: "🇲🇭", currency: "USD", symbol: "$" },
    { name: "Mauritania", dial_code: "+222", code: "MR", flag: "🇲🇷", currency: "MRU", symbol: "UM" },
    { name: "Mauritius", dial_code: "+230", code: "MU", flag: "🇲🇺", currency: "MUR", symbol: "₨" },
    { name: "Mayotte", dial_code: "+262", code: "YT", flag: "🇲🇶", currency: "EUR", symbol: "€" },
    { name: "Mexico", dial_code: "+52", code: "MX", flag: "🇲🇽", currency: "MXN", symbol: "$" },
    { name: "Micronesia", dial_code: "+691", code: "FM", flag: "🇫🇲", currency: "USD", symbol: "$" },
    { name: "Moldova, Republic of", dial_code: "+373", code: "MD", flag: "🇲🇩", currency: "MDL", symbol: "L" },
    { name: "Monaco", dial_code: "+377", code: "MC", flag: "🇲🇨", currency: "EUR", symbol: "€" },
    { name: "Mongolia", dial_code: "+976", code: "MN", flag: "🇲🇳", currency: "MNT", symbol: "₮" },
    { name: "Montenegro", dial_code: "+382", code: "ME", flag: "🇲🇪", currency: "EUR", symbol: "€" },
    { name: "Montserrat", dial_code: "+1664", code: "MS", flag: "🇲🇸", currency: "XCD", symbol: "$" },
    { name: "Morocco", dial_code: "+212", code: "MA", flag: "🇲🇦", currency: "MAD", symbol: "د.م." },
    { name: "Mozambique", dial_code: "+258", code: "MZ", flag: "🇲🇿", currency: "MZN", symbol: "MT" },
    { name: "Myanmar", dial_code: "+95", code: "MM", flag: "🇲🇲", currency: "MMK", symbol: "Ks" },
    { name: "Namibia", dial_code: "+264", code: "NA", flag: "🇳🇦", currency: "NAD", symbol: "$" },
    { name: "Nauru", dial_code: "+674", code: "NR", flag: "🇳🇷", currency: "AUD", symbol: "$" },
    { name: "Nepal", dial_code: "+977", code: "NP", flag: "🇳🇵", currency: "NPR", symbol: "Rs" },
    { name: "Netherlands", dial_code: "+31", code: "NL", flag: "🇳🇱", currency: "EUR", symbol: "€" },
    { name: "New Caledonia", dial_code: "+687", code: "NC", flag: "🇳🇨", currency: "XPF", symbol: "₣" },
    { name: "New Zealand", dial_code: "+64", code: "NZ", flag: "🇳🇿", currency: "NZD", symbol: "$" },
    { name: "Nicaragua", dial_code: "+505", code: "NI", flag: "🇳🇮", currency: "NIO", symbol: "C$" },
    { name: "Niger", dial_code: "+227", code: "NE", flag: "🇳🇪", currency: "XOF", symbol: "₣" },
    { name: "Nigeria", dial_code: "+234", code: "NG", flag: "🇳🇬", currency: "NGN", symbol: "₦" },
    { name: "Niue", dial_code: "+683", code: "NU", flag: "🇳🇺", currency: "NZD", symbol: "$" },
    { name: "Norfolk Island", dial_code: "+672", code: "NF", flag: "🇳🇫", currency: "AUD", symbol: "$" },
    { name: "North Macedonia", dial_code: "+389", code: "MK", flag: "🇲🇰", currency: "MKD", symbol: "ден" },
    { name: "Northern Mariana Islands", dial_code: "+1670", code: "MP", flag: "🇲🇵", currency: "USD", symbol: "$" },
    { name: "Norway", dial_code: "+47", code: "NO", flag: "🇳🇴", currency: "NOK", symbol: "kr" },
    { name: "Oman", dial_code: "+968", code: "OM", flag: "🇴🇲", currency: "OMR", symbol: "ر.ع." },
    { name: "Pakistan", dial_code: "+92", code: "PK", flag: "🇵🇰", currency: "PKR", symbol: "₨" },
    { name: "Palau", dial_code: "+680", code: "PW", flag: "🇵🇼", currency: "USD", symbol: "$" },
    { name: "Palestine", dial_code: "+970", code: "PS", flag: "🇵🇸", currency: "ILS", symbol: "₪" },
    { name: "Panama", dial_code: "+507", code: "PA", flag: "🇵🇦", currency: "PAB", symbol: "B/." },
    { name: "Papua New Guinea", dial_code: "+675", code: "PG", flag: "🇵🇬", currency: "PGK", symbol: "K" },
    { name: "Paraguay", dial_code: "+595", code: "PY", flag: "🇵🇾", currency: "PYG", symbol: "₲" },
    { name: "Peru", dial_code: "+51", code: "PE", flag: "🇵🇪", currency: "PEN", symbol: "S/." },
    { name: "Philippines", dial_code: "+63", code: "PH", flag: "🇵🇭", currency: "PHP", symbol: "₱" },
    { name: "Pitcairn", dial_code: "+872", code: "PN", flag: "🇵🇳", currency: "NZD", symbol: "$" },
    { name: "Poland", dial_code: "+48", code: "PL", flag: "🇵🇱", currency: "PLN", symbol: "zł" },
    { name: "Portugal", dial_code: "+351", code: "PT", flag: "🇵🇹", currency: "EUR", symbol: "€" },
    { name: "Puerto Rico", dial_code: "+1787", code: "PR", flag: "🇵🇷", currency: "USD", symbol: "$" },
    { name: "Qatar", dial_code: "+974", code: "QA", flag: "🇶🇦", currency: "QAR", symbol: "ر.ق" },
    { name: "Réunion", dial_code: "+262", code: "RE", flag: "🇷🇪", currency: "EUR", symbol: "€" },
    { name: "Romania", dial_code: "+40", code: "RO", flag: "🇷🇴", currency: "RON", symbol: "lei" },
    { name: "Russia", dial_code: "+7", code: "RU", flag: "🇷🇺", currency: "RUB", symbol: "₽" },
    { name: "Rwanda", dial_code: "+250", code: "RW", flag: "🇷🇼", currency: "RWF", symbol: "FRw" },
    { name: "Saint Barthélemy", dial_code: "+590", code: "BL", flag: "🇧🇱", currency: "EUR", symbol: "€" },
    { name: "Saint Helena, Ascension and Tristan da Cunha", dial_code: "+290", code: "SH", flag: "🇱🇸", currency: "SHP", symbol: "£" },
    { name: "Saint Kitts and Nevis", dial_code: "+1869", code: "KN", flag: "🇰🇳", currency: "XCD", symbol: "$" },
    { name: "Saint Lucia", dial_code: "+1758", code: "LC", flag: "🇱🇨", currency: "XCD", symbol: "$" },
    { name: "Saint Martin", dial_code: "+590", code: "MF", flag: "🇲🇫", currency: "EUR", symbol: "€" },
    { name: "Saint Pierre and Miquelon", dial_code: "+508", code: "PM", flag: "🇵🇲", currency: "EUR", symbol: "€" },
    { name: "Saint Vincent and the Grenadines", dial_code: "+1784", code: "VC", flag: "🇲🇸", currency: "XCD", symbol: "$" },
    { name: "Samoa", dial_code: "+684", code: "WS", flag: "🇼🇸", currency: "WST", symbol: "T" },
    { name: "San Marino", dial_code: "+378", code: "SM", flag: "🇸🇲", currency: "EUR", symbol: "€" },
    { name: "Sao Tome and Principe", dial_code: "+239", code: "ST", flag: "🇲🇿", currency: "STN", symbol: "Db" },
    { name: "Saudi Arabia", dial_code: "+966", code: "SA", flag: "🇸🇦", currency: "SAR", symbol: "ر.س" },
    { name: "Senegal", dial_code: "+221", code: "SN", flag: "🇸🇳", currency: "XOF", symbol: "₣" },
    { name: "Serbia", dial_code: "+381", code: "RS", flag: "🇷🇸", currency: "RSD", symbol: "дин" },
    { name: "Seychelles", dial_code: "+248", code: "SC", flag: "🇸🇨", currency: "SCR", symbol: "₨" },
    { name: "Sierra Leone", dial_code: "+232", code: "SL", flag: "🇸🇱", currency: "SLL", symbol: "Le" },
    { name: "Singapore", dial_code: "+65", code: "SG", flag: "🇸🇬", currency: "SGD", symbol: "$" },
    { name: "Sint Maarten", dial_code: "+1721", code: "SX", flag: "🇸🇽", currency: "ANG", symbol: "ƒ" },
    { name: "Slovakia", dial_code: "+421", code: "SK", flag: "🇸🇰", currency: "EUR", symbol: "€" },
    { name: "Slovenia", dial_code: "+386", code: "SI", flag: "🇸🇮", currency: "EUR", symbol: "€" },
    { name: "Solomon Islands", dial_code: "+677", code: "SB", flag: "🇸🇧", currency: "SBD", symbol: "$" },
    { name: "Somalia", dial_code: "+252", code: "SO", flag: "🇸🇴", currency: "SOS", symbol: "Sh" },
    { name: "South Africa", dial_code: "+27", code: "ZA", flag: "🇿🇦", currency: "ZAR", symbol: "R" },
    { name: "South Georgia", dial_code: "+500", code: "GS", flag: "🇬🇸", currency: "GBP", symbol: "£" },
    { name: "South Sudan", dial_code: "+211", code: "SS", flag: "🇸🇸", currency: "SSP", symbol: "£" },
    { name: "Spain", dial_code: "+34", code: "ES", flag: "🇪🇸", currency: "EUR", symbol: "€" },
    { name: "Sri Lanka", dial_code: "+94", code: "LK", flag: "🇱🇰", currency: "LKR", symbol: "Rs" },
    { name: "Sudan", dial_code: "+249", code: "SD", flag: "🇸🇩", currency: "SDG", symbol: "ج.س." },
    { name: "Suriname", dial_code: "+597", code: "SR", flag: "🇸🇷", currency: "SRD", symbol: "$" },
    { name: "Svalbard and Jan Mayen", dial_code: "+47", code: "SJ", flag: "🇸🇯", currency: "NOK", symbol: "kr" },
    { name: "Sweden", dial_code: "+46", code: "SE", flag: "🇸🇪", currency: "SEK", symbol: "kr" },
    { name: "Switzerland", dial_code: "+41", code: "CH", flag: "🇨🇭", currency: "CHF", symbol: "CHF" },
    { name: "Syrian Arab Republic", dial_code: "+963", code: "SY", flag: "🇸🇾", currency: "SYP", symbol: "ل.س" },
    { name: "Taiwan, Province of China", dial_code: "+886", code: "TW", flag: "🇹🇼", currency: "TWD", symbol: "NT$" },
    { name: "Tajikistan", dial_code: "+992", code: "TJ", flag: "🇹🇯", currency: "TJS", symbol: "SM" },
    { name: "Tanzania, United Republic of", dial_code: "+255", code: "TZ", flag: "🇹🇿", currency: "TZS", symbol: "TSh" },
    { name: "Thailand", dial_code: "+66", code: "TH", flag: "🇹🇭", currency: "THB", symbol: "฿" },
    { name: "Timor-Leste", dial_code: "+670", code: "TL", flag: "🇹🇱", currency: "USD", symbol: "$" },
    { name: "Togo", dial_code: "+228", code: "TG", flag: "🇹🇬", currency: "XOF", symbol: "₣" },
    { name: "Tokelau", dial_code: "+690", code: "TK", flag: "🇹🇰", currency: "NZD", symbol: "$" },
    { name: "Tonga", dial_code: "+676", code: "TO", flag: "🇹🇴", currency: "TOP", symbol: "T$" },
    { name: "Trinidad and Tobago", dial_code: "+1868", code: "TT", flag: "🇹🇹", currency: "TTD", symbol: "$" },
    { name: "Tunisia", dial_code: "+216", code: "TN", flag: "🇹🇳", currency: "TND", symbol: "د.ت" },
    { name: "Turkey", dial_code: "+90", code: "TR", flag: "🇹🇷", currency: "TRY", symbol: "₺" },
    { name: "Turkmenistan", dial_code: "+993", code: "TM", flag: "🇹🇲", currency: "TMT", symbol: "T" },
    { name: "Tuvalu", dial_code: "+688", code: "TV", flag: "🇹🇻", currency: "AUD", symbol: "$" },
    { name: "Uganda", dial_code: "+256", code: "UG", flag: "🇺🇬", currency: "UGX", symbol: "USh" },
    { name: "Ukraine", dial_code: "+380", code: "UA", flag: "🇺🇦", currency: "UAH", symbol: "₴" },
    { name: "United Arab Emirates", dial_code: "+971", code: "AE", flag: "🇦🇪", currency: "AED", symbol: "د.إ" },
    { name: "United Kingdom of Great Britain and Northern Ireland", dial_code: "+44", code: "GB", flag: "🇬🇧", currency: "GBP", symbol: "£" },
    { name: "United States of America", dial_code: "+1", code: "US", flag: "🇺🇸", currency: "USD", symbol: "$" },
    { name: "Uruguay", dial_code: "+598", code: "UY", flag: "🇺🇾", currency: "UYU", symbol: "$" },
    { name: "Uzbekistan", dial_code: "+998", code: "UZ", flag: "🇺🇿", currency: "UZS", symbol: "UZS" },
    { name: "Vanuatu", dial_code: "+678", code: "VU", flag: "🇻🇺", currency: "VUV", symbol: "VT" },
    { name: "Venezuela (Bolivarian Republic of)", dial_code: "+58", code: "VE", flag: "🇻🇪", currency: "VES", symbol: "Bs" },
    { name: "Viet Nam", dial_code: "+84", code: "VN", flag: "🇻🇳", currency: "VND", symbol: "₫" },
    { name: "Western Sahara", dial_code: "+212", code: "EH", flag: "🇪🇭", currency: "MAD", symbol: "د.م." },
    { name: "Yemen", dial_code: "+967", code: "YE", flag: "🇾🇪", currency: "YER", symbol: "ر.ي" },
    { name: "Zambia", dial_code: "+260", code: "ZM", flag: "🇿🇲", currency: "ZMW", symbol: "ZK" },
    { name: "Zimbabwe", dial_code: "+263", code: "ZW", flag: "🇿🇼", currency: "ZWL", symbol: "$" }
]

export const mainCountries = [
    { name: "Ghana", dial_code: "+233", code: "GH", flag: "🇬🇭", currency: "GHS", symbol: "¢" },
    { name: "Kenya", dial_code: "+254", code: "KE", flag: "🇰🇪", currency: "KES", symbol: "KSh" },
    { name: "Nigeria", dial_code: "+234", code: "NG", flag: "🇳🇬", currency: "NGN", symbol: "₦" },
    { name: "Rwanda", dial_code: "+250", code: "RW", flag: "🇷🇼", currency: "RWF", symbol: "FRw" },
    { name: "South Africa", dial_code: "+27", code: "ZA", flag: "🇿🇦", currency: "ZAR", symbol: "R" },
    { name: "Tanzania", dial_code: "+255", code: "TZ", flag: "🇹🇿", currency: "TZS", symbol: "TSh" },
    { name: "United States", dial_code: "+1", code: "US", flag: "🇺🇸", currency: "USD", symbol: "$" },
    { name: "United Kingdom", dial_code: "+44", code: "GB", flag: "🇬🇧", currency: "GBP", symbol: "£" },
    { name: "Zimbabwe", dial_code: "+263", code: "ZW", flag: "🇿🇼", currency: "ZWL", symbol: "$" },
]