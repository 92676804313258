import { ppholder } from "constants/assets";
import moment from "moment";
import React from "react";

type FeedCommentProps = {
  data: any;
};

const FeedComment = ({ data }: FeedCommentProps) => {
  return (
    <article className="p-6 text-base bg-white border-b border-gray-200">
      <footer className="flex justify-between items-center mb-2">
        <div className="flex items-center">
          <p className="inline-flex items-center mr-3 text-sm text-black font-semibold">
            <img
              className="mr-2 w-8 h-8 rounded-full"
              src={
                data.userData.profile_img !== null
                  ? data.userData.profile_img.url
                  : ppholder
              }
              alt="Helene Engels"
            />
            @{data.userData.username || ""}
          </p>
          <p className="text-sm text-gray-500">
            <time title="June 23rd, 2022">
              {moment(data.createdAt).fromNow()}
            </time>
          </p>
        </div>
      </footer>
      <p className="text-black">{data.comment}</p>
    </article>
  );
};

export default FeedComment;
