import {
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Card,
} from "@/components/ui/card";

import React from "react";
import CampaignCard from "./CampaignCard";
import { Hourglass } from "lucide-react";

type VCamProps = {
  data: any;
  currency: any;
};

const ViewCampaign = ({ data, currency }: VCamProps) => {
  return (
    <Card className="w-full p-0 border-0 shadow-none">
      <CardHeader>
        <CardTitle>View Campaign</CardTitle>
        <CardDescription>Shows detailed info of campaign</CardDescription>
      </CardHeader>
      <CardContent>
        <CampaignCard viewBool={true} data={data} currency={currency} />
        <div className="mt-3">
          <h5 className="text-sm">Campaign Insights</h5>
          <div className="mt-3 bg-gray-50 w-full h-60 flex flex-col justify-center rounded-lg items-center">
            <Hourglass className="text-muted-foreground" />
            <h6 className="font-normal text-md text-muted-foreground">
              Coming Soon...
            </h6>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default ViewCampaign;
