import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    dashData: null,
    accountData: null
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardData: (state, data: any) => {
            state.dashData = data.payload
        },
        removeDashboardData: (state) => {
            state.dashData = null
        },
        setAccountData: (state, data: any) => {
            state.accountData = data.payload
        },
        removeAccountData: (state) => {
            state.accountData = null
        }
    }
});

export const { setDashboardData, removeDashboardData, setAccountData, removeAccountData } = dashboardSlice.actions
export default dashboardSlice.reducer;