import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import { useState } from "react";
type SearchInputProps = {
  searchFunc: (data: any, type: string) => void;
};

const SearchInput = ({ searchFunc }: SearchInputProps) => {
  const [inputValue, setInputValue] = useState("");

  const handleChange = (e: any) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z0-9._ ]*$/;
    if (regex.test(value)) {
      searchFunc(value, "new");
      setInputValue(value);
    }
  };

  return (
    <div className="relative ml-auto flex-1 md:grow-0">
      <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
      <Input
        type="search"
        value={inputValue}
        placeholder="Search..."
        className="w-full rounded-lg bg-background pl-8 md:w-[200px] lg:w-[336px]"
        onChange={handleChange}
      />
    </div>
  );
};

export default SearchInput;
