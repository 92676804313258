import { useDispatch, useSelector } from "react-redux";
import { assignOfTokens, unAssignOrg } from '../redux/features/authSlice';
import { axiosPrivate } from "../config/axios";
import { useNavigate } from 'react-router-dom';

const useRefreshToken = () => {
    const router = useNavigate();
    const dispatch = useDispatch();
    const { ofTokens } = useSelector((store: any) => store.auth);
    const refresh = async () => {
        try {
            const response = await axiosPrivate.post('/auth/refresh-auth', {
                headers: {
                    'authorization': `Bearer ${ofTokens.refreshToken}`
                },
            });
            dispatch(assignOfTokens(response.data.data.tokens));
            return response.data.data.tokens.accessToken;

        } catch (error) {
            dispatch(unAssignOrg())
            // localStorage.removeItem("outfun-org");
            router("/");
        }
    }
    return refresh;
};

export default useRefreshToken;