import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { axiosPrivate } from "config/axios";
import { Formik } from "formik";
import useUIMisc from "hooks/useUIMisc";
import React from "react";
import { mainCountries } from "utils/countries";
import { formValidations } from "utils/validations/formValidations";
import * as yup from "yup";
import { useSelector } from "react-redux";

type ReqPayProps = {
  balance: number;
  addFunc: (data: any) => void;
};

const RequestPayout = ({ balance, addFunc }: ReqPayProps) => {
  const { dashData } = useSelector((state: any) => state.dashboard);
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const initPayoutValues = {
    account_name: "",
    amount: 0,
    account_no: "",
    swift_code: "",
    country: "",
    bank_name: "",
  };

  const payoutSchema = yup.object().shape({
    account_name: formValidations.defaultAccText,
    account_no: formValidations.accountNumber,
    amount: formValidations.defaultAmount,
    swift_code: formValidations.defaultAccText,
    country: formValidations.defaultAccText,
    bank_name: formValidations.defaultAccText,
  });

  const handleSubmit = async (values: any) => {
    showLoading();
    const amount = parseInt(values.amount);
    if (balance >= amount) {
      const mainData = {
        ...values,
        amount,
        payType: "event",
        reqId: dashData._id,
      };
      try {
        const mainPay = await axiosPrivate.post(
          `/payouts/${dashData._id}`,
          mainData
        );
        addFunc(mainPay.data.data);
        showToast("Payout Sent Successfully!", "success");
        closeLoading();
      } catch (error) {
        closeLoading();
      }
    } else {
      showToast("Insufficient balance", "warning");
      closeLoading();
    }
  };

  return (
    <div>
      <Card className="w-full p-0 border-0 shadow-none">
        <CardHeader>
          <CardTitle>Request Payout</CardTitle>
          <CardDescription>
            Fill the following fields. Click submit when you're done.
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="border p-3 text-center rounded-lg">
            <span className="text-sm text-muted-foreground">
              Available Balance
            </span>
            <h4 className="text-3xl font-bold">${balance.toFixed(2)}</h4>
          </div>
          <div className="mt-6">
            <h5 className="mb-3 text-sm text-muted-foreground">
              Transfer Form
            </h5>
            <Formik
              validationSchema={payoutSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
              initialValues={initPayoutValues}
            >
              {({
                values,
                errors,
                handleChange,
                touched,
                setFieldValue,
                handleSubmit,
              }: any) => (
                <form onSubmit={handleSubmit}>
                  <div className="grid gap-4 mb-4 sm:grid-cols-2">
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="amount">Amount</Label>
                      <Input
                        id="amount"
                        type="text"
                        placeholder="200"
                        onChange={handleChange}
                        name="amount"
                        value={values.amount}
                      />
                      {errors.amount && touched.amount && (
                        <span className="text-sm font-light text-red-500">
                          {errors.amount}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="transfer">Country</Label>
                      <Select
                        name="country"
                        onValueChange={(e) => {
                          setFieldValue("country", e);
                        }}
                        value={values.country}
                      >
                        <SelectTrigger
                          className="w-full focus:ring-0 mt-1"
                          id="country"
                        >
                          <SelectValue
                            className="focus:ring-0 font-normal"
                            placeholder="Select country"
                          />
                        </SelectTrigger>
                        <SelectContent className="h-36">
                          <SelectGroup>
                            {mainCountries.map((country) => (
                              <SelectItem
                                value={country.name}
                                key={country.name}
                              >
                                {country.name} {country.flag}
                              </SelectItem>
                            ))}
                          </SelectGroup>
                        </SelectContent>
                      </Select>
                      {errors.country && touched.country && (
                        <span className="text-sm font-light text-red-500">
                          {errors.country}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="grid gap-4 mb-4 sm:grid-cols-2">
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="account_name">Account Name</Label>
                      <Input
                        id="account_name"
                        type="text"
                        placeholder="Michael Quaynor"
                        onChange={handleChange}
                        name="account_name"
                        value={values.account_name}
                      />
                      {errors.account_name && touched.account_name && (
                        <span className="text-sm font-light text-red-500">
                          {errors.account_name}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="account_no">Account Number</Label>
                      <Input
                        id="account_no"
                        type="text"
                        placeholder="010049007000293"
                        onChange={handleChange}
                        name="account_no"
                        value={values.account_no}
                      />
                      {errors.account_no && touched.account_no && (
                        <span className="text-sm font-light text-red-500">
                          {errors.account_no}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="bank_name">Bank Name</Label>
                      <Input
                        id="bank_name"
                        type="text"
                        placeholder="Bank of Hope"
                        onChange={handleChange}
                        name="bank_name"
                        value={values.bank_name}
                      />
                      {errors.bank_name && touched.bank_name && (
                        <span className="text-sm font-light text-red-500">
                          {errors.bank_name}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-col space-y-1.5">
                      <Label htmlFor="swift_code">Swift Code</Label>
                      <Input
                        id="swift_code"
                        type="text"
                        placeholder="BUL9N0938"
                        onChange={handleChange}
                        name="swift_code"
                        value={values.swift_code}
                      />
                      {errors.swift_code && touched.swift_code && (
                        <span className="text-sm font-light text-red-500">
                          {errors.swift_code}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="text-center">
                    <Button className="bg-black">Submit</Button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default RequestPayout;
