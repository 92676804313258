import React from "react";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { cleanDate } from "services/misc";

type VPayProps = {
  payout: any;
  currency?: any;
};

const ViewPayouts = ({ payout, currency }: VPayProps) => {
  return (
    <div>
      <Card className="w-full p-0 border-0 shadow-none">
        <CardHeader>
          <CardTitle>View Payout</CardTitle>
          <CardDescription>Shows detailed info of payout</CardDescription>
        </CardHeader>
        <CardContent>
          <div
            className="flex items-center gap-4 border p-2 px-4 rounded-lg mb-3"
            key={"pay"}
          >
            <div className="grid gap-1">
              <p className="text-sm font-medium leading-none">
                PayoutID: <span className="uppercase">#{payout.payId}</span>
              </p>
              <p className="text-sm text-muted-foreground">
                {cleanDate(payout.payDate) || "Processing..."}
              </p>
              <div>
                <Badge
                  className={`text-white bg-emerald-500 font-normal hover:bg-black hover:text-white capitalize ${
                    payout.status === "pending"
                      ? "bg-yellow-300 text-black"
                      : payout.status === "fulfilled"
                      ? " bg-emerald-500"
                      : "bg-red-600"
                  }`}
                >
                  {payout.status}
                </Badge>
              </div>
            </div>
            <div className="ml-auto font-medium">
              {currency || ""}
              {payout.amount}
            </div>
          </div>
          <h5 className="text-sm text-muted-foreground">Transfer Account</h5>
          <div className="border p-2 px-4 rounded-lg mt-3">
            <h6 className="font-medium text-md capitalize">
              {payout.account_name}
            </h6>
            <p className="text-sm text-muted-foreground capitalize">
              {payout.bank_name}
            </p>
            <p className="text-sm text-muted-foreground">{payout.account_no}</p>
            <p className="text-sm text-muted-foreground">
              Swift Code: {payout.swift_code}
            </p>
            <p className="text-sm text-muted-foreground capitalize">
              {payout.country}
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ViewPayouts;
