import { LandingHeader, LandingFooter } from "pages/components";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
type Props = {};

const Terms = (props: Props) => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <title>OutFun Terms and Conditions</title>
        <meta
          name="description"
          content="Read the terms and conditions for using OutFun."
        />
        <meta
          name="keywords"
          content="terms and conditions, user agreement, OutFun"
        />
        <script type="application/ld+json">
          {`
      {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "OutFun Terms and Conditions",
        "url": "https://outfun.app/terms",
        "description": "Read the terms and conditions for using the OutFun event booking platform.",
        "inLanguage": "en",
        "publisher": {
          "@type": "Organization",
          "name": "OutFun",
          "url": "https://outfun.app"
        }
      }
    `}
        </script>
      </Helmet>

      <div>
        <LandingHeader />
        <div className="mx-auto max-w-screen-xl">
          <section className="bg-white dark:bg-gray-900">
            <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
              <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
                Terms of Service
              </h2>
              <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
                Thank you for using OutFun!
              </p>
            </div>
          </section>
          <div className="max-w-3xl mx-auto p-6 bg-white mb-8 pt-0">
            <p className="text-gray-600 mb-4">
              Last Updated: 21st September 2024
            </p>
            <p className="mb-4">
              Welcome to OutFun! These Terms of Service outline the rules and
              regulations for the use of our event app for ticketing and
              booking. By accessing or using our services, you agree to comply
              with these terms.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              1. Acceptance of Terms
            </h2>
            <p className="mb-4 text-gray-600">
              By using our services, you affirm that you are at least 13 years
              old and have the legal capacity to enter into these Terms. If you
              do not agree with any part of these terms, you must not use our
              services.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              2. Modifications to Terms
            </h2>
            <p className="mb-4 text-gray-600">
              We may update these Terms of Service from time to time. Changes
              will be posted on this page, and the date at the top will be
              updated. Continued use of our services after changes indicates
              your acceptance of the new terms.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              3. User Accounts
            </h2>
            <p className="mb-4 text-gray-600">
              To use certain features, you may need to create an account. You
              are responsible for maintaining the confidentiality of your
              account information and for all activities under your account.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              4. Payment and Fees
            </h2>
            <p className="mb-4 text-gray-600">
              All payments for tickets and services are processed through
              third-party payment processors. By making a purchase, you agree to
              their terms and conditions. We reserve the right to change our
              pricing at any time.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">5. User Conduct</h2>
            <p className="mb-4 text-gray-600">
              You agree to use our services only for lawful purposes and in
              accordance with these terms. You must not use our services in any
              way that violates any applicable law or regulation.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              6. Intellectual Property
            </h2>
            <p className="mb-4 text-gray-600">
              All content, trademarks, and other intellectual property related
              to OutFun are owned by us or our licensors. You are granted a
              limited, non-exclusive license to use our services for personal,
              non-commercial use.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              7. Limitation of Liability
            </h2>
            <p className="mb-4 text-gray-600">
              To the fullest extent permitted by law, OutFun shall not be liable
              for any indirect, incidental, or consequential damages arising
              from your use of our services.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">
              8. Governing Law
            </h2>
            <p className="mb-4 text-gray-600">
              These Terms of Service shall be governed by and construted in
              accordance with the laws of Ghana. Any disputes arising from these
              terms shall be resolved in the courts of Ghana.
            </p>

            <h2 className="text-xl font-semibold mt-6 mb-2">9. Contact Us</h2>
            <p className="mb-4 text-gray-600">
              If you have any questions or concerns about these Terms of
              Service, please contact us at:
            </p>
            <p className="mb-4 text-gray-600">
              <strong>OutFun Support</strong>
            </p>
            <p className="mb-4 text-gray-600">
              Email:{" "}
              <a href="mailto:hello@outfun.app" className="text-[#d60c0c]">
                hello@outfun.app
              </a>
            </p>
            <p className="mb-4 text-gray-600">Phone: +233546522014</p>
          </div>
        </div>
        <LandingFooter />
      </div>
    </>
  );
};

export default Terms;
