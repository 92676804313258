import { useToasts } from "react-toast-notifications";
import { hideLoader, showLoader } from "../redux/features/loaderSlice";
import { useDispatch } from "react-redux";

type Prop = {
  showLoading: () => void
  closeLoading: () => void
  showToast: (msg: string, toast: string) => void
}

const useUIMisc = (): Prop => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const showLoading = () => {
    dispatch(showLoader())
  }

  const closeLoading = () => {
    dispatch(hideLoader())
  }

  const showToast = (message: string, toastType: any) => {
    addToast(message, {
      appearance: toastType,
      autoDismiss: true,
    });
  };

  return { showLoading, closeLoading, showToast }
};

export default useUIMisc;