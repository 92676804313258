import { StatsCard } from "pages/components";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../@/components/ui/dropdown-menu";
import {
  ListFilter,
  MessageSquareHeart,
  Star,
  ThumbsDown,
  ThumbsUp,
  Ticket,
} from "lucide-react";
import { Button } from "../../../@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useEffect, useState } from "react";
import ReviewCard from "./components/ReviewCard";
import ViewReview from "./components/ViewReview";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { useSelector } from "react-redux";
import useUIMisc from "hooks/useUIMisc";
import { SkeletonCard } from "components/custom/SkeletonCards";
import { uniqBy } from "lodash";
import { EmptyContainer } from "components/custom/EmptyState";
import { REVIEW_FILTER } from "constants/data";
import useScrollToBottom from "hooks/useScrollToBottom";
type Props = {};

const ReviewsPage = (props: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const [allReviews, setAllReviews] = useState<any[]>([]);
  const { showToast } = useUIMisc();
  const initFilter = {
    reviewType: "all",
    status: "all",
    sort: "desc",
  };
  const initPageData = {
    page: 1,
    total: 0,
  };
  const [emptyState, setEmptyState] = useState(false);
  const [pageData, setPageData] = useState(initPageData);
  const [filter, setFilter] = useState(initFilter);

  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );
  const [selectedReview, setSelectedReview] = useState<any>({});
  const initStats = {
    total: 0,
    good: 0,
    fair: 0,
    bad: 0,
  };
  const [statsData, setStatsData]: any = useState(initStats);

  const openView = (data: any) => {
    setSelectedReview(data);
    setOpenModal(true);
  };

  const getReviews = async () => {
    try {
      const reviews = await axiosPrivate.get(`/reviews/all/${dashData._id}`, {
        params: {
          page: pageData.page,
          reviewType: filter.reviewType,
        },
      });
      const responseData = reviews.data.data;
      setPageData({ ...pageData, total: reviews.data.total });
      setAllReviews([...allReviews, ...responseData]);
      if (responseData.length === 0 && allReviews.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {}
  };

  const loadMore = () => {
    if (pageData.total !== allReviews.length) {
      setPageData({ ...pageData, page: pageData.page + 1 });
    }
  };

  const changeFilter = (filterType: string) => {
    setAllReviews([]);
    setPageData(initPageData);
    setFilter({ ...filter, reviewType: filterType });
  };

  const getStats = async () => {
    try {
      const statsRes = await axiosPrivate.get(
        `/reviews/stats/${dashData._id}/${
          accountData.business_type === "venue_manager"
            ? "venue_manager"
            : "event_organizer"
        }`
      );
      setStatsData(
        statsRes.data.data.total !== undefined ? statsRes.data.data : initStats
      );
    } catch (error) {}
  };

  useEffect(() => {
    getStats();
  }, []);

  const loadMoreData = () => {
    setTimeout(() => {
      loadMore();
      resetFetching();
    }, 1000);
  };

  const { isFetching, resetFetching } = useScrollToBottom(loadMoreData, 100);

  useEffect(() => {
    if (dashData !== null) {
      getReviews();
    }
  }, [pageData.page, filter.reviewType]);

  return (
    <div>
      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent className="max-w-3xl max-h-screen p-0">
          <ViewReview review={selectedReview} />
        </DialogContent>
      </Dialog>
      <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4">
        <StatsCard
          name="Total Reviews"
          figure={statsData.total}
          iconData={{ icon: MessageSquareHeart }}
          info="Number of reviews"
        />
        <StatsCard
          name="Good Reviews"
          figure={statsData.good}
          iconData={{ icon: ThumbsUp }}
          info="Number of good reviews"
        />
        <StatsCard
          name="Bad Reviews"
          figure={statsData.bad}
          iconData={{ icon: ThumbsDown }}
          info="Number of bad reviews"
        />
        <StatsCard
          name="Rating"
          figure={
            dashData.rating === 0 && dashData.reviewCount === 0
              ? "5.0"
              : (dashData.rating / dashData.reviewCount).toFixed(1)
          }
          iconData={{ icon: Star }}
          info="From users"
        />
      </div>
      <div className="flex justify-between items-center mb-5">
        <h5 className="font-bold text-3xl">Reviews</h5>
        <div className="flex justify-end mt-4 gap-3">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                <ListFilter /> Filter
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-56">
              {REVIEW_FILTER.map((reviewFilter) => (
                <DropdownMenuItem
                  onClick={() => changeFilter(reviewFilter.slug)}
                >
                  {reviewFilter.name}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>
      <div className="grid lg:grid-cols-3 2xl:grid-cols-4 gap-5">
        {allReviews !== undefined && allReviews.length !== 0 ? (
          <>
            {uniqBy(allReviews, "_id").map((reviews) => (
              <>
                <ReviewCard setFunc={openView} data={reviews} />
              </>
            ))}
          </>
        ) : allReviews.length === 0 && !emptyState ? (
          <>
            {Array.from({ length: 4 }).map((card) => (
              <SkeletonCard />
            ))}
          </>
        ) : (
          <div className="col-span-4">
            <EmptyContainer
              message={"No reviews yet!"}
              instructions={`Reviews will show here!`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ReviewsPage;
