import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { Ticket } from "lucide-react";
import { cleanDate } from "services/misc";

type TicketProps = {
  key: any;
  viewBool: boolean;
  data: any;
  viewFunc?: (data: any) => void;
  currency?: any;
};

const TicketCard = ({ viewBool, data, viewFunc, currency }: TicketProps) => {
  return (
    <div
      className={`bg-white p-5 gap-3 rounded-lg cursor-pointer ${
        viewBool ? "border" : "shadow"
      }`}
      onClick={() => {
        viewFunc?.(data);
      }}
    >
      <div className="w-full">
        <div className="flex justify-between items-center">
          <div>
            <h5 className="text-lg capitalize font-medium tracking-tight text-black">
              {data.name}
            </h5>
            {viewBool ? (
              <>
                <p className="text-sm text-gray-500">{data.desc}</p>
                <p className="text-sm text-gray-500">
                  Max. Qty per Order: {data.maxOrder}
                </p>
                <p className="text-sm text-gray-500">
                  {viewBool
                    ? `Sale from: ${cleanDate(data.startDate)} - ${cleanDate(
                        data.endDate
                      )}`
                    : null}
                </p>
              </>
            ) : null}
          </div>
          <div className="flex flex-col justify-start items-center">
            <div className="bg-gray-100 p-2 rounded-md">
              <Ticket />
            </div>
            <p className="m-0 font-normal text-gray-500">
              {currency || ""}
              {data.price}
            </p>
          </div>
        </div>

        <p className="m-0 font-normal text-gray-500">Qty: {data.qty}</p>
        <div></div>
        <Badge
          className={`text-white bg-emerald-500 font-normal hover:bg-black hover:text-white capitalize ${
            data.status === "on hold"
              ? "bg-yellow-300 text-black"
              : data.status === "live"
              ? " bg-emerald-500"
              : "bg-red-600"
          }`}
        >
          {data.status}
        </Badge>

        <Separator className="my-2" />
        <div className="grid grid-cols-3 divide-x">
          <div className="text-center">
            <span className="text-gray-400">Sold</span>
            <h6 className="font-medium">{data.sold}</h6>
          </div>
          <div className="text-center">
            <span className="text-gray-400">Scanned</span>
            <h6 className="font-medium">{data.scanned}</h6>
          </div>
          <div className="text-center">
            <span className="text-gray-400">Revenue</span>
            <h6 className="font-medium">
              {currency || ""}
              {data.revenue}
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketCard;
