import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    oforg: null,
    loggedIn: false,
    ofTokens: null,
}

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        assignOrg: (state, data: any) => {
            state.oforg = data.payload;
            state.loggedIn = true;
        },
        unAssignOrg: (state) => {
            state.oforg = null;
            state.loggedIn = false;
            state.ofTokens = null;
        },
        assignOfTokens: (state, data) => {
            state.ofTokens = data.payload;
        },
        updateOrg: (state, data: any) => {
            state.oforg = data.payload;
        },

    }
})


export const { assignOrg, unAssignOrg, updateOrg, assignOfTokens } = authSlice.actions;
export default authSlice.reducer;