import SimpleCrypto from 'simple-crypto-js';
import * as L from "leaflet";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import moment from 'moment';
import { addDays, isBefore, startOfToday } from 'date-fns';
import { INTERESTS } from 'constants/data';
import { mainCountries } from 'utils/countries';
const cryptoInstance = new SimpleCrypto(`${process.env.REACT_APP_CRYPTO_KEY}`);
dayjs.extend(relativeTime);
let dateNow = dayjs();

const encryptToken = (token: any) => {
    const encData = cryptoInstance.encrypt(token);
    localStorage.setItem('outOrg', encData);
}

const decryptToken = () => {
    const outOrgToken: any = localStorage.getItem('outOrg');
    if (outOrgToken !== null) {
        const decData = cryptoInstance.decrypt(outOrgToken);
        return decData;
    } else {
        return null
    }
}

const generateTimeOptions = () => {
    const times = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const value = `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
            times.push({ value, label: value });
        }
    }
    return times;
};


const handleOpenSocial = (link: string) => {
    const ahref = document.createElement("a");
    ahref.href = `${link}`;
    ahref.target = "_blank";
    ahref.click();
};

const timeAgo = (date: Date | undefined) => {
    return dateNow.from(date, true)
}

const callNumber = (phone: string | number) => {
    var a = document.createElement('a');
    a.href = `tel:${phone}`
    a.click()
}

const getStartedBtn = () => {
    const ahref = document.createElement("a");
    ahref.href = "/login";
    // ahref.target = "_blank";
    ahref.click();
};

const dateFormatter = (date: any, type: string) => {
    const formattedDate = moment(date).format(type === 'month' ? 'MMM' : 'DD').toUpperCase();
    return formattedDate
};

const cleanDate = (date: any,) => {
    return moment(date).format("Do MMM. YYYY")
}

const toArrayList = (array: any[]) => {
    return array.map(item => item.value);
}

const calculateDays = (startDate: any, endDate: any) => {
    // Convert the dates from string to Date objects
    const start: any = new Date(startDate);
    const end: any = new Date(endDate);

    // Calculate the difference in time
    const timeDiff = end - start;

    // Convert time difference from milliseconds to days
    const daysDiff = timeDiff / (1000 * 60 * 60 * 24) + 1;

    return daysDiff;
}


const nowDate = (date: any) => {
    return moment(date).fromNow();
};

const transformedArray = (data: any) => {
    const mainData = data.map((item: any) => ({
        value: item,
        label: item
    }));

    return mainData
}


const isObjEmpty = (obj: any) => {
    return Object.keys(obj).length === 0;
}

const getName = (name: string) => {
    const nameParts = name.split(' ');

    // Extract the first name and last name
    const firstname = nameParts[0];
    const lastname = nameParts[nameParts.length - 1];
    return { firstname, lastname };
}

const disablePastDates = (date: any) => {
    const today = new Date();
    return isBefore(date, today); // Returns true if date is before today
};

const getToday = () => {
    const today = startOfToday();
    return today
}

const getRandomSuggestions = (selectedInterests: any) => {


    let combinedSuggestions: string[] = [];

    selectedInterests.forEach((interest: any) => {
        const interestData = INTERESTS.find(i => i.value === interest);
        if (interestData) {
            combinedSuggestions = combinedSuggestions.concat(interestData.suggestions);
        }
    });

    // Ensure uniqueness without using Set
    const uniqueSuggestions = combinedSuggestions.filter((suggestion, index, array) => array.indexOf(suggestion) === index);
    uniqueSuggestions.sort(() => 0.5 - Math.random());

    return uniqueSuggestions.slice(0, 10);
}


const getCurrencyByCountryName = (countryName: string) => {
    const country = mainCountries.find((c) => c.name === countryName);
    return country ? country.symbol : "";
}

const getValueAndLabel = (data: any) => {
    return data.map((item: any) => ({
        value: item.value,
        label: item.label
    }));
};

export {
    encryptToken,
    decryptToken,
    calculateDays,
    getToday,
    nowDate,
    handleOpenSocial,
    timeAgo,
    getValueAndLabel,
    disablePastDates,
    getRandomSuggestions,
    isObjEmpty,
    callNumber,
    dateFormatter,
    cleanDate,
    getStartedBtn,
    transformedArray,
    generateTimeOptions,
    toArrayList,
    getName,
    getCurrencyByCountryName
}