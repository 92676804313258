/* eslint-disable react-hooks/rules-of-hooks */
import {
  Home,
  Settings,
  PanelLeft,
  Ticket,
  Users,
  Wallet,
  MessagesSquare,
  BriefcaseBusiness,
  Star,
  Megaphone,
  Repeat2,
  RocketIcon,
} from "lucide-react";
import {
  TooltipTrigger,
  Tooltip,
  TooltipProvider,
  TooltipContent,
} from "../../../@/components/ui/tooltip";
import { ReactNode, useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetTrigger,
} from "../../../@/components/ui/sheet";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "../../../@/components/ui/breadcrumb";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../@/components/ui/dropdown-menu";

import { Button } from "../../../@/components/ui/button";
import { logoBlack, ppholder } from "constants/assets";
import { unAssignOrg } from "redux/features/authSlice";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { EmptyContainer } from "components/custom/EmptyState";
import { SkeletonCard } from "components/custom/SkeletonCards";
import { uniqBy } from "lodash";
import {
  removeAccountData,
  removeDashboardData,
  setDashboardData,
} from "redux/features/dashboardSlice";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Helmet } from "react-helmet-async";
interface Props {
  children: ReactNode;
}

const OrganizerLayout = ({ children }: Props) => {
  const { accountData, dashData } = useSelector(
    (state: any) => state.dashboard
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { oforg } = useSelector((state: any) => state.auth);
  const { showToast, showLoading, closeLoading } = useUIMisc();
  const location = useLocation();
  const [openModal, setOpenModal] = useState(false);

  const initPageData = {
    searchPage: 1,
    searchTotal: 0,
    page: 1,
    total: 0,
  };
  const [emptyState, setEmptyState] = useState(false);
  const [allPages, setAllPages] = useState<any[]>([]);
  const [pageData, setPageData] = useState(initPageData);
  const [isCompleted, setIsCompleted] = useState(true);
  const pathnames = location.pathname.split("/").filter((x) => x);
  const lastPathname = pathnames[pathnames.length - 1];
  const lastPath = `/${pathnames.join("/")}`;

  const hideNavTabs =
    location.pathname.includes("/outfunner/accounts") ||
    location.pathname.includes("/outfunner/manager");

  if (!oforg.email_verified && location.pathname !== "/outfunner/accounts") {
    return <Navigate to="/outfunner/accounts" />;
  }
  const navData = [
    {
      title: "Dashboard",
      slug: "dashboard",
      icon: <Home className="h-5 w-5" />,
    },
    { title: "Tickets", slug: "tickets", icon: <Ticket className="h-5 w-5" /> },
    {
      title: "Transactions",
      slug: "transactions",
      icon: <Wallet className="h-5 w-5" />,
    },
    { title: "Teams", slug: "teams", icon: <Users className="h-5 w-5" /> },
    {
      title: "Feeds",
      slug: "feeds",
      icon: <MessagesSquare className="h-5 w-5" />,
    },
    {
      title: "Campaigns",
      slug: "campaigns",
      icon: <Megaphone className="h-5 w-5" />,
    },
    {
      title: "Reviews",
      slug: "reviews",
      icon: <Star className="h-5 w-5" />,
    },
  ];

  const venueNavData = [
    {
      title: "Dashboard",
      slug: "dashboard",
      icon: <Home className="h-5 w-5" />,
    },
    {
      title: "Bookings",
      slug: "bookings",
      icon: <BriefcaseBusiness className="h-5 w-5" />,
    },
    {
      title: "Campaigns",
      slug: "campaigns",
      icon: <Megaphone className="h-5 w-5" />,
    },
    {
      title: "Reviews",
      slug: "reviews",
      icon: <Star className="h-5 w-5" />,
    },
  ];

  const logoutUser = async () => {
    showLoading();
    try {
      await axiosPrivate.post(`/auth/logout`);
      dispatch(unAssignOrg());
      dispatch(removeAccountData());
      dispatch(removeDashboardData());
      localStorage.removeItem("authUser");
      navigate("/login");
      closeLoading();
      showToast("Logged out successfully", "success");
    } catch (error) {
      closeLoading();
      showToast("An error occured", "error");
    }
  };

  const getEvents = async () => {
    try {
      const getAllPages = await axiosPrivate.get(
        `/events/manager/${accountData._id}`,
        {
          params: {
            page: pageData.page,
          },
        }
      );
      const responseData = getAllPages.data.data;
      setPageData({ ...pageData, total: getAllPages.data.total });
      setAllPages([...allPages, ...responseData]);
      if (responseData.length === 0 && allPages.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  const getBusinessVenue = async () => {
    try {
      const getAllPages = await axiosPrivate.get(
        `/business/manager/venue/${accountData._id}`,
        {
          params: {
            page: pageData.page,
          },
        }
      );
      const responseData = getAllPages.data.data;
      setPageData({ ...pageData, total: getAllPages.data.total });
      setAllPages([...allPages, ...responseData]);
      if (responseData.length === 0 && allPages.length === 0) {
        setEmptyState(true);
      }
    } catch (error: any) {
      showToast(error.response.data.message, "error");
    }
  };

  const openPage = (data: any) => {
    if (data._id !== dashData._id) {
      dispatch(setDashboardData(data));

      setTimeout(() => {
        window.location.href = "/outfunner/dashboard";
      }, 1000);
    }
    setOpenModal(false);
  };

  const getData = () => {
    if (accountData !== null) {
      if (accountData.business_type === "event_organizer" && openModal) {
        getEvents();
      } else if (accountData.business_type === "venue_manager" && openModal) {
        getBusinessVenue();
      }
    }
  };

  const checkDataFunc = () => {
    // Essential fields to check
    const essentialFields =
      accountData.business_type === "event_organizer"
        ? [
            "event_type",
            "name",
            "desc",
            "event_category",
            "shortdesc",
            "startDate",
            "startTime",
            "interests",
            "event_type",
            "image",
            "startTime",
            "venue.venue_name",
            "venue.address",
            "venue.city",
            "venue.country",
            "venue.link",
          ]
        : [
            "phone_one",
            "name",
            "bio",
            "email",
            "business_type",
            "address",
            "city",
            "country",
            "amenities",
            "suitableFor",
            "image",
            "work_days",
            "venue_link",
            "venue_type",
          ];

    if (accountData.business_type === "event_organizer") {
      const missingFields = essentialFields.filter((field) => {
        const fieldParts = field.split(".");
        if (fieldParts.length > 1) {
          return (
            !dashData[fieldParts[0]] || !dashData[fieldParts[0]][fieldParts[1]]
          );
        }
        return !dashData[field];
      });

      return missingFields.length === 0;
    } else {
      const missingFields = essentialFields.filter((field) => {
        return (
          !dashData[field] ||
          (Array.isArray(dashData[field]) && dashData[field].length === 0)
        );
      });
      return missingFields.length === 0;
    }
  };

  useEffect(() => {
    getData();
  }, [accountData, pageData.page, openModal]);

  useEffect(() => {
    if (location.pathname.includes("/outfunner/accounts")) {
      setAllPages([]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (dashData !== null) {
      const isValid = checkDataFunc();
      setIsCompleted(isValid);
    }
  }, [location.pathname, dashData]);

  return (
    <div>
      <Helmet>
        <title>OutFun Dashboard - Manage Your Events</title>
        <meta
          name="description"
          content="Access the OutFun dashboard to manage your events and bookings."
        />
        <meta
          name="keywords"
          content="OutFun dashboard, manage events, event tracking, event management"
        />
      </Helmet>
      <div className="flex min-h-screen w-full flex-col bg-muted/40">
        <Dialog open={openModal} onOpenChange={setOpenModal}>
          <DialogContent className="max-w-xl max-h-screen">
            <DialogHeader>
              <DialogTitle className="text-xl">
                Your{" "}
                {accountData !== null &&
                accountData.business_type === "event_organizer"
                  ? "Events"
                  : "Venues"}
              </DialogTitle>
            </DialogHeader>

            {allPages !== undefined && allPages.length !== 0 ? (
              <>
                {uniqBy(allPages, "_id").map((page) => (
                  <>
                    <div
                      className="flex gap-3 rounded-sm justify-start items-center cursor-pointer hover:bg-slate-50 p-2"
                      onClick={() => openPage(page)}
                    >
                      <div className="w-10 h-10">
                        <img
                          src={page.image !== null ? page.image.url : ppholder}
                          alt="profile"
                          className="overflow-hidden rounded-full w-full h-full"
                        />
                      </div>
                      <h5>{page.name}</h5>
                    </div>
                  </>
                ))}
              </>
            ) : allPages.length === 0 && !emptyState ? (
              <>
                {Array.from({ length: 1 }).map((card) => (
                  <SkeletonCard />
                ))}
              </>
            ) : (
              <div className="col-span-3">
                <EmptyContainer
                  message={
                    accountData.business_type === "event_organizer"
                      ? "No events yet!"
                      : accountData.business_type === "venue_manager"
                      ? "No venues yet!"
                      : ""
                  }
                  instructions={`Click the "Add" button to create one`}
                />
              </div>
            )}
          </DialogContent>
        </Dialog>

        <TooltipProvider>
          <aside className="fixed inset-y-0 left-0 z-10 hidden w-14 flex-col border-r bg-background sm:flex">
            <nav className="flex flex-col items-center gap-4 px-2 sm:py-5">
              <Link
                to={"/outfunner/dashboard"}
                className="group flex items-center justify-start gap-2"
              >
                <img src={logoBlack} alt="" className="w-10 h-10" />
                <span className="sr-only">OutFun</span>
              </Link>
              {!hideNavTabs && (
                <>
                  {accountData.business_type === "event_organizer" ? (
                    <>
                      {navData.map((nav) => (
                        <Tooltip key={nav.slug}>
                          <TooltipTrigger asChild>
                            <Link
                              to={`/outfunner/${nav.slug}`}
                              className={`flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8 ${
                                location.pathname.includes(
                                  `/outfunner/${nav.slug}`
                                )
                                  ? "bg-black text-white hover:text-white"
                                  : ""
                              }`}
                            >
                              {nav.icon}
                              <span className="sr-only">{nav.title}</span>
                            </Link>
                          </TooltipTrigger>
                          <TooltipContent side="right">
                            {nav.title}
                          </TooltipContent>
                        </Tooltip>
                      ))}
                    </>
                  ) : accountData.business_type === "venue_manager" ? (
                    <>
                      {venueNavData.map((nav) => (
                        <Tooltip key={nav.slug}>
                          <TooltipTrigger asChild>
                            <Link
                              to={`/outfunner/${nav.slug}`}
                              className={`flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8 ${
                                location.pathname.includes(
                                  `/outfunner/${nav.slug}`
                                )
                                  ? "bg-black text-white hover:text-white"
                                  : ""
                              }`}
                            >
                              {nav.icon}
                              <span className="sr-only">{nav.title}</span>
                            </Link>
                          </TooltipTrigger>
                          <TooltipContent side="right">
                            {nav.title}
                          </TooltipContent>
                        </Tooltip>
                      ))}
                    </>
                  ) : null}
                </>
              )}
            </nav>
            {!hideNavTabs ? (
              <nav className="mt-auto flex flex-col items-center gap-4 px-2 sm:py-5">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Link
                      to="/outfunner/settings"
                      className={`flex h-9 w-9 items-center justify-center rounded-lg text-muted-foreground transition-colors hover:text-foreground md:h-8 md:w-8 ${
                        location.pathname.includes(`/outfunner/settings`)
                          ? "bg-black text-white hover:text-white"
                          : ""
                      }`}
                    >
                      <Settings className="h-5 w-5" />
                      <span className="sr-only">Settings</span>
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent side="right">Settings</TooltipContent>
                </Tooltip>
              </nav>
            ) : null}
          </aside>
        </TooltipProvider>
        <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">
          <header className="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6">
            <Sheet>
              <SheetTrigger asChild>
                <Button size="icon" variant="outline" className="sm:hidden">
                  <PanelLeft className="h-5 w-5" />
                  <span className="sr-only">Toggle Menu</span>
                </Button>
              </SheetTrigger>
              <SheetContent side="left" className="sm:max-w-xs">
                <nav className="grid gap-6 text-lg font-medium">
                  <SheetClose asChild>
                    <Link
                      to={"/outfunner"}
                      className="group flex items-center justify-start gap-2"
                    >
                      <img src={logoBlack} alt="" className="w-10 h-10" />
                      <span className="sr-only">OutFun</span>
                    </Link>
                  </SheetClose>
                  {navData.map((nav) => (
                    <SheetClose asChild>
                      <Link
                        to={`/outfunner/${nav.slug}`}
                        className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
                      >
                        {nav.icon}
                        {nav.title}
                      </Link>
                    </SheetClose>
                  ))}
                  <Link
                    to="/outfunner/settings"
                    className="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
                  >
                    <Settings className="h-5 w-5" />
                    Settings
                  </Link>
                </nav>
              </SheetContent>
            </Sheet>
            <Breadcrumb className="hidden md:flex">
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink asChild>
                    <Link to={"/outfunner/dashboard"}>OutFun</Link>
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                {lastPathname && (
                  <BreadcrumbItem>
                    <BreadcrumbLink asChild>
                      <Link to={lastPath}>
                        {lastPathname.charAt(0).toUpperCase() +
                          lastPathname.slice(1)}
                      </Link>
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                )}
              </BreadcrumbList>
            </Breadcrumb>
            <div className="relative ml-auto flex-1 flex justify-end items-center md:grow-0 gap -3">
              {!hideNavTabs ? (
                <>
                  <Button
                    className="mr-3 text-sm bg-transparent text-gray-500 hover:bg-black hover:text-white"
                    onClick={() => setOpenModal(true)}
                  >
                    <Repeat2 className="mr-1" size={20} />
                    Switch{" "}
                    {accountData.business_type === "event_organizer"
                      ? "Events"
                      : "Venues"}
                  </Button>
                </>
              ) : null}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    className="overflow-hidden rounded-full"
                  >
                    <img
                      src={
                        accountData !== null && accountData.img !== null
                          ? accountData.img.url
                          : ppholder
                      }
                      width={36}
                      height={36}
                      alt="Avatar"
                      className="overflow-hidden rounded-full"
                    />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuLabel>My Account</DropdownMenuLabel>
                  <DropdownMenuSeparator />
                  {/* <DropdownMenuItem>Support</DropdownMenuItem>
                <DropdownMenuSeparator /> */}
                  <DropdownMenuItem className="cursor-pointer">
                    <Link to={"/outfunner/accounts"}>My Profiles</Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className="cursor-pointer"
                    onClick={logoutUser}
                  >
                    Logout
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </header>
          {dashData && !isCompleted && (
            <div className="w-full px-3">
              <Alert className="border border-yellow-300 text-yellow-800">
                <RocketIcon className="h-4 w-4" />
                <AlertTitle>Heads up!</AlertTitle>
                <AlertDescription>
                  Please provide all the important details about the{" "}
                  {accountData.business_type === "event_organizer"
                    ? "event"
                    : "venue"}{" "}
                  for approval.
                </AlertDescription>
              </Alert>
            </div>
          )}
          <main className="p-4">{children}</main>
        </div>
      </div>
    </div>
  );
};

export default OrganizerLayout;
