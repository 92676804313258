import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";

import { ppholder } from "constants/assets";

type TCProps = {
  viewBool: boolean;
  data?: any;
  viewFunc?: (data: any) => void;
};

const TeamCard = ({ viewBool, data, viewFunc }: TCProps) => {
  return (
    <div
      className={`flex justify-start items-start bg-white p-5 gap-3 rounded-lg cursor-pointer ${
        viewBool ? "border" : "shadow"
      } `}
      onClick={() => {
        viewFunc?.(data);
      }}
    >
      <div className="w-20 h-16 ">
        <img
          className="object-cover w-full h-full rounded-full"
          src={
            data.userData.profile_img !== null
              ? data.userData.profile_img.url
              : ppholder
          }
          alt="team"
        />
      </div>
      <div className="w-full">
        <h5 className="text-lg font-medium tracking-tight text-black">
          @{data.username}
        </h5>
        <p className="m-0 font-normal text-sm text-gray-500 capitalize">
          {data.status}
        </p>
        <Badge className="bg-gray-200 text-black font-normal hover:bg-black hover:text-white">
          {data.roles === "scan"
            ? `Scan only`
            : data.roles === "sell"
            ? `Sales only`
            : `Both Scan & Sales`}
        </Badge>

        <Separator className="my-2" />
        <div className="grid grid-cols-3 divide-x">
          <div className="text-center">
            <span className="text-gray-400">Allocated</span>
            <h6 className="font-medium">{data.allocatedTickets}</h6>
          </div>
          <div className="text-center">
            <span className="text-gray-400">Scanned</span>
            <h6 className="font-medium">{data.scannedTickets}</h6>
          </div>
          <div className="text-center">
            <span className="text-gray-400">Sold</span>
            <h6 className="font-medium">{data.soldTickets}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamCard;
