import { Button } from "@/components/ui/button";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { ppholder } from "constants/assets";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import useUIMisc from "hooks/useUIMisc";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import { assignOrg } from "redux/features/authSlice";
import AddAccount from "./components/AddAccount";
import { Badge } from "@/components/ui/badge";
import { useNavigate } from "react-router-dom";
import { EmptyContainer } from "components/custom/EmptyState";
import {
  removeDashboardData,
  setAccountData,
} from "redux/features/dashboardSlice";
import EditManager from "./EditManager";

const PageOpener = () => {
  const [otp, setOtp] = useState("");
  const { showLoading, showToast, closeLoading } = useUIMisc();
  const { oforg } = useSelector((state: any) => state.auth);
  const axiosPrivate = useAxiosPrivate();
  const [selectedManager, setSelectedManager] = useState<any>({});
  const navigate = useNavigate();
  const [activeModal, setActiveModal] = useState("");
  const [allAccounts, setAllAccounts] = useState<any[]>([]);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    dispatch(removeDashboardData());
    if (oforg.email_verified) {
      getAccounts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oforg]);

  const verifyAccount = async () => {
    showLoading();
    try {
      const resData = await axiosPrivate.post(`/auth/verify-account`, {
        token: otp,
      });
      closeLoading();
      showToast("Profile verified successful!", "success");
      dispatch(assignOrg(resData.data.data));
    } catch (error: any) {
      closeLoading();
      showToast(error.response.data.message, "error");
    }
  };

  const resendCode = async () => {
    showLoading();
    try {
      await axiosPrivate.post(`/auth/resend-verify `);
      closeLoading();
      showToast("Code sent to email successful!", "success");
    } catch (error: any) {
      closeLoading();
      showToast(error.response.data.message, "error");
    }
  };

  const getAccounts = async () => {
    try {
      const getAllAccs = await axiosPrivate.get(`/accounts/${oforg._id}`);
      setAllAccounts(getAllAccs.data.data);
    } catch (error: any) {
      closeLoading();
      showToast(error.response.data.message, "error");
    }
  };

  const addAccountFunc = async (values: any) => {
    showLoading();
    try {
      const resData = await axiosPrivate.post(`/accounts/`, values);
      const allData: any = [...allAccounts];
      allData.push(resData.data.data);
      setAllAccounts(allData);
      closeLoading();
      setOpenModal(false);
      showToast("Manager profile created!", "success");
    } catch (error: any) {
      closeLoading();
      showToast(error.response.data.message, "error");
    }
  };

  const openModalFunc = (type: string, data?: any) => {
    setActiveModal(type);
    setOpenModal(true);
    if (type === "edit") {
      setSelectedManager(data);
    }
  };

  const openAccount = (data: any) => {
    if (data.admin_approval) {
      navigate(`/outfunner/manager/${data._id}`);
      dispatch(setAccountData(data));
    } else {
      showToast("Profile in review. Kindly hold on!", "warning");
    }
  };

  const updateManagerList = (data: any) => {
    const allData: any = [...allAccounts];

    const findAccIndex = allData.findIndex(
      (a: any) => a._id === selectedManager._id
    );
    allData[findAccIndex] = data;
    setAllAccounts([...allData]);
  };

  return (
    <div>
      <Dialog open={openModal} onOpenChange={setOpenModal}>
        <DialogContent className="max-w-3xl max-h-screen">
          {activeModal === "add" ? (
            <AddAccount addFunc={addAccountFunc} />
          ) : activeModal === "edit" ? (
            <EditManager
              data={selectedManager}
              updateFunc={updateManagerList}
            />
          ) : null}
        </DialogContent>
      </Dialog>
      <section className="mt-3">
        <div className="py-3 px-4 mx-auto max-w-screen-xl flex flex-col justify-center">
          {!oforg.email_verified ? (
            <div className="mx-auto mt-16 justify-center w-2/3">
              <h6 className="text-center text-3xl font-bold">
                Verify your account!
              </h6>
              <p className="text-center">
                We have sent you a 6 digits code to your email address
              </p>
              <div className="mt-5">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderSeparator={<span>-</span>}
                  renderInput={(props) => <input {...props} />}
                  containerStyle={"justify-center p-2"}
                  inputStyle={
                    "border-gray-300 font-medium text-2xl !w-20 !py-8"
                  }
                />
                <div className="mt-5 text-center w-full">
                  <Button
                    className="bg-black"
                    disabled={otp.length < 6}
                    onClick={otp.length === 6 ? verifyAccount : () => {}}
                  >
                    Verify
                  </Button>
                  <p className="text-gray-500 mt-3">
                    Didn't receive code?{" "}
                    <span
                      className="underline text-black cursor-pointer"
                      onClick={resendCode}
                    >
                      Request again
                    </span>
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="flex justify-between items-center mb-10">
                <div>
                  <h6 className="font-bold text-4xl mb-1 text-black">
                    Profile Pages
                  </h6>
                  <p className="font-regular text-gray-500">
                    Kindly select the page you wish to manage now.
                  </p>
                </div>
                <div>
                  {allAccounts.length < 8 ? (
                    <Button
                      className="bg-black"
                      onClick={() => openModalFunc("add")}
                    >
                      Add Profile
                    </Button>
                  ) : null}
                </div>
              </div>
              {/* <div className="w-full" key="openerpage"> */}
              {allAccounts.length !== 0 ? (
                <div className="grid grid-cols-4 gap-4">
                  <>
                    {allAccounts.map((account: any) => (
                      <div className="w-full max-w-sm bg-white border border-gray-200 rounded-lg">
                        <div
                          className="flex flex-col items-center pt-10 px-10  cursor-pointer"
                          onClick={() => openAccount(account)}
                        >
                          <img
                            className="w-24 h-24 mb-3 rounded-full object-cover"
                            src={
                              account.img !== null ? account.img.url : ppholder
                            }
                            alt="manager"
                          />
                          <h5 className="mb-1 text-xl font-medium text-black dark:text-white capitalize">
                            {account.name}
                          </h5>
                          {!account.admin_approval ? (
                            <Badge className="bg-yellow-300">
                              Pending approval
                            </Badge>
                          ) : (
                            <span className="text-sm text-gray-500 dark:text-gray-400">
                              {account.followersCount} followers
                            </span>
                          )}
                        </div>
                        <div
                          className="flex justify-center text-gray-400 items-center mt-8 pb-4 pt-4 bg-gray-50 rounded-b-md cursor-pointer hover:bg-black hover:!text-white"
                          onClick={() => openModalFunc("edit", account)}
                        >
                          <h6 className="text-sm">Edit Profile</h6>
                        </div>
                      </div>
                    ))}
                  </>
                </div>
              ) : (
                <EmptyContainer
                  message=" No Profiles Created"
                  instructions={`Click the "Add Profile" button to create one`}
                />
              )}
              {/* </div> */}
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default PageOpener;
