import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import {
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  Card,
} from "@/components/ui/card";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Separator } from "@/components/ui/separator";
import { ppholder } from "constants/assets";
import useAxiosPrivate from "hooks/useAxiosPrivate";
import { Calendar } from "lucide-react";
import React, { useEffect, useState } from "react";
import { cleanDate } from "services/misc";

type BookingProps = {
  booking: any;
  updateFunc: (status: string) => void;
};

const BookingView = ({ booking, updateFunc }: BookingProps) => {
  const [mainStatus, setStatus] = useState({
    busConfirm: booking.busConfirm,
    status: booking.status,
    userConfirm: booking.userConfirm,
  });
  const [infoData, setInfoData] = useState<any>(null);
  const axiosPrivate = useAxiosPrivate();
  const getUser = async () => {
    try {
      const user = await axiosPrivate.get(`users/${booking.userId}`);
      setInfoData(user.data.data);
    } catch (error) {}
  };

  const [busStatus, setBusStatus] = useState(booking.busConfirm);

  const updateStatus = () => {
    if (booking.busConfirm !== mainStatus) {
      updateFunc(mainStatus.busConfirm);
      setBusStatus(mainStatus.busConfirm);
    }
  };

  useEffect(() => {
    if (booking !== undefined) {
      getUser();
    }
  }, []);

  return (
    <div>
      <Card className="w-full p-0 border-0 shadow-none">
        <CardHeader>
          <CardTitle>View Booking</CardTitle>
          <CardDescription>Shows detailed info of booking</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="bg-white p-5 gap-3 rounded-lg border">
            <div className="flex justify-between items-center">
              <h5 className="font-medium text-md">
                BookingID: {booking.bookingId}
              </h5>
              <span className="text-sm text-muted-foreground">
                {cleanDate(booking.createdAt)}
              </span>
            </div>
            <div className="flex justify-start items-center gap-1">
              <Calendar size={15} />
              <p className="text-sm">Booking Date: {cleanDate(booking.date)}</p>
            </div>
            <Badge
              className={`text-white bg-emerald-500 font-normal hover:bg-black hover:text-white capitalize ${
                mainStatus.status === "pending"
                  ? "bg-yellow-300 text-black"
                  : mainStatus.status === "completed"
                  ? " bg-emerald-500"
                  : mainStatus.status === "accepted"
                  ? " bg-blue-600"
                  : "bg-red-600"
              }`}
            >
              {mainStatus.status}
            </Badge>
            <div className="text-sm mb-2">
              User Confirmation: {""}
              <Badge
                className={`text-white bg-emerald-500 font-normal hover:bg-black hover:text-white capitalize ${
                  mainStatus.userConfirm === "pending"
                    ? "bg-yellow-300 text-black"
                    : mainStatus.userConfirm === "completed"
                    ? " bg-emerald-500"
                    : mainStatus.userConfirm === "accepted"
                    ? " bg-blue-600"
                    : "bg-red-600"
                }`}
              >
                {mainStatus.userConfirm}
              </Badge>
            </div>
            <div className="text-sm">
              Your Confirmation: {""}
              <Badge
                className={`text-white bg-emerald-500 font-normal hover:bg-black hover:text-white capitalize ${
                  busStatus === "pending"
                    ? "bg-yellow-300 text-black"
                    : busStatus === "completed"
                    ? " bg-emerald-500"
                    : busStatus === "accepted"
                    ? " bg-blue-600"
                    : "bg-red-600"
                }`}
              >
                {busStatus}
              </Badge>
            </div>
            <Separator className="my-3" />
            <div>
              <span className="text-sm text-muted-foreground">Message</span>
              <p className="text-sm">{booking.description}</p>
            </div>
            {infoData !== null ? (
              <div className="flex gap-3 justify-start items-start mt-4">
                <img
                  src={
                    infoData.profile_img !== null
                      ? infoData.profile_img.url
                      : ppholder
                  }
                  alt=""
                  className="w-10 h-10 rounded-lg object-cover"
                />
                <div className="w-full">
                  <h5 className="text-sm font-medium tracking-tight text-black">
                    @{infoData.username}
                  </h5>
                  <p className="m-0 text-sm font-normal text-gray-500">User</p>
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex justify-between items-center mt-3">
            <div className="flex flex-col space-y-1.5">
              <Label htmlFor="status" className="text-sm text-muted-foreground">
                Status
              </Label>
              <Select
                onValueChange={(e) =>
                  setStatus({ ...mainStatus, busConfirm: e })
                }
                value={mainStatus.busConfirm}
              >
                <SelectTrigger id="status" className="w-[200px]">
                  <SelectValue placeholder="Select" />
                </SelectTrigger>
                <SelectContent position="popper">
                  {["pending", "completed", "accepted", "rejected"].map(
                    (status) => (
                      <SelectItem
                        className="capitalize"
                        value={`${status}`}
                        key={status}
                      >
                        {status}
                      </SelectItem>
                    )
                  )}
                </SelectContent>
              </Select>
            </div>
            <Button className="bg-black" onClick={updateStatus}>
              Submit
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default BookingView;
