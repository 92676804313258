import { Badge } from "@/components/ui/badge";
import { ppholder } from "constants/assets";
import { MapPin, Theater } from "lucide-react";

type VenueProps = {
  data: any;
  viewFunc: (data: any) => void;
};

const DVenueCard = ({ data, viewFunc }: VenueProps) => {
  return (
    <div
      className="shadow rounded-2xl cursor-pointer"
      onClick={() => viewFunc(data)}
    >
      <img
        src={data.image !== null ? data.image.url : ppholder}
        alt=""
        className="w-full h-40 rounded-t-2xl object-cover bg-center"
      />
      <div className="p-3">
        <h3 className="font-medium text-black text-xl capitalize tracking-tighter">
          {data.name}
        </h3>
        {data.address !== "" ? (
          <div className="flex justify-start items-center text-gray-500 tracking-tighter capitalize">
            <MapPin size={15} />
            <p className="capitalize">
              {data.address} {data.city} {data.country}
            </p>
          </div>
        ) : (
          <div className="flex justify-start items-center text-gray-500 tracking-tighter">
            <Theater size={15} /> <p className=""> {data.bio}</p>
          </div>
        )}
        <Badge
          className={`tracking-tighter ${
            data.admin_status === "pending"
              ? "bg-yellow-300 text-black"
              : data.admin_status === "active"
              ? " bg-emerald-600"
              : "bg-red-600"
          }`}
        >
          {data.admin_status === "pending"
            ? "Pending Approval"
            : data.admin_status === "active"
            ? "Live"
            : "Rejected"}
        </Badge>
      </div>
    </div>
  );
};

export default DVenueCard;
